<template>
  <div v-if="false">
    <b-table
      responsive
      :fields="tableFields"
      :items="items"
      :busy="loading"
      show-empty
      bordered
      striped
    >
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <template #cell(categorieConso)="data">
        <span> {{ data.value.name }} </span></template
      >

      <template #cell(restant)="data">
        <span>
          {{
            new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(
              parseFloat(data.item.budget) - parseFloat(data.item.consommation)
            )
          }}
        </span></template
      >

      <template #cell(budget)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          {{
            new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(data.value)
          }}</span
        >
        <CurrencyInput
          v-else
          placeholder="Budget"
          v-model="budget"
          :value="budget"
        />
      </template>

      <template #cell(consommation)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          {{
            new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(data.value)
          }}</span
        >
        <CurrencyInput
          v-else
          placeholder="Consommation"
          v-model="consommation"
        />
      </template>
      <template #cell(actions)="data">
        <table-actions
          :actions="['edit']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @editItem="editItem(data.item)"
          @confirmItem="confirmItem(data.item)"
          @cancelItem="cancelItem(data.item)"
        ></table-actions> </template
    ></b-table>
    <div class="d-flex col-12 p-0">
      <div class="p-1 mr-auto font-weight-bold" style="font-size: small">
        <span> Total Budget:</span>
        {{
          new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
          }).format(totalBudget)
        }}
      </div>
      <div class="px-0 py-1 mx-auto font-weight-bold" style="font-size: small">
        <span> Total Consommé:</span>
        {{
          new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
          }).format(totalConsome)
        }}
      </div>
      <div class="p-1 ml-auto font-weight-bold" style="font-size: small">
        <span> Total Restant:</span>
        {{
          new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
          }).format(rest)
        }}
      </div>
    </div>
  </div>
</template>
<script>
import TableActions from "@/components/custom/TableActions";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import CurrencyInput from "@/components/custom/CurrencyInput";

export default {
  components: {
    TableActions,
    CurrencyInput,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      budget: 0,
      consommation: 0,
    };
  },
  created() {
    if (!this.$route.params.id) {
      this.loading = false;
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.INITIATIVE.finance &&
        this.$store
          .dispatch(
            "financeInitiative/fetchFinanceInitiative",
            this.INITIATIVE.finance.id
          )
          .then(() => {
            this.loading = false;
          });
    }
  },
  methods: {
    editItem(item) {
      this.$store
        .dispatch(
          "financeInitiative/fetchFinanceInitiative",
          this.INITIATIVE.finance.id
        )
        .then(() => {
          this.items = this.items.map((consomation) =>
            consomation.id === item.id
              ? { ...consomation, editMode: true }
              : consomation
          );
        });
      this.consommation = item.consommation;
      this.budget = item.budget;
    },
    confirmItem(item) {
      this.$store
        .dispatch("consommation/updateConsommation", {
          id: item.id,
          budget: parseFloat(this.budget),
          consommation: parseFloat(this.consommation),
        })
        .then(() => {
          this.$store
            .dispatch(
              "financeInitiative/fetchFinanceInitiative",
              this.INITIATIVE.finance.id
            )
            .then(() => {
              Swal.fire("La consommation est mise à jour !", "", "success");
            });
        });
    },
    cancelItem() {
      if (this.consommation !== null || this.budget !== null) {
        this.consommation = null;
        this.budget = null;
      } else {
        this.$store.dispatch(
          "financeInitiative/fetchFinanceInitiative",
          this.INITIATIVE.finance.id
        );
      }
    },
  },
  computed: {
    ...mapGetters("initiative", ["INITIATIVE"]),
    ...mapGetters("financeInitiative", ["FINANCE_INITIATIVE"]),

    tableFields() {
      var fields = [
        { key: "categorieConso", label: "Catégorie" },
        {
          key: "budget",
          label: "Budget",
          thStyle: { width: "100px !important", minWidth: "100px !important" },
        },
        {
          key: "consommation",
          label: "Consommé",
          thStyle: { width: "100px !important", minWidth: "100px !important" },
        },
        { key: "restant", label: "Restant" },
      ];
      if (!this.disabled)
        fields.push({
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        });
      return fields;
    },
    totalBudget() {
      return this.items.reduce((r, item) => r + item.budget, 0);
    },
    totalConsome() {
      return this.items.reduce((r, item) => r + item.consommation, 0);
    },
    rest() {
      return this.totalBudget - this.totalConsome;
    },
  },
  watch: {
    FINANCE_INITIATIVE() {
      this.items = this.FINANCE_INITIATIVE.consommations;
    },
    INITIATIVE() {
      if (this.$route.params.id) {
        this.INITIATIVE.finance &&
          this.$store
            .dispatch(
              "financeInitiative/fetchFinanceInitiative",
              this.INITIATIVE.finance.id
            )
            .then(() => {
              this.loading = false;
            });
      }
    },
  },
};
</script>
