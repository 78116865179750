<template>
  <div>
    <div class="row">
      <div class="col-12">
        <p class="h2">Ajouter un lien vers un document</p>
      </div>
      <div class="col-12">
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-form-group label="Libellé livrable">
          <v-select :options="['Livrable 1', 'Livrable 2', 'Livrable 3']">
            <template #no-options> Liste vide </template>
          </v-select>
        </b-form-group>
        <b-form-group label="Lien livrable">
          <b-form-input type="text"></b-form-input>
        </b-form-group>

        <b-button variant="secondary">Enregistrer</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style></style>
