<template>
  <section class="donut-c3">
    <vue-c3 :handler="donutcharthandler"></vue-c3>
  </section>
</template>

<script>
import Vue from "vue";
import VueC3 from "vue-c3";

export default {
  name: "donut-c3",
  components: {
    VueC3,
  },
  props: {
    options: Object,
  },
  data() {
    return {
      donutcharthandler: new Vue(),
    };
  },

  mounted() {
    this.donutcharthandler.$emit("init", this.options);
  },
};
</script>