<template>
  <div class="modal-xyz-548">
    <div
      class="w-100 h-100"
      @click="$emit('close')"
      style="position: fixed; top: 0; left: 0; background: rgba(0, 0, 0, 0.377)"
    ></div>
    <div class="sub-modal" style="position: relative">
      <div v-if="!noHeaders" class="sb_mdl_ctrls">
        <div
          class="mdl_btn"
          v-show="action === 'print'"
          @click="
            $emit('close');
            $emit('printItem', item);
          "
        >
          <i style="color: white; cursor: pointer" class="mdi mdi-printer"></i>
        </div>

        <div
          class="d-flex align-items-center justify-content-center"
          variant="primary"
          pill
          style="height: 40px; width: 40px"
          v-show="action === 'confirm'"
          @click="
            $emit('close');
            $emit('printItem', item);
          "
        >
          <i style="color: white; cursor: pointer" class="mdi mdi-check"></i>
        </div>

        <div
          class="mdl_btn"
          v-show="action === 'edit' || action === 'show'"
          @click="
            $emit('close');
            $emit('deleteItem', item);
          "
        >
          <i style="color: white; cursor: pointer" class="mdi mdi-delete"></i>
        </div>
        <div
          class="mdl_btn"
          v-show="false"
          @click="
            $emit('close');
            $emit('editItem', item);
          "
        >
          <i style="color: white; cursor: pointer" class="mdi mdi-pencil-outline"></i>
        </div>
        <b-button
          @click="$emit('close')"
          class="d-flex align-items-center justify-content-center"
          variant="primary"
          pill
          size="sm"
          style="height: 40px; width: 40px"
          ><i class="mdi mdi-close"></i
        ></b-button>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    noHeaders: Boolean,
    item: Object,
    action: { type: String, default: null },
  },
};
</script>

<style scoped>
.modal-xyz-548 {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub-modal {
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
  min-width: 900px;
  max-width: 900px;
  min-height: 500px;
  max-height: 500px;
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: hidden;
  overflow-x: hidden;
  height: auto;
}

@media (max-width: 990px) {
  .sub-modal {
    min-width: 80%;
    max-width: 80%;
  }
  .sb_mdl_ctrls {
    right: 9% !important;
  }
}

/* @media (max-width: 667px) {
  .sub-modal {
    min-width: 400px;
    max-width: 400px;
  }
}

@media (max-width: 460px) {
  .sub-modal {
    min-width: 360px;
    max-width: 360px;
  }
} */

.sb_mdl_ctrls {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 1rem;
}

.mdl_btn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffc000;
  margin-left: 5px;
}

.custom-title {
  font-size: 2rem;
}
</style>
