<template>
  <section class="tables">
    <div class="row">
      <div class="col-12">
        <p class="h2 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light">Nom : </span>
          <span style="font-size: 22px">{{ fdrName }}</span>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="row col-12 pr-0">
        <div class="col-md-3 mr-auto my-auto">
          <p class="mb-0 h4">Liste initiatives :</p>
        </div>
        <div class="row col-md-9 ml-auto mr-0 pr-0 my-auto">
          <div class="p-0 pl-2 col-md-3 col-12 mb-2">
            <b-form-input
              v-model="searchQuery.libelle"
              type="text"
              placeholder="Libellé initiative"
            ></b-form-input>
          </div>
          <div class="p-0 pl-2 col-md-3 col-12 mb-2">
            <v-select
              label="value"
              v-model="searchQuery.responsable"
              :options="responsables"
              :reduce="(responsable) => responsable.id"
              class="bg-white h-100"
              placeholder="Responsable"
            />
          </div>

          <div class="p-0 pl-2 col-md-3 col-12 mb-2">
            <v-select
              v-model="searchQuery.status"
              :options="statusInitiative"
              placeholder="Statut"
              class="bg-white h-100"
            />
          </div>
          <div class="p-0 pl-2 col-md-3 col-12 mb-2">
            <v-select
              :options="fdrList"
              :reduce="(fdr) => fdr.id"
              label="name"
              v-model="searchQuery.fdr"
              placeholder="FDR"
              class="bg-white h-100"
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col-12">
        <b-table
          id="table-list"
          responsive
          :items="filteredInitiatives"
          :fields="tableFields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy="loadingData"
          show-empty
          bordered
        >
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>

          <template v-slot:cell(endingAt)="data">
            <span
              class="text-white text-center px-2 py-1 rounded"
              :style="{ backgroundColor: dateBackgroundColor(data.value) }"
              >{{ data.value }}</span
            >
          </template>
          <template v-slot:cell(progress)="data">
            <b-progress max="100" show-progress height="15px">
              <b-progress-bar
                :style="`background-color: ${
                  data.value > 20 && data.value <= 70
                    ? '#f5f245'
                    : data.value > 70
                    ? '#23d92c'
                    : '#f5ba45'
                }`"
                :value="data.value"
                :label="`${data.value}%`"
              ></b-progress-bar>
            </b-progress>
          </template>
          <template v-slot:cell(responsable)="data">
            <TableResponsable :responsable="data.value" />
          </template>
          <template v-slot:cell(phases)="data">
            <div>
              {{ data.value.length ? data.value[0].libelle : "-" }}
            </div>
          </template>

          <template v-slot:cell(actions)="data">
            <div class="d-flex">
              <b-button
                v-if="data.value.includes('show')"
                @click="$emit('showItem', data.item)"
                variant="light"
                pill
                size="sm"
                style="width: 40px; height: 40px"
                class="d-flex align-items-center justify-content-center"
                ><i class="mdi mdi-eye"></i
              ></b-button>
              <b-button
                v-if="data.value.includes('edit')"
                @click="$emit('editItem', data.item)"
                variant="light"
                pill
                size="sm"
                style="width: 40px; height: 40px"
                class="d-flex align-items-center justify-content-center"
                ><i class="mdi mdi-pencil-outline"></i
              ></b-button>
              <b-button
                v-if="data.value.includes('delete')"
                @click="$emit('deleteItem', data.item)"
                variant="light"
                pill
                size="sm"
                style="width: 40px; height: 40px"
                class="d-flex align-items-center justify-content-center"
                ><i class="mdi mdi-delete"></i
              ></b-button>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import moment from "moment";
// import { httpClient } from "@/helpers/services/HttpClient";
import { mapGetters } from "vuex";
import TableResponsable from "@/components/custom/TableResponsable.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableResponsable,
  },
  props: {
    id: Number,
  },
  data: function () {
    return {
      sortBy: "name",
      perPage: 4,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      tableFields: [
        {
          key: "libelle",
          label: "Libellé initiative",
          sortable: true,
        },
        { key: "responsable", label: "Responsable", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "endingAt", label: "Échéances", sortable: true },
        { key: "phases", label: "Phase projet", sortable: true },
        { key: "progress", label: "Avancement", sortable: true },
      ],
      tableItems: [],
      loadingData: true,
      fdrName: "",
      searchQuery: {
        libelle: "",
        fdr: "",
        status: "",
        responsable: "",
      },
    };
  },
  methods: {
    dateBackgroundColor: function (date) {
      // "#38b000" : "#d20f35";
      moment(date);
      var diff = parseInt(
        moment
          .duration(
            moment(date, "DD-MM-YYYY").diff(moment(new Date(), "DD-MM-YYYY"))
          )
          .asDays()
      );
      return diff < 0 ? "#d20f35" : diff > 4 ? "#38b000" : "#ffd60a";
    },
  },

  computed: {
    // ...mapGetters("initiative", ["INITIATIVES", "VIEW", "TOTAL_ITEMS"]),
    ...mapGetters("fdr", ["FDR"]),
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("status", ["INITIATIVE_STATUS"]),
    ...mapGetters("fdr", ["FDRS"]),

    responsableTitle: function ({ index, value }) {
      if (this.isExpanded[index]) {
        return (
          value.split(" ")[0].substring(0, 1) +
          ". " +
          value.split(" ")[1].substring(0, 1)
        );
      } else {
        return value;
      }
    },
    responsables() {
      //return array of responsables

      var data = this.RESPONSABLES.map((responsable) => ({
        // value: `/api/users/${responsable.id}`,
        id: responsable.id,
        value: `${responsable.firstname} ${responsable.lastname}`,
      }));

      return data;
    },
    statusInitiative() {
      var status = this.INITIATIVE_STATUS;

      return status;
    },
    fdrList() {
      return this.FDRS;
    },

    filteredInitiatives() {
      return this.tableItems
        ? this.tableItems
            .filter(
              (initiative) =>
                (initiative.libelle
                  ? initiative.libelle
                      .toLowerCase()
                      .indexOf(this.searchQuery.libelle.toLowerCase()) > -1
                  : true) &&
                (initiative.status
                  ? initiative.status
                      .toLowerCase()
                      .indexOf(
                        this.searchQuery.status
                          ? this.searchQuery.status.toLowerCase()
                          : ""
                      ) > -1
                  : true)
            )
            .filter((initiative) =>
              this.searchQuery.responsable
                ? initiative.responsable.id ===
                  parseInt(this.searchQuery.responsable)
                : true
            )
            .filter((initiative) =>
              this.searchQuery.fdr
                ? initiative.feuilleRoute.id === parseInt(this.searchQuery.fdr)
                : true
            )
            .map((initiative) => ({
              ...initiative,
              avancement: parseInt(Math.random() * 100),
            }))
        : this.tableItems;
    },
    rows() {
      return this.filteredInitiatives ? this.filteredInitiatives.length : 0;
    },
  },

  created() {
    this.tableItems = this.INITIATIVES;
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("fdr/fetchAllFdrs");
    this.$store.dispatch("status/fetchInitiativeStatus");
  },
};
</script>
