var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"add-page"}},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"h4 text-secondary mr-auto my-auto"},[_vm._v("Liste des objectifs ")]),(!_vm.$router.currentRoute.path.includes('print'))?_c('div',{staticClass:"d-flex justify-content-end"},[_c('CustomAddButton',{attrs:{"text":"Affecter objectif","disabled":_vm.disabled},on:{"click":function($event){_vm.addStrategyChallengeInputs(Date.now())}}})],1):_vm._e()]),_vm._m(0),_c('b-table',{attrs:{"items":_vm.tableItems,"fields":_vm.tableFields,"per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"responsive":"","show-empty":"","bordered":"","striped":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"cell(vision)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('b-link',{attrs:{"to":{
          name: 'show-vision',
          params: {
            id: data.value.id,
            slug: data.value.slug || 'abcde',
          },
        }}},[_vm._v(_vm._s(data.value.libelle))]):_c('div',[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
            'is-invalid':
              _vm.$v.selectedVision.$error && _vm.$v.selectedVision.$dirty,
          },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticStyle:{"width":"100%","background-color":"transparent"},attrs:{"appendToBody":"","label":"text","placeholder":"Vision","reduce":(vision) => vision.value,"options":data.item.id ? _vm.visionsList : _vm.filtredVisionList},model:{value:(_vm.selectedVision),callback:function ($$v) {_vm.selectedVision=$$v},expression:"selectedVision"}})],1),(_vm.$v.selectedVision.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.selectedVision.required ? "Champ obligatoire" : "")+" ")]):_vm._e()])]}},{key:"cell(libelle)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('b-link',{attrs:{"to":{
          name: 'show-objectif',
          params: {
            id: data.item.id,
            slug: data.item.slug || 'abcde',
          },
        }}},[_vm._v(_vm._s(data.value))]):_c('div',[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
            'is-invalid':
              _vm.$v.selectedObjectif.$error && _vm.$v.selectedObjectif.$dirty,
          },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticStyle:{"width":"100%","background-color":"transparent"},attrs:{"options":_vm.objectifList,"appendToBody":"","label":"text","placeholder":"Objectif","reduce":(objectif) => objectif.value},model:{value:(_vm.selectedObjectif),callback:function ($$v) {_vm.selectedObjectif=$$v},expression:"selectedObjectif"}})],1),(_vm.$v.selectedObjectif.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.selectedObjectif.required ? "Champ obligatoire" : "")+" ")]):_vm._e()])]}},{key:"cell(description)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[_vm._v(_vm._s(data.value))]):_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":""}})]}},{key:"cell(responsable)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('TableResponsable',{attrs:{"responsable":data.value}}):_c('input',{staticClass:"form-control",attrs:{"type":"select","disabled":"","placeholder":"Responsable"},domProps:{"value":_vm.responsable}})]}},{key:"cell(organisationResp)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[_vm._v(_vm._s(data.value ? `${data.value.entite.libelle}/${data.value.libelle}` : "-"))]):_c('input',{staticClass:"form-control",attrs:{"type":"text","disabled":"","placeholder":"Organisation"},domProps:{"value":_vm.organisation
            ? `${_vm.organisation.entite.libelle}/${_vm.organisation.libelle}`
            : ''}})]}},{key:"cell(actions)",fn:function(data){return [_c('table-actions',{attrs:{"actions":['edit', 'delete'],"editMode":data.item.hasOwnProperty('editMode')},on:{"confirmItem":function($event){return _vm.confirmItem(data.index)},"cancelItem":function($event){return _vm.cancelItem(data.item, data.index)},"editItem":function($event){return _vm.editItem(data.index)},"deleteItem":function($event){return _vm.deleteItem(data.index)}}})]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 p-0"},[_c('hr',{staticClass:"mt-1 mb-3 bg-secondary"})])
}]

export { render, staticRenderFns }