<template>
  <div>
    <div class="row">
      <div class="col-12 d-flex">
        <span class="h4 text-secondary mr-auto my-auto">Liste des cadres </span>

        <div class="d-flex justify-content-end">
          <CustomAddButton
            :disabled="disabled"
            class="ml-1"
            text="Ajouter un cadre"
            @click="addFrameModal = true"
          />
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <b-table
      responsive
      :fields="tableFields"
      :items="tableItems"
      show-empty
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      striped
      bordered
      :sort-desc.sync="sortDesc"
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>

      <template #cell(actions)="data">
        <table-actions
          :actions="['edit', 'delete']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @deleteItem="deleteItem(data.item.id)"
          @editItem="editItem(data.item)"
          @cancelItem="cancelItem(data.item)"
          @confirmItem="confirmItem(data.item)"
        />
      </template>
      <template #cell(frame_label)="data"
        ><span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.item.cadre.name || "-"
        }}</span>
        <b-form-input
          v-else
          placeholder="Libellé"
          v-model="data.item.cadre.name"
        ></b-form-input>
      </template>
      <template #cell(type)="data"
        ><span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.item.cadre.type || "-"
        }}</span>

        <v-select
          v-else
          appendToBody
          style="min-width: max-content"
          :options="['Pattern', 'Régle', 'Politique', 'Principe']"
          v-model="data.item.cadre.type"
          placeholder="Type"
        >
          <template #no-options> Liste vide </template>
        </v-select>
      </template>

      <template #cell(name)="data"
        ><span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value || "-"
        }}</span>
        <b-form-input v-else placeholder="nom" v-model="data.item.name"
      /></template>
      <template #cell(couverture)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value || "-"
        }}</span>
        <v-select
          appendToBody
          style="min-width: max-content"
          v-else
          v-model="couverture"
          placeholder="Couverture"
          :options="['Partiel', 'Obsolete', 'Aucune Couverture', 'Total']"
        >
          <template #no-options> Liste vide </template>
        </v-select>
      </template>
      <template #cell(tauxCouverture)="data">
        <b-progress
          v-if="!data.item.hasOwnProperty('editMode')"
          max="100"
          show-progress
          height="15px"
        >
          <b-progress-bar
            :value="data.value || 0"
            :label="`${data.value || 0}%`"
            :style="`background-color: ${
              data.value > 20 && data.value <= 70
                ? '#f5f245'
                : data.value > 70
                ? '#23d92c'
                : '#f5ba45'
            }`"
          ></b-progress-bar>
        </b-progress>
        <div v-else>
          <b-progress
            v-if="couverture !== 'Partiel' && couverture !== 'Obsolete'"
            max="100"
            show-progress
            height="15px"
          >
            <b-progress-bar
              :value="tauxCouverture || 0"
              :label="`${tauxCouverture || 0}%`"
              :style="`background-color: ${
                tauxCouverture > 20 && tauxCouverture <= 70
                  ? '#f5f245'
                  : tauxCouverture > 70
                  ? '#23d92c'
                  : '#f5ba45'
              }`"
            ></b-progress-bar>
          </b-progress>

          <section v-else class="simple-slider">
            <vue-slide-bar class="pt-3" v-model="tauxCouverture" />
          </section>
        </div>
      </template>
      <template #cell(commentaire)="data"
        ><span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value || "-"
        }}</span>
        <b-form-input
          v-else
          placeholder="Commentaire"
          v-model="data.item.commentaire"
        />
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
    <div to="modals-xyz-548" v-if="addFrameModal">
      <Modal @close="addFrameModal = !addFrameModal">
        <add-frame
          :initiativeName="initiativeName"
          :oldPatterns="tableItems"
          @close="addFrameModal = !addFrameModal"
        />
      </Modal>
    </div>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";
import VueSlideBar from "vue-slide-bar";
import CustomAddButton from "@/components/custom/CustomAddButton";
import Modal from "@/components/custom/Modal.vue";
import AddFrame from "./AddFrame.vue";

export default {
  props: {
    patterns: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initiativeName: {
      type: String,
      default: "",
    },
  },
  components: { TableActions, VueSlideBar, CustomAddButton, AddFrame, Modal },
  data: () => ({
    sortBy: "",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    addFrameModal: false,
    tableItems: [],
    couverture: "",
    tauxCouverture: 0,
  }),

  methods: {
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("pattern/deletePattern", id).then(() => {
            this.$store
              .dispatch("initiative/fetchInitiative", this.$route.params.id)
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
    editItem(pattern) {
      this.$store
        .dispatch("initiative/fetchInitiative", this.$route.params.id)
        .then(() => {
          this.tableItems = this.tableItems.map((item) => {
            if (pattern.id === item.id) {
              this.couverture = item.couverture || "";
              this.tauxCouverture = item.tauxCouverture || 0;

              return {
                ...item,
                editMode: true,
              };
            }
            return item;
          });
        });
    },
    cancelItem: function (item) {
      if (
        item.name === "" &&
        this.couverture === "" &&
        item.commentaire === ""
      ) {
        this.$store.dispatch(
          "initiative/fetchInitiative",
          this.$route.params.id
        );
      } else {
        item.name = "";
        this.couverture = "";
        item.commentaire = "";
        this.tauxCouverture = 0;
      }
    },
    confirmItem: function (item) {
      var empty =
        item.name === "" || this.couverture === "" || item.commentaire === "";

      if (!empty) {
        this.$store
          .dispatch("pattern/updatePattern", {
            id: item.id,
            name: item.name,
            couverture: this.couverture || "",
            commentaire: item.commentaire || "",
            tauxCouverture: this.tauxCouverture,
          })
          .then(() => {
            // fetch data again
            this.$store
              .dispatch("cadre/updateCadre", {
                id: item.cadre.id,
                name: item.cadre.name,
                type: item.cadre.type || "",
              })
              .then(() => {
                this.$store
                  .dispatch("initiative/fetchInitiative", this.$route.params.id)
                  .then(() => {
                    Swal.fire("Le cadre est mis à jour !", "", "success");
                  });
              });
          });
      }
    },
  },
  mounted() {
    this.tableItems = this.patterns;
  },
  computed: {
    rows() {
      return this.patterns.length || 0;
    },
    tableFields() {
      var fields = [
        { key: "frame_label", label: "Libellé cadre", sortable: true },
        { key: "type", label: "Type cadre", sortable: true },
        { key: "couverture", label: "Couverture", sortable: true },
        { key: "tauxCouverture", label: "Taux de couverture", sortable: true },
        { key: "commentaire", label: "Commentaire", sortable: true },
      ];
      if (!this.disabled) {
        fields.push({
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        });
      }

      return fields;
    },
  },
  watch: {
    patterns: function () {
      this.tableItems = this.patterns;
    },
    couverture() {
      if (this.couverture === "Total") this.tauxCouverture = 100;
      // if (this.couverture === "Aucune Couverture") this.tauxCouverture = 0;
      else this.tauxCouverture = 0;
    },
  },
};
</script>

<style></style>
