<template>
  <form @submit.prevent="saveItem">
    <div class="row" style="margin-top: -56px">
      <div class="col-10">
        <p class="h2 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light"
            >Initiative :
          </span>
          <span style="font-size: 22px">{{ initiative.libelle }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex flex-wrap justify-content-between">
        <span class="h3 my-auto text-secondary">Création de tâche</span>
        <div>
          <b-button type="submit" pill :disabled="submitingForm">
            <b-spinner
              small
              v-if="submitingForm"
              class="text-white"
              label="Loading..."
            ></b-spinner>
            Confirmer</b-button
          >
        </div>
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col-md-6 col-12">
        <b-form-group
          label="Initiative:"
          class="font-weight-bold"
          label-cols-sm="3"
          label-align-sm="left"
        >
          <b-form-select
            :options="initiativesList"
            label="libelle"
            class="font-weight-normal"
            v-model="selectedInitiative"
            placeholder="Initiative"
            required
            disabled
          />
        </b-form-group>
        <b-form-group
          label="Phase:"
          class="font-weight-bold"
          label-cols-sm="3"
          label-align-sm="left"
        >
          <b-form-select
            :options="phasesList"
            class="font-weight-normal"
            placeholder="Phase"
            required
            v-model="selectedPhase"
          />
        </b-form-group>
        <b-form-group
          label="Type de tâche:"
          class="font-weight-bold"
          label-cols-sm="3"
          label-align-sm="left"
        >
          <b-form-select
            :options="typesTask"
            placeholder="Type de tâche"
            v-model="newTask.type"
            class="font-weight-normal"
            required
          ></b-form-select>
        </b-form-group>
        <b-form-group
          label="Tâche ou Libellé :"
          class="font-weight-bold"
          label-cols-sm="3"
          label-align-sm="left"
        >
          <b-form-input
            type="text"
            placeholder="Tâche ou Libellé "
            v-model="newTask.libelle"
            class="font-weight-normal"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Date de création:"
          class="font-weight-bold"
          label-cols-sm="3"
          label-align-sm="left"
        >
          <date-picker
            :value="new Date()"
            disabled
            class="col-12 p-0 font-weight-normal"
            placeholder="Date de création"
            required
            format="DD/MM/YYYY"
          />
        </b-form-group>
        <b-form-group
          label="Description:"
          class="font-weight-bold"
          label-cols-sm="3"
          label-align-sm="left"
        >
          <textarea
            type="text"
            v-model="newTask.description"
            placeholder="Description"
            class="font-weight-normal form-control"
          />
        </b-form-group>

        <!-- <b-form-group
          label="Avancement:"
          class="font-weight-bold"
          label-cols-sm="3"
          label-align-sm="left"
        >
          <section class="simple-slider col-md-6 p-0">
            <vue-slide-bar class="pt-3" v-model="newTask.avancement" />
          </section>
        </b-form-group> -->
      </div>
      <div class="col-md-6 col-12">
        <b-form-group
          label="Responsable:"
          class="font-weight-bold"
          label-cols-sm="3"
          label-align-sm="left"
        >
          <b-form-select
            :options="responsables"
            placeholder="Responsable"
            name="responsable"
            v-model="selectedResponsable"
            class="font-weight-normal"
            required
          />
        </b-form-group>
        <b-form-group
          label="Date de début:"
          class="font-weight-bold"
          label-cols-sm="3"
          label-align-sm="left"
        >
          <date-picker
            class="col-12 p-0 font-weight-normal"
            v-model="newTask.startedAt"
            placeholder="Date de début"
            :input-attr="{ required: 'true' }"
            format="DD/MM/YYYY"
          />
        </b-form-group>
        <b-form-group
          label="Date fin prév:"
          class="font-weight-bold"
          label-cols-sm="3"
          label-align-sm="left"
        >
          <date-picker
            class="col-12 p-0 font-weight-normal"
            v-model="informatedDate"
            placeholder="Date fin prév"
            :input-attr="{ required: 'true' }"
            format="DD/MM/YYYY"
          />
        </b-form-group>
        <b-form-group
          label="Statut:"
          class="font-weight-bold"
          label-cols-sm="3"
          label-align-sm="left"
        >
          <b-form-select
            :options="statusTasks"
            placeholder="Statut "
            v-model="newTask.status"
            class="font-weight-normal"
            required
          />
        </b-form-group>
        <!-- <b-form-group
          label="Total ETP:"
          class="font-weight-bold"
          label-cols-sm="3"
          label-align-sm="left"
        >
          <span class="font-weight-normal"> {{ totalETP }} JOURS</span>
        </b-form-group> -->
        <b-form-group
          label="Livrable:"
          class="font-weight-bold"
          label-cols-sm="3"
          label-align-sm="left"
        >
          <!-- <FilePicker v-model="newTask.livrable" /> -->
          <FilePicker class="font-weight-normal" />
        </b-form-group>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-sm-12 col-md-4">
        <label class="font-weight-bold">Libellé projet</label>
        <v-select :value="selected" :options="['projet 1', 'projet 2', 'projet 3']" />
      </div>
      <div class="col-sm-12 col-md-4">
        <label class="font-weight-bold">Phase</label>

        <v-select
          :options="['Phase 1', 'Phase 2', 'Phase 3']"
          :searchable="false"
          :close-on-select="false"
          :show-labels="false"
          placeholder="Phase"
        >
          <template #no-options > Liste vide </template>
        </v-select>
      </div>
      <div class="col-sm-12 col-md-4">
        <label class="font-weight-bold">Type de tâche</label>

        <v-select
          :options="['One', 'Two', 'Three']"
          :searchable="false"
          :close-on-select="false"
          :show-labels="false"
          placeholder="Type de tâche"
        >
          <template #no-options > Liste vide </template>
        </v-select>
      </div>
      <div class="col-sm-12 col-md-4">
        <label class="font-weight-bold">Tâche ou libellé</label>

        <v-select
          :options="['One', 'Two', 'Three']"
          :searchable="false"
          :close-on-select="false"
          :show-labels="false"
          placeholder="Tâche libellé"
        >
          <template #no-options > Liste vide </template>
        </v-select>
      </div>
      <div class="col-sm-12 col-md-4">
        <label class="font-weight-bold">Date de création</label>
        <b-input-group>
          <b-form-datepicker
            :readonly="true"
            disabled
            :value="new Date()"
            valueType="format"
            placeholder="Date de création"
          ></b-form-datepicker>
        </b-input-group>
      </div>
      <div class="col-sm-12 col-md-4">
        <label class="font-weight-bold">Responsable</label>

        <v-select
          :options="['Responsable 1', 'Responsable 2', 'Responsable 3']"
          :searchable="false"
          :close-on-select="false"
          :show-labels="false"
          placeholder="Responsable"
        >
          <template #no-options > Liste vide </template>
        </v-select>
      </div>
      <div class="col-sm-12 col-md-4">
        <label class="font-weight-bold">Date fin prév</label>
        <b-form-datepicker
          :value="new Date()"
          valueType="format"
          placeholder="Date fin prév"
        ></b-form-datepicker>
      </div>

      <div class="col-sm-12 col-md-4 mb-3">
        <label class="font-weight-bold">Statut</label>

        <v-select :value="selected" :options="['One', 'Two', 'Three']" />
      </div>
      <div class="col-sm-12 col-md-4 mb-3">
        <label class="font-weight-bold">Avancement</label>
        <simple-slider />
      </div>
    </div>

    <div class="row"></div>
    <div class="row">
      <div class="col-md-6">
        <label class="font-weight-bold">Description</label>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
        ></textarea>
      </div>
      <div class="col-sm-12 col-md-4">
        <label class="font-weight-bold">Livrable</label>
        <FilePicker />
      </div>
    </div> -->
  </form>
</template>

<script>
// import simpleSlider from "@/components/forms/sliders/simpleSlider";
// import ContributerTable from "@/pages/home/tasks/components/ContributerTable.vue";
// import FilePicker from "@/components/custom/FilePicker.vue";

// import ContributerTable from "./components/ContributerTable.vue";
// import ModalActions from "@/components/custom/ModalActions.vue";
import FilePicker from "@/components/custom/FilePicker.vue";
// import VueSlideBar from "vue-slide-bar";
import moment from "moment";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { USER_URL, PHASE_URL, INITIATIVE_URL } from "@/helpers/constURL";

export default {
  props: {
    initiative: Object,
  },
  components: {
    // simpleSlider,
    // ContributerTable,
    FilePicker,
    // ContributerTable,
    // ModalActions,
    // FilePicker,
    // VueSlideBar,
  },
  data: () => ({
    contributers: [],
    selectedInitiative: null,
    selectedPhase: null,
    selectedResponsable: null,
    informatedDate: "",
    submitingForm: false,
    newTask: {
      echeance: "N",
      libelle: "",
      description: "",
      type: "",
      contributions: [],
      initiative: "",
      responsable: "",
      deadline: new Date(),
      startedAt: "",
      status: "",
      avancement: 0,
    },

    deadline: false,
  }),
  methods: {
    addContributerInputs() {
      this.contributers.tableItems = [
        ...this.contributers.tableItems,
        {
          contributers: "",
          comment: "",
          etp: "",
          status: "",
          conso: 60,
          actions: ["edit", "delete"],
          editMode: true,
        },
      ];
    },
    removeContributerInputs(itemIndex) {
      this.contributers.tableItems = this.contributers.tableItems.filter(
        (item, index) => index !== itemIndex
      );
    },

    saveItem: function () {
      this.newTask = {
        ...this.newTask,
        initiative: `/api/${INITIATIVE_URL}/${this.selectedInitiative}`,
        responsable: `/api/${USER_URL}/${this.selectedResponsable}`,
        phase: `/api/${PHASE_URL}/${this.selectedPhase}`,
        deadline: moment(this.informatedDate).format("DD/MM/YYYY"),
        startedAt: moment(this.newTask.startedAt).format("DD/MM/YYYY"),
      };
      this.submitingForm = true;
      this.$store.dispatch("task/createTask", this.newTask).then(() => {
        this.$store
          .dispatch("initiative/fetchInitiative", this.$route.params.id)
          .then(() => {
            this.submitingForm = false;
            this.$emit("close");
            Swal.fire({
              title: "La tâche est bien créée !",
              type: "success",
              confirmButtonText: "OK",
            });
          });
      });
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("status/fetchTaskStatus");
    this.$store.dispatch("type/fetchTaskTypes");
    this.$store.dispatch("initiative/fetchAllInitiatives");
    this.$store.dispatch("phase/fetchAllPhases");
  },
  mounted() {
    this.selectedInitiative = this.initiative.id;
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("status", ["TASK_STATUS"]), //get all status
    ...mapGetters("type", ["TASK_TYPES"]), //get all status
    ...mapGetters("initiative", ["INITIATIVES"]), //get all initiatives
    ...mapGetters("phase", ["PHASES"]), //get all phases
    ...mapGetters("task", ["TASK"]),

    responsables() {
      //return array of responsables
      return this.RESPONSABLES.map((responsable) => ({
        id: `/api/${USER_URL}/${responsable.id}`,
        full_name: responsable.firstname + " " + responsable.lastname,
        value: responsable.id,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    statusTasks() {
      return this.TASK_STATUS;
    },
    typesTask() {
      return this.TASK_TYPES;
    },
    totalETP() {
      var total = 0;

      this.contributers.forEach((contributer) => {
        total = total + parseInt(contributer.nbrejours);
      });
      return !isNaN(parseInt(total)) ? parseInt(total) : 0;
    },
    initiativesList() {
      // this.initiative
      return this.INITIATIVES.map((initiative) => ({
        value: initiative.id,
        text: initiative.libelle,
      }));
    },
    phasesList() {
      this.selectedInitiative;
      return this.PHASES.map((phase) => ({
        ...phase,
        value: phase.id,
        text: phase.libelle,
      })); //.filter((phase) =>
      //     phase.initiatives ? phase.initiatives[0].id === this.selectedInitiative : true
      //   );
    },
  },
};
</script>

<style>
.mx-datepicker {
  width: 100% !important;
}
.form-row div {
  margin: auto;
}
.mx-datepicker .mx-input-wrapper .mx-input {
  height: 44.22px !important;
}
.custom-select {
  height: 44.22px !important;
}
.mx-datepicker-popup {
  z-index: 4000 !important;
}
</style>
