<template>
  <b-dropdown :text="text" variant="outline-info">
    <div v-for="(item, index) in items" :key="index">
      <b-dropdown-item @click="$emit('selected', item)">{{ item.text }}</b-dropdown-item>
      <b-dropdown-divider v-show="index < items.length - 1"></b-dropdown-divider>
    </div>
  </b-dropdown>
</template>

<script>
export default {
  props: { items: Array, text: String },
};
</script>

<style></style>
