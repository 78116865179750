<template>
  <div>
    <b-table
      responsive
      :fields="tableFields"
      :items="filtredImpacts"
      show-empty
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      striped
      bordered
      :sort-desc.sync="sortDesc"
      :busy="loading"
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(object_type)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value
        }}</span>
        <v-select
          style="min-width: max-content"
          v-model="selectedObjectType"
          :options="objectTypes"
          v-else
          appendToBody
          disabled
        >
          <template #no-options> Liste vide </template>
        </v-select>
      </template>

      <template v-slot:cell(object_label)="data">
        <b-link
          :to="{
            name:
              data.item.object_type === 'Application'
                ? 'show-application'
                : data.item.object_type === 'Composant Technique'
                ? 'show-composant'
                : data.item.object_type === 'Processus Métier'
                ? 'show-job-process'
                : data.item.object_type === 'Capacité Métier'
                ? 'business-capacity'
                : 'show-concept',
            params: {
              id: data.value.id,
              slug: data.value.slug || 'abcde',
            },
          }"
          v-if="!data.item.hasOwnProperty('editMode')"
          >{{ data.value.name }}</b-link
        >

        <v-select
          style="min-width: max-content"
          v-model="selectedObject"
          v-else
          :options="objects"
          appendToBody
          label="text"
          :reduce="(object) => object.value"
        >
          <template #no-options> Liste vide </template>
        </v-select>
      </template>

      <template v-slot:cell(isPrincipale)="data">
        <input
          v-if="!data.item.hasOwnProperty('editMode')"
          class="checkbox"
          disabled
          v-model="data.item.isPrincipale"
          type="checkbox"
        />

        <input
          v-else
          class="checkbox"
          :disabled="!canBePrincipale(data.item)"
          v-model="data.item.isPrincipale"
          type="checkbox"
        />
      </template>

      <template v-slot:cell(name)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value
        }}</span>
        <b-form-input v-else class="text" v-model="data.item.name" />
      </template>

      <template v-slot:cell(type)="data">
        <b-badge
          v-if="!data.item.hasOwnProperty('editMode')"
          :variant="
            data.value === 'Création'
              ? 'success'
              : data.value === 'Evolution'
              ? 'warning'
              : data.value === 'Suppression'
              ? 'danger'
              : ''
          "
        >
          {{ data.value }}
        </b-badge>

        <v-select
          v-else
          style="min-width: max-content"
          class="impact_type_select"
          v-model="data.item.type"
          appendToBody
          :class="{
            'bg-success': data.item.type === 'Création',
            'bg-warning': data.item.type === 'Evolution',
            'bg-danger': data.item.type === 'Suppression',
          }"
          :options="['Création', 'Suppression', 'Evolution', 'N/A']"
        >
          <template #no-options> Liste vide </template>
        </v-select>
      </template>

      <template v-slot:cell(forceImpact)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value
        }}</span>
        <v-select
          style="min-width: max-content"
          :options="['Très faible', 'Faible', 'Moyen', 'Elévé', '']"
          v-else
          v-model="data.item.forceImpact"
          appendToBody
        >
          <template #no-options> Liste vide </template>
        </v-select>
      </template>

      <template v-slot:cell(capacite)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          <b-link
            v-if="data.value.id"
            :to="{
              name: data.value.capaciteLinkName,
              params: {
                id: data.value.id || 0,
                slug: data.value.slug || 'abcde',
              },
            }"
            >{{ data.value.name }}</b-link
          >
          <span v-else>N/A</span>
        </span>
        <b-form-input
          disabled
          v-else
          type="text"
          v-model="data.item.capacite"
          class="form-control"
          placeholder=""
        />
      </template>

      <template #cell(actions)="data">
        <table-actions
          :actions="['edit', 'delete']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @confirmItem="$emit('confirmItem', data.item)"
          @cancelItem="$emit('cancelItem', data.item)"
          @editItem="$emit('editItem', data.item)"
          @deleteItem="$emit('deleteItem', data.item.id)"
        />
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
// import CustomAddButton from "@/components/custom/CustomAddButton";
import { mapGetters } from "vuex";
// import Swal from "sweetalert2";
// import DropdownSelection from "@/components/custom/DropdownSelection.vue";
// import ImpactGraphique from "./ImpactGraphique";
// import { INITIATIVE_URL } from "@/helpers/constURL";

export default {
  props: {
    filtredImpacts: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectedObjectType: {
      type: String,
      default: "Application",
    },
    selectedObject: String,
  },
  components: {
    TableActions,
    // CustomAddButton,
    // DropdownSelection,
    // ImpactGraphique,
  },
  data: () => ({
    loading: true,
    sortBy: "",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    filterBy: "", // default filter
    objectTypes: [
      "Application",
      "Composant Technique",
      "Processus Métier",
      "Concept Métier",
      "Capacité Métier",
    ],
    objects: [],
    tableItems: [],

    selectedOption: "",
    isFocused: false,

    viewOptions: [
      { value: "tableau", text: "Tableau" },
      { value: "graphique", text: "Graphique" },
    ],
    view: { value: "tableau", text: "Tableau" },
  }),
  methods: {
    canBePrincipale(impact) {
      return (
        this.tableItems.every((item) => !item.isPrincipale) ||
        impact.isPrincipale
      );
    },
    getTheMaximumLevel(impact) {
      var capacite;
      // impact.object_type === "Application"
      //   ? impact.object_label.capaciteSis
      //   : impact.object_type === "Composant Technique"
      //   ? impact.object_label.capacites
      //   : impact.object_label;
      if (impact.object_type === "Application") {
        capacite = impact.object_label.capaciteSis.find(
          ({ niveau }) =>
            niveau ===
            Math.max(
              ...impact.object_label.capaciteSis.map(({ niveau }) => niveau)
            )
        );
        return { ...capacite, capaciteLinkName: "show-infosys-capacity" };
        // Math.max(...impact.object_label.capaciteSis.map(({ niveau }) => niveau));
      }

      if (impact.object_type === "Composant Technique") {
        capacite = impact.object_label.capacites.find(
          ({ niveau }) =>
            niveau ===
            Math.max(
              ...impact.object_label.capacites.map(({ niveau }) => niveau)
            )
        );
        return { ...capacite, capaciteLinkName: "show-technical-capacity" };
        // Math.max(...impact.object_label.capaciteSis.map(({ niveau }) => niveau));
      }

      if (impact.object_type === "Capacité Métier") {
        capacite = impact.object_label.parent;
        // var i = 0;
        if (capacite)
          while (capacite.parent) {
            capacite = capacite.parent;
          }
        return { ...capacite, capaciteLinkName: "business-capacity" };
        // Math.max(...impact.object_label.capaciteSis.map(({ niveau }) => niveau));
      }
      return impact.object_type;
    },
  },

  created() {
    this.$store.dispatch("application/fetchAllApplications");
    this.$store.dispatch("composant/fetchAllComposants");
    this.$store.dispatch("processusMetier/fetchAllProcessusMetiers");
    this.$store.dispatch("capaciteMetier/fetchAllCapaciteMetiers");
    this.$store.dispatch("concept/fetchAllConcepts");
    if (this.$route.params.id)
      this.$store
        .dispatch("initiative/fetchInitiativeImpacts", this.$route.params.id)
        .then(() => {
          this.loading = false;
        });
    else this.loading = false;
  },
  computed: {
    ...mapGetters("application", ["APPLICATIONS"]), //get all status
    ...mapGetters("composant", ["COMPOSANTS"]), //get all status
    ...mapGetters("processusMetier", ["PROCESSUS_METIERS"]), //get all status
    ...mapGetters("concept", ["CONCEPTS"]), //get all status
    ...mapGetters("capaciteMetier", ["CAPACITE_METIERS"]), //get all status
    ...mapGetters("initiative", ["IMPACTS"]), //get all status

    applicationsList() {
      return this.APPLICATIONS.map((application) => ({
        text: application.name,
        value: application["@id"],
      }));
    },
    composantList() {
      return this.COMPOSANTS.map((composant) => ({
        text: composant.name,
        value: composant["@id"],
      }));
    },
    processusList() {
      return this.PROCESSUS_METIERS.map((processus) => ({
        text: processus.name,
        value: processus["@id"],
      }));
    },
    conceptsList() {
      return this.CONCEPTS.map((concept) => ({
        text: concept.name,
        value: concept["@id"],
      }));
    },
    capacitesList() {
      return this.CAPACITE_METIERS.map((capacite) => ({
        text: capacite.name,
        value: capacite["@id"],
      }));
    },
    rows() {
      return this.filtredImpacts.length;
    },

    tableFields() {
      var fields = [
        { key: "object_type", label: "Type d'objet", sortable: true },
        { key: "object_label", label: "Libellé objet", sortable: true },
        { key: "isPrincipale", label: "Principale", sortable: true },
        { key: "type", label: "Type impact", sortable: true },
        { key: "name", label: "Libellé impact", sortable: true },
        { key: "forceImpact", label: "Force impact", sortable: true },
        { key: "capacite", label: "Capacité", sortable: true },
      ];
      if (!this.disabled) {
        fields.push({
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        });
      }

      return fields;
    },
  },
  watch: {
    view() {
      if (this.view.value === "graphique") {
        this.$store.dispatch(
          "initiative/fetchInitiativeImpacts",
          this.$route.params.id
        );
      }
    },
  },
};
</script>

<style>
.impact_type_select {
  background-color: transparent !important;
}
</style>