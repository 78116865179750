<template>
  <div>
    <b-tabs
      :value="$store.state.tabs.impactInitiativeTab"
      @changed="impactInitiativeTab = $store.state.tabs.impactInitiativeTab"
      @activate-tab="
        (newTabIndex) =>
          $store.dispatch('tabs/changeImpactInitiativeTab', newTabIndex)
      "
      ><b-tab
        title="Impact application"
        @click="filterByFunction('Application')"
      >
        <div class="col-12 mt-1 d-flex flex-wrap align-items-center p-0">
          <span class="h4 text-secondary my-auto"
            >Liste des impacts applications
          </span>
          <DropdownSelection
            class="ml-1"
            :text="view.text"
            :items="viewOptions"
            @selected="(item) => (view = item)"
          />
          <div
            class="d-flex align-items-center ml-auto justify-content-end my-0"
          >
            <CustomAddButton
              :disabled="disabled"
              class="ml-1"
              text=" Nouvel impact"
              @click="addItem"
            />
          </div>
        </div>
        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>
        <div v-if="view.value === 'tableau'">
          <b-table
            responsive
            :fields="tableFields"
            :items="filtredImpacts"
            show-empty
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            striped
            bordered
            :sort-desc.sync="sortDesc"
            :busy="loading"
          >
            <!-- FOR LOADING ANIMATIONS -->
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Chargement...</strong>
              </div>
            </template>
            <!-- SHOW IF TABLE IS EMPTY -->
            <template #empty>
              <div class="text-center text-gray">Table vide</div>
            </template>
            <template v-slot:cell(object_type)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{
                data.value
              }}</span>
              <v-select
                style="min-width: max-content"
                v-model="selectedObjectType"
                disabled
                :options="objectTypes"
                v-else
                appendToBody
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </template>

            <template v-slot:cell(object_label)="data">
              <b-link
                :to="{
                  name:
                    data.item.object_type === 'Application'
                      ? 'show-application'
                      : data.item.object_type === 'Composant Technique'
                      ? 'show-composant'
                      : data.item.object_type === 'Processus Métier'
                      ? 'show-job-process'
                      : data.item.object_type === 'Capacité Métier'
                      ? 'business-capacity'
                      : 'show-concept',
                  params: {
                    id: data.value.id,
                    slug: data.value.slug || 'abcde',
                  },
                }"
                v-if="!data.item.hasOwnProperty('editMode')"
                >{{ data.value.name }}</b-link
              >
              <div v-else>
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  id="add-page"
                  :class="{
                    'is-invalid':
                      $v.selectedObject.$error && $v.selectedObject.$dirty,
                  }"
                >
                  <v-select
                    style="width: 100%; background-color: transparent"
                    v-model="selectedObject"
                    :options="objects"
                    placeholder="Objet"
                    appendToBody
                    label="text"
                    :reduce="(object) => object.value"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.selectedObject.$dirty"
                >
                  {{ !$v.selectedObject.required ? "champ obligatoire" : "" }}
                </span>
              </div>
            </template>

            <template v-slot:cell(isPrincipale)="data">
              <input
                v-if="!data.item.hasOwnProperty('editMode')"
                class="checkbox"
                disabled
                v-model="data.item.isPrincipale"
                type="checkbox"
              />

              <input
                v-else
                class="checkbox"
                :disabled="!canBePrincipale(data.item)"
                v-model="data.item.isPrincipale"
                type="checkbox"
              />
            </template>

            <template v-slot:cell(name)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{
                data.value
              }}</span>
              <b-form-input
                v-else
                class="text"
                v-model="data.item.name"
                placeholder="Libellé"
              />
            </template>

            <template v-slot:cell(type)="data">
              <b-badge
                v-if="!data.item.hasOwnProperty('editMode')"
                :variant="
                  data.value === 'Création'
                    ? 'success'
                    : data.value === 'Evolution'
                    ? 'warning'
                    : data.value === 'Suppression'
                    ? 'danger'
                    : ''
                "
              >
                {{ data.value }}
              </b-badge>

              <v-select
                v-else
                style="min-width: max-content"
                v-model="data.item.type"
                placeholder="Type"
                appendToBody
                :class="{
                  'bg-success': data.item.type === 'Création',
                  'bg-warning': data.item.type === 'Evolution',
                  'bg-danger': data.item.type === 'Suppression',
                }"
                :options="['Création', 'Suppression', 'Evolution', 'N/A']"
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </template>

            <template v-slot:cell(forceImpact)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{
                data.value
              }}</span>
              <v-select
                style="min-width: max-content"
                :options="['Très faible', 'Faible', 'Moyen', 'Elévé', '']"
                v-else
                v-model="data.item.forceImpact"
                placeholder="Force d'impact"
                appendToBody
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </template>

            <template v-slot:cell(capacite)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">
                <b-link
                  v-if="data.value.id"
                  :to="{
                    name: data.value.capaciteLinkName,
                    params: {
                      id: data.value.id || 0,
                      slug: data.value.slug || 'abcde',
                    },
                  }"
                  >{{ data.value.name }}</b-link
                >
                <span v-else>N/A</span>
              </span>
              <b-form-input
                disabled
                v-else
                type="text"
                v-model="data.item.capacite.name"
                label="name"
                class="form-control"
                placeholder=""
              />
              <!-- {{ data.item.capacite }} -->
            </template>

            <template #cell(actions)="data">
              <table-actions
                :actions="['edit', 'delete']"
                :editMode="data.item.hasOwnProperty('editMode')"
                @confirmItem="confirmItem(data.item)"
                @cancelItem="cancelItem(data.item)"
                @editItem="editItem(data.item)"
                @deleteItem="deleteItem(data.item.id)"
              />
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="table-list"
            align="right"
          >
          </b-pagination>
        </div>
        <ImpactGraphique v-else :impacts="filtredImpacts" :applications="[]" />
      </b-tab>
      <!--  -->
      <b-tab
        title="Impact processus"
        @click="filterByFunction('Processus Métier')"
      >
        <div class="col-12 mt-1 d-flex flex-wrap align-items-center p-0">
          <span class="h4 text-secondary my-auto"
            >Liste des impacts processus
          </span>
          <DropdownSelection
            class="ml-1"
            :text="view.text"
            :items="viewOptions"
            @selected="(item) => (view = item)"
          />
          <div
            class="d-flex align-items-center ml-auto justify-content-end my-0"
          >
            <CustomAddButton
              :disabled="disabled"
              class="ml-1"
              text=" Nouvel impact"
              @click="addItem"
            />
          </div>
        </div>
        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>
        <div v-if="view.value === 'tableau'">
          <b-table
            responsive
            :fields="tableFields"
            :items="filtredImpacts"
            show-empty
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            striped
            bordered
            :sort-desc.sync="sortDesc"
            :busy="loading"
          >
            <!-- FOR LOADING ANIMATIONS -->
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Chargement...</strong>
              </div>
            </template>
            <!-- SHOW IF TABLE IS EMPTY -->
            <template #empty>
              <div class="text-center text-gray">Table vide</div>
            </template>
            <template v-slot:cell(object_type)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{
                data.value
              }}</span>
              <v-select
                style="min-width: max-content"
                v-model="selectedObjectType"
                disabled
                :options="objectTypes"
                v-else
                appendToBody
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </template>

            <template v-slot:cell(object_label)="data">
              <b-link
                :to="{
                  name:
                    data.item.object_type === 'Application'
                      ? 'show-application'
                      : data.item.object_type === 'Composant Technique'
                      ? 'show-composant'
                      : data.item.object_type === 'Processus Métier'
                      ? 'show-job-process'
                      : data.item.object_type === 'Capacité Métier'
                      ? 'business-capacity'
                      : 'show-concept',
                  params: {
                    id: data.value.id,
                    slug: data.value.slug || 'abcde',
                  },
                }"
                v-if="!data.item.hasOwnProperty('editMode')"
                >{{ data.value.name }}</b-link
              >

              <div v-else>
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  id="add-page"
                  :class="{
                    'is-invalid':
                      $v.selectedObject.$error && $v.selectedObject.$dirty,
                  }"
                >
                  <v-select
                    style="width: 100%; background-color: transparent"
                    v-model="selectedObject"
                    :options="objects"
                    placeholder="Objet"
                    appendToBody
                    label="text"
                    :reduce="(object) => object.value"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.selectedObject.$dirty"
                >
                  {{ !$v.selectedObject.required ? "Champ obligatoire" : "" }}
                </span>
              </div>
            </template>

            <template v-slot:cell(isPrincipale)="data">
              <input
                v-if="!data.item.hasOwnProperty('editMode')"
                class="checkbox"
                disabled
                v-model="data.item.isPrincipale"
                type="checkbox"
              />

              <input
                v-else
                class="checkbox"
                :disabled="!canBePrincipale(data.item)"
                v-model="data.item.isPrincipale"
                type="checkbox"
              />
            </template>

            <template v-slot:cell(name)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{
                data.value
              }}</span>
              <b-form-input
                v-else
                class="text"
                v-model="data.item.name"
                placeholder="Libellé"
              />
            </template>

            <template v-slot:cell(type)="data">
              <b-badge
                v-if="!data.item.hasOwnProperty('editMode')"
                :variant="
                  data.value === 'Création'
                    ? 'success'
                    : data.value === 'Evolution'
                    ? 'warning'
                    : data.value === 'Suppression'
                    ? 'danger'
                    : ''
                "
              >
                {{ data.value }}
              </b-badge>

              <v-select
                v-else
                style="min-width: max-content"
                v-model="data.item.type"
                placeholder="Type"
                appendToBody
                :class="{
                  'bg-success': data.item.type === 'Création',
                  'bg-warning': data.item.type === 'Evolution',
                  'bg-danger': data.item.type === 'Suppression',
                }"
                :options="['Création', 'Suppression', 'Evolution', 'N/A']"
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </template>

            <template v-slot:cell(forceImpact)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{
                data.value
              }}</span>
              <v-select
                style="min-width: max-content"
                :options="['Très faible', 'Faible', 'Moyen', 'Elévé', '']"
                v-else
                v-model="data.item.forceImpact"
                placeholder="Force d'impact"
                appendToBody
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </template>

            <template v-slot:cell(capacite)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">
                <b-link
                  v-if="data.value.id"
                  :to="{
                    name: data.value.capaciteLinkName,
                    params: {
                      id: data.value.id || 0,
                      slug: data.value.slug || 'abcde',
                    },
                  }"
                  >{{ data.value.name }}</b-link
                >
                <span v-else>N/A</span>
              </span>
              <b-form-input
                disabled
                v-else
                type="text"
                v-model="data.item.capacite.name"
                label="name"
                class="form-control"
                placeholder=""
              />
            </template>

            <template #cell(actions)="data">
              <table-actions
                :actions="['edit', 'delete']"
                :editMode="data.item.hasOwnProperty('editMode')"
                @confirmItem="confirmItem(data.item)"
                @cancelItem="cancelItem(data.item)"
                @editItem="editItem(data.item)"
                @deleteItem="deleteItem(data.item.id)"
              />
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="table-list"
            align="right"
          >
          </b-pagination>
        </div>
        <ImpactGraphique v-else :impacts="filtredImpacts" :applications="[]" />
      </b-tab>
      <!--  -->
      <b-tab title="Impact data" @click="filterByFunction('Concept Métier')">
        <div class="col-12 mt-1 d-flex flex-wrap align-items-center p-0">
          <span class="h4 text-secondary my-auto">Liste des impacts data </span>
          <DropdownSelection
            class="ml-1"
            :text="view.text"
            :items="viewOptions"
            @selected="(item) => (view = item)"
          />
          <div
            class="d-flex align-items-center ml-auto justify-content-end my-0"
          >
            <CustomAddButton
              :disabled="disabled"
              class="ml-1"
              text=" Nouvel impact"
              @click="addItem"
            />
          </div>
        </div>
        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>
        <div v-if="view.value === 'tableau'">
          <b-table
            responsive
            :fields="tableFields"
            :items="filtredImpacts"
            show-empty
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            striped
            bordered
            :sort-desc.sync="sortDesc"
            :busy="loading"
          >
            <!-- FOR LOADING ANIMATIONS -->
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Chargement...</strong>
              </div>
            </template>
            <!-- SHOW IF TABLE IS EMPTY -->
            <template #empty>
              <div class="text-center text-gray">Table vide</div>
            </template>
            <template v-slot:cell(object_type)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{
                data.value
              }}</span>
              <v-select
                style="min-width: max-content"
                v-model="selectedObjectType"
                disabled
                :options="objectTypes"
                v-else
                appendToBody
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </template>

            <template v-slot:cell(object_label)="data">
              <b-link
                :to="{
                  name:
                    data.item.object_type === 'Application'
                      ? 'show-application'
                      : data.item.object_type === 'Composant Technique'
                      ? 'show-composant'
                      : data.item.object_type === 'Processus Métier'
                      ? 'show-job-process'
                      : data.item.object_type === 'Capacité Métier'
                      ? 'business-capacity'
                      : 'show-concept',
                  params: {
                    id: data.value.id,
                    slug: data.value.slug || 'abcde',
                  },
                }"
                v-if="!data.item.hasOwnProperty('editMode')"
                >{{ data.value.name }}</b-link
              >

              <div v-else>
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  id="add-page"
                  :class="{
                    'is-invalid':
                      $v.selectedObject.$error && $v.selectedObject.$dirty,
                  }"
                >
                  <v-select
                    style="width: 100%; background-color: transparent"
                    v-model="selectedObject"
                    :options="objects"
                    placeholder="Objet"
                    appendToBody
                    label="text"
                    :reduce="(object) => object.value"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.selectedObject.$dirty"
                >
                  {{ !$v.selectedObject.required ? "Champ obligatoire" : "" }}
                </span>
              </div>
            </template>

            <template v-slot:cell(isPrincipale)="data">
              <input
                v-if="!data.item.hasOwnProperty('editMode')"
                class="checkbox"
                disabled
                v-model="data.item.isPrincipale"
                type="checkbox"
              />

              <input
                v-else
                class="checkbox"
                :disabled="!canBePrincipale(data.item)"
                v-model="data.item.isPrincipale"
                type="checkbox"
              />
            </template>

            <template v-slot:cell(name)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{
                data.value
              }}</span>
              <b-form-input
                v-else
                class="text"
                v-model="data.item.name"
                placeholder="Libellé"
              />
            </template>

            <template v-slot:cell(type)="data">
              <b-badge
                v-if="!data.item.hasOwnProperty('editMode')"
                :variant="
                  data.value === 'Création'
                    ? 'success'
                    : data.value === 'Evolution'
                    ? 'warning'
                    : data.value === 'Suppression'
                    ? 'danger'
                    : ''
                "
              >
                {{ data.value }}
              </b-badge>

              <v-select
                v-else
                style="min-width: max-content"
                v-model="data.item.type"
                placeholder="Type"
                appendToBody
                :class="{
                  'bg-success': data.item.type === 'Création',
                  'bg-warning': data.item.type === 'Evolution',
                  'bg-danger': data.item.type === 'Suppression',
                }"
                :options="['Création', 'Suppression', 'Evolution', 'N/A']"
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </template>

            <template v-slot:cell(forceImpact)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{
                data.value
              }}</span>
              <v-select
                style="min-width: max-content"
                :options="['Très faible', 'Faible', 'Moyen', 'Elévé', '']"
                v-else
                v-model="data.item.forceImpact"
                placeholder="Force d'impact"
                appendToBody
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </template>

            <template v-slot:cell(capacite)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">
                <b-link
                  v-if="data.value.id"
                  :to="{
                    name: data.value.capaciteLinkName,
                    params: {
                      id: data.value.id || 0,
                      slug: data.value.slug || 'abcde',
                    },
                  }"
                  >{{ data.value.name }}</b-link
                >
                <span v-else>N/A</span>
              </span>
              <b-form-input
                disabled
                v-else
                type="text"
                v-model="data.item.capacite.name"
                label="name"
                class="form-control"
                placeholder=""
              />
            </template>

            <template #cell(actions)="data">
              <table-actions
                :actions="['edit', 'delete']"
                :editMode="data.item.hasOwnProperty('editMode')"
                @confirmItem="confirmItem(data.item)"
                @cancelItem="cancelItem(data.item)"
                @editItem="editItem(data.item)"
                @deleteItem="deleteItem(data.item.id)"
              />
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="table-list"
            align="right"
          >
          </b-pagination>
        </div>
        <ImpactGraphique v-else :impacts="filtredImpacts" :applications="[]" />
      </b-tab>
      <!--  -->
      <b-tab
        title="Impact infrastructure"
        @click="filterByFunction('Composant Technique')"
      >
        <div class="col-12 mt-1 d-flex flex-wrap align-items-center p-0">
          <span class="h4 text-secondary my-auto"
            >Liste des impacts infrastructures
          </span>
          <DropdownSelection
            class="ml-1"
            :text="view.text"
            :items="viewOptions"
            @selected="(item) => (view = item)"
          />
          <div
            class="d-flex align-items-center ml-auto justify-content-end my-0"
          >
            <CustomAddButton
              :disabled="disabled"
              class="ml-1"
              text=" Nouvel impact"
              @click="addItem"
            />
          </div>
        </div>
        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>
        <div v-if="view.value === 'tableau'">
          <b-table
            responsive
            :fields="tableFields"
            :items="filtredImpacts"
            show-empty
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            striped
            bordered
            :sort-desc.sync="sortDesc"
            :busy="loading"
          >
            <!-- FOR LOADING ANIMATIONS -->
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Chargement...</strong>
              </div>
            </template>
            <!-- SHOW IF TABLE IS EMPTY -->
            <template #empty>
              <div class="text-center text-gray">Table vide</div>
            </template>
            <template v-slot:cell(object_type)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{
                data.value
              }}</span>
              <v-select
                style="min-width: max-content"
                v-model="selectedObjectType"
                disabled
                :options="objectTypes"
                v-else
                appendToBody
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </template>

            <template v-slot:cell(object_label)="data">
              <b-link
                :to="{
                  name:
                    data.item.object_type === 'Application'
                      ? 'show-application'
                      : data.item.object_type === 'Composant Technique'
                      ? 'show-composant'
                      : data.item.object_type === 'Processus Métier'
                      ? 'show-job-process'
                      : data.item.object_type === 'Capacité Métier'
                      ? 'business-capacity'
                      : 'show-concept',
                  params: {
                    id: data.value.id,
                    slug: data.value.slug || 'abcde',
                  },
                }"
                v-if="!data.item.hasOwnProperty('editMode')"
                >{{ data.value.name }}</b-link
              >

              <div v-else>
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  id="add-page"
                  :class="{
                    'is-invalid':
                      $v.selectedObject.$error && $v.selectedObject.$dirty,
                  }"
                >
                  <v-select
                    style="width: 100%; background-color: transparent"
                    v-model="selectedObject"
                    :options="objects"
                    placeholder="Objet"
                    appendToBody
                    label="text"
                    :reduce="(object) => object.value"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>

                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.selectedObject.$dirty"
                >
                  {{ !$v.selectedObject.required ? "Champ obligatoire" : "" }}
                </span>
              </div>
            </template>

            <template v-slot:cell(isPrincipale)="data">
              <input
                v-if="!data.item.hasOwnProperty('editMode')"
                class="checkbox"
                disabled
                v-model="data.item.isPrincipale"
                type="checkbox"
              />

              <input
                v-else
                class="checkbox"
                :disabled="!canBePrincipale(data.item)"
                v-model="data.item.isPrincipale"
                type="checkbox"
              />
            </template>

            <template v-slot:cell(name)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{
                data.value
              }}</span>
              <b-form-input
                v-else
                class="text"
                v-model="data.item.name"
                placeholder="Libellé"
              />
            </template>

            <template v-slot:cell(type)="data">
              <b-badge
                v-if="!data.item.hasOwnProperty('editMode')"
                :variant="
                  data.value === 'Création'
                    ? 'success'
                    : data.value === 'Evolution'
                    ? 'warning'
                    : data.value === 'Suppression'
                    ? 'danger'
                    : ''
                "
              >
                {{ data.value }}
              </b-badge>

              <v-select
                v-else
                style="min-width: max-content"
                v-model="data.item.type"
                placeholder="Type"
                appendToBody
                :class="{
                  'bg-success': data.item.type === 'Création',
                  'bg-warning': data.item.type === 'Evolution',
                  'bg-danger': data.item.type === 'Suppression',
                }"
                :options="['Création', 'Suppression', 'Evolution', 'N/A']"
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </template>

            <template v-slot:cell(forceImpact)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{
                data.value
              }}</span>
              <v-select
                style="min-width: max-content"
                :options="['Très faible', 'Faible', 'Moyen', 'Elévé', '']"
                v-else
                v-model="data.item.forceImpact"
                placeholder="Force d'impact"
                appendToBody
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </template>

            <template v-slot:cell(capacite)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">
                <b-link
                  v-if="data.value.id"
                  :to="{
                    name: data.value.capaciteLinkName,
                    params: {
                      id: data.value.id || 0,
                      slug: data.value.slug || 'abcde',
                    },
                  }"
                  >{{ data.value.name }}</b-link
                >
                <span v-else>N/A</span>
              </span>
              <b-form-input
                disabled
                v-else
                type="text"
                v-model="data.item.capacite.name"
                label="name"
                class="form-control"
                placeholder="Capacité principale"
              />
            </template>

            <template #cell(actions)="data">
              <table-actions
                :actions="['edit', 'delete']"
                :editMode="data.item.hasOwnProperty('editMode')"
                @confirmItem="confirmItem(data.item)"
                @cancelItem="cancelItem(data.item)"
                @editItem="editItem(data.item)"
                @deleteItem="deleteItem(data.item.id)"
              />
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="table-list"
            align="right"
          >
          </b-pagination>
        </div>
        <ImpactGraphique v-else :impacts="filtredImpacts" :applications="[]" />
      </b-tab>
      <!--  -->
      <b-tab title="Impact métier" @click="filterByFunction('Capacité Métier')">
        <div class="col-12 mt-1 d-flex flex-wrap align-items-center p-0">
          <span class="h4 text-secondary my-auto"
            >Liste des impacts métiers
          </span>
          <DropdownSelection
            class="ml-1"
            :text="view.text"
            :items="viewOptions"
            @selected="(item) => (view = item)"
          />
          <div
            class="d-flex align-items-center ml-auto justify-content-end my-0"
          >
            <CustomAddButton
              :disabled="disabled"
              class="ml-1"
              text=" Nouvel impact"
              @click="addItem"
            />
          </div>
        </div>
        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>
        <div v-if="view.value === 'tableau'">
          <ImpactsTable
            v-if="false"
            :filtredImpacts="filtredImpacts"
            :disabled="disabled"
            :selectedObjectType="selectedObjectType"
            :selectedObject="selectedObject"
            @confirmItem="confirmItem"
            @cancelItem="cancelItem"
            @editItem="editItem"
            @deleteItem="deleteItem"
          />
          <b-table
            responsive
            :fields="tableFields"
            :items="filtredImpacts"
            show-empty
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            striped
            bordered
            :sort-desc.sync="sortDesc"
            :busy="loading"
          >
            <!-- FOR LOADING ANIMATIONS -->
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Chargement...</strong>
              </div>
            </template>
            <!-- SHOW IF TABLE IS EMPTY -->
            <template #empty>
              <div class="text-center text-gray">Table vide</div>
            </template>
            <template v-slot:cell(object_type)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{
                data.value
              }}</span>
              <v-select
                style="min-width: max-content"
                v-model="selectedObjectType"
                disabled
                :options="objectTypes"
                v-else
                appendToBody
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </template>

            <template v-slot:cell(object_label)="data">
              <b-link
                :to="{
                  name:
                    data.item.object_type === 'Application'
                      ? 'show-application'
                      : data.item.object_type === 'Composant Technique'
                      ? 'show-composant'
                      : data.item.object_type === 'Processus Métier'
                      ? 'show-job-process'
                      : data.item.object_type === 'Capacité Métier'
                      ? 'business-capacity'
                      : 'show-concept',
                  params: {
                    id: data.value.id,
                    slug: data.value.slug || 'abcde',
                  },
                }"
                v-if="!data.item.hasOwnProperty('editMode')"
                >{{ data.value.name }}</b-link
              >
              <div v-else>
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  id="add-page"
                  :class="{
                    'is-invalid':
                      $v.selectedObject.$error && $v.selectedObject.$dirty,
                  }"
                >
                  <v-select
                    style="width: 100%; background-color: transparent"
                    v-model="selectedObject"
                    :options="objects"
                    placeholder="Objet"
                    appendToBody
                    label="text"
                    :reduce="(object) => object.value"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.selectedObject.$dirty"
                >
                  {{ !$v.selectedObject.required ? "Champ obligatoire" : "" }}
                </span>
              </div>
            </template>

            <template v-slot:cell(isPrincipale)="data">
              <input
                v-if="!data.item.hasOwnProperty('editMode')"
                class="checkbox"
                disabled
                v-model="data.item.isPrincipale"
                type="checkbox"
              />

              <input
                v-else
                class="checkbox"
                :disabled="!canBePrincipale(data.item)"
                v-model="data.item.isPrincipale"
                type="checkbox"
              />
            </template>

            <template v-slot:cell(name)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{
                data.value
              }}</span>
              <b-form-input
                v-else
                class="text"
                v-model="data.item.name"
                placeholder="Libellé"
              />
            </template>

            <template v-slot:cell(type)="data">
              <b-badge
                v-if="!data.item.hasOwnProperty('editMode')"
                :variant="
                  data.value === 'Création'
                    ? 'success'
                    : data.value === 'Evolution'
                    ? 'warning'
                    : data.value === 'Suppression'
                    ? 'danger'
                    : ''
                "
              >
                {{ data.value }}
              </b-badge>

              <v-select
                v-else
                style="min-width: max-content"
                v-model="data.item.type"
                placeholder="Type"
                appendToBody
                :class="{
                  'bg-success': data.item.type === 'Création',
                  'bg-warning': data.item.type === 'Evolution',
                  'bg-danger': data.item.type === 'Suppression',
                }"
                :options="['Création', 'Suppression', 'Evolution', 'N/A']"
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </template>

            <template v-slot:cell(forceImpact)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">{{
                data.value
              }}</span>
              <v-select
                style="min-width: max-content"
                :options="['Très faible', 'Faible', 'Moyen', 'Elévé', '']"
                v-else
                v-model="data.item.forceImpact"
                placeholder="Force d'impact"
                appendToBody
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </template>

            <template v-slot:cell(capacite)="data">
              <span v-if="!data.item.hasOwnProperty('editMode')">
                <b-link
                  v-if="data.value.id"
                  :to="{
                    name: data.value.capaciteLinkName,
                    params: {
                      id: data.value.id || 0,
                      slug: data.value.slug || 'abcde',
                    },
                  }"
                  >{{ data.value.name }}</b-link
                >
                <span v-else>N/A</span>
              </span>
              <b-form-input
                disabled
                v-else
                type="text"
                v-model="data.item.capacite.name"
                label="name"
                class="form-control"
                placeholder=""
              />
            </template>

            <template #cell(actions)="data">
              <table-actions
                :actions="['edit', 'delete']"
                :editMode="data.item.hasOwnProperty('editMode')"
                @confirmItem="confirmItem(data.item)"
                @cancelItem="cancelItem(data.item)"
                @editItem="editItem(data.item)"
                @deleteItem="deleteItem(data.item.id)"
              />
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="table-list"
            align="right"
          >
          </b-pagination>
        </div>
        <ImpactGraphique v-else :impacts="filtredImpacts" :applications="[]" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import DropdownSelection from "@/components/custom/DropdownSelection.vue";
import ImpactGraphique from "./ImpactGraphique";
import ImpactsTable from "./ImpactsTable";
import { INITIATIVE_URL } from "@/helpers/constURL";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    impacts: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TableActions,
    CustomAddButton,
    DropdownSelection,
    ImpactGraphique,
    ImpactsTable,
  },
  data: () => ({
    submiting: false,
    loading: true,
    sortBy: "",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    filterBy: "Application", // default filter
    objectTypes: [
      "Application",
      "Processus Métier",
      "Concept Métier",
      "Composant Technique",
      "Capacité Métier",
    ],
    objects: [],
    selectedObjectType: null,
    selectedObject: null,
    tableItems: [],

    selectedOption: "",
    isFocused: false,

    viewOptions: [
      { value: "tableau", text: "Tableau" },
      { value: "graphique", text: "Graphique" },
    ],
    view: { value: "tableau", text: "Tableau" },
  }),
  validations: {
    selectedObject: {
      required,
    },
  },
  methods: {
    getColor(str, target) {
      target.querySelectorAll("option").forEach((it) => {
        if (it.innerText.toLowerCase() == str.toLowerCase()) {
          return true;
        }
      });

      return false;
    },
    editItem(item) {
      this.selectedObjectType = item.object_type;
      this.$store
        .dispatch("initiative/fetchInitiativeImpacts", this.$route.params.id)
        .then(() => {
          this.tableItems = this.tableItems.map((impact) =>
            impact.id === item.id ? { ...impact, editMode: true } : impact
          );
          this.selectedObject = item.object_label["@id"];
        });
    },
    confirmItem(item) {
      this.$v.$touch();
      window.console.log(item);
      if (!this.$v.$invalid && item.type && item.name && item.forceImpact) {
        if (!this.submiting) {
          delete item.application;
          delete item.composant;
          delete item.concept;
          delete item.processus;
          var newImpact =
            this.selectedObjectType === "Application"
              ? {
                  ...item,
                  initiative: `/api/${INITIATIVE_URL}/${this.$route.params.id}`,
                  application: this.selectedObject,
                }
              : this.selectedObjectType === "Composant Technique"
              ? {
                  ...item,
                  initiative: `/api/${INITIATIVE_URL}/${this.$route.params.id}`,
                  composant: this.selectedObject,
                }
              : this.selectedObjectType === "Processus Métier"
              ? {
                  ...item,
                  initiative: `/api/${INITIATIVE_URL}/${this.$route.params.id}`,
                  processus: this.selectedObject,
                }
              : this.selectedObjectType === "Capacité Métier"
              ? {
                  ...item,
                  initiative: `/api/${INITIATIVE_URL}/${this.$route.params.id}`,
                  capaciteMetier: this.selectedObject,
                }
              : {
                  ...item,
                  initiative: `/api/${INITIATIVE_URL}/${this.$route.params.id}`,
                  concept: this.selectedObject,
                };
          if (
            item.name !== "" &&
            item.type !== "" &&
            item.forceImpact !== "" &&
            this.selectedObjectType !== null &&
            this.selectedObject !== null
          )
            this.submiting = true;
          if (!item.id)
            this.$store.dispatch("impact/createImpact", newImpact).then(() => {
              this.$store
                .dispatch(
                  "initiative/fetchInitiativeImpacts",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("L'impact est bien créé !", "", "success");
                  this.submiting = false;
                });
            });
          else
            this.$store.dispatch("impact/updateImpact", newImpact).then(() => {
              this.$store
                .dispatch(
                  "initiative/fetchInitiativeImpacts",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("L'impact est mis à jour !", "", "success");
                  this.submiting = false;
                });
            });
        }
      }
    },
    cancelItem(item) {
      if (
        item.name != "" ||
        item.type != "" ||
        item.forceImpact != "" ||
        item.isPrincipale ||
        // this.selectedObjectType != null ||
        this.selectedObject != null
      ) {
        item.name = "";
        item.type = "";
        item.forceImpact = "";
        item.isPrincipale = false;
        // this.selectedObjectType = null;
        this.selectedObject = null;
      } else {
        this.$store.dispatch(
          "initiative/fetchInitiativeImpacts",
          this.$route.params.id
        );
      }
    },
    addItem() {
      // this.selectedObjectType = null;
      this.selectedObject = null;
      if (
        !this.tableItems.some((item) => (item.editMode ? item.editMode : false))
      ) {
        this.tableItems.unshift({
          editMode: true,
          capacite: this.selectedObject
            ? this.getTheMaximumLevel({
                object_type: this.filterBy,
                object_label: this.selectedObject,
              })
            : { name: "N/A" },
        });
        this.view = { value: "tableau", text: "Tableau" };
        this.currentPage = 1;
        this.selectedObjectType = this.filterBy;
      }
      // this.filterBy = "";
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("impact/deleteImpact", id).then(() => {
            this.$store
              .dispatch("initiative/updateInitiative", {
                id: this.$route.params.id,
              })
              .then(() => {
                this.$store
                  .dispatch(
                    "initiative/fetchInitiativeImpacts",
                    this.$route.params.id
                  )
                  .then(() => {
                    Swal.fire("Supprimé!", "", "success");
                  });
              });
          });
        }
      });
    },
    filterByFunction(filter) {
      if (this.$route.params.id) {
        this.loading = true;
        this.$store
          .dispatch("initiative/fetchInitiativeImpacts", this.$route.params.id)
          .then(() => {
            this.filterBy = this.filterBy === filter ? "" : filter;
            this.loading = false;
          });
      }
    },
    canBePrincipale(impact) {
      return (
        this.tableItems.every((item) => !item.isPrincipale) ||
        impact.isPrincipale
      );
    },
    getTheMaximumLevel(impact) {
      var capacite;

      if (impact.object_type === "Application") {
        capacite = impact.object_label.capaciteSis.find(
          ({ niveau }) =>
            niveau ===
            Math.min(
              ...impact.object_label.capaciteSis.map(({ niveau }) => niveau)
            )
        );
        if (capacite && capacite.parent) capacite = capacite.parent;

        return { ...capacite, capaciteLinkName: "show-infosys-capacity" };
      }

      if (impact.object_type === "Composant Technique") {
        capacite = impact.object_label.capacites.find(
          ({ niveau }) =>
            niveau ===
            Math.min(
              ...impact.object_label.capacites.map(({ niveau }) => niveau)
            )
        );
        if (capacite && capacite.parent) capacite = capacite.parent;

        return { ...capacite, capaciteLinkName: "show-technical-capacity" };
      }

      if (impact.object_type === "Capacité Métier") {
        capacite = impact.object_label.parent;

        if (capacite)
          while (capacite.parent) {
            capacite = capacite.parent;
          }
        return { ...capacite, capaciteLinkName: "business-capacity" };
      }

      if (impact.object_type === "Processus Métier") {
        capacite = impact.object_label.capaciteMetiers;

        if (capacite && capacite.parent) capacite = capacite.parent;

        return { ...capacite, capaciteLinkName: "business-capacity" };
      }

      return impact.object_type;
    },
  },
  mounted: function () {
    if (this.$route.params.id) this.filterBy = "Application";
  },
  created() {
    this.$store.dispatch("application/fetchAllApplications");
    this.$store.dispatch("composant/fetchAllComposants");
    this.$store.dispatch("processusMetier/fetchAllProcessusMetiers");
    this.$store.dispatch("capaciteMetier/fetchAllCapaciteMetiers");
    this.$store.dispatch("concept/fetchAllConcepts");
    if (this.$route.params.id)
      this.$store
        .dispatch("initiative/fetchInitiativeImpacts", this.$route.params.id)
        .then(() => {
          this.loading = false;
        });
    else this.loading = false;
    this.filterByFunction(
      this.objectTypes[this.$store.state.tabs.impactInitiativeTab]
    );
  },
  computed: {
    ...mapGetters("application", ["APPLICATIONS"]), //get all status
    ...mapGetters("composant", ["COMPOSANTS"]), //get all status
    ...mapGetters("processusMetier", ["PROCESSUS_METIERS"]), //get all status
    ...mapGetters("concept", ["CONCEPTS"]), //get all status
    ...mapGetters("capaciteMetier", ["CAPACITE_METIERS"]), //get all status
    ...mapGetters("initiative", ["IMPACTS"]), //get all status

    applicationsList() {
      return this.APPLICATIONS.map((application) => ({
        text: application.name,
        value: application["@id"],
      }));
      // .filter(
      //   (application) =>
      //     !this.tableItems.find((item) =>
      //       item.object_label ? item.object_label["@id"] === application.value : false
      //     )
      // );
    },
    composantList() {
      return this.COMPOSANTS.map((composant) => ({
        text: composant.name,
        value: composant["@id"],
      }));
      // .filter(
      //   (composant) =>
      //     !this.tableItems.find((item) =>
      //       item.object_label ? item.object_label["@id"] === composant.value : false
      //     )
      // );
    },
    processusList() {
      return this.PROCESSUS_METIERS.map((processus) => ({
        text: processus.name,
        value: processus["@id"],
      }));
      // .filter(
      //   (processus) =>
      //     !this.tableItems.find((item) =>
      //       item.object_label ? item.object_label["@id"] === processus.value : false
      //     )
      // );
    },
    conceptsList() {
      return this.CONCEPTS.map((concept) => ({
        text: concept.name,
        value: concept["@id"],
      }));
      // .filter(
      //   (concept) =>
      //     !this.tableItems.find((item) =>
      //       item.object_label ? item.object_label["@id"] === concept.value : false
      //     )
      // );
    },
    capacitesList() {
      return this.CAPACITE_METIERS.map((capacite) => ({
        text: capacite.name,
        value: capacite["@id"],
      }));
    },
    rows() {
      return this.filtredImpacts.length;
    },
    filtredImpacts() {
      return this.tableItems.filter((impact) =>
        this.filterBy && impact.object_type
          ? impact.object_type === this.filterBy
          : true
      );
    },

    tableFields() {
      var fields = [
        { key: "object_type", label: "Type d'objet", sortable: true },
        { key: "object_label", label: "Libellé objet", sortable: true },
        { key: "isPrincipale", label: "Principale", sortable: true },
        { key: "type", label: "Type impact", sortable: true },
        { key: "name", label: "Libellé impact", sortable: true },
        { key: "forceImpact", label: "Force impact", sortable: true },
        { key: "capacite", label: "Capacité", sortable: true },
      ];
      if (!this.disabled) {
        fields.push({
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        });
      }

      return fields;
    },
  },
  watch: {
    selectedObjectType() {
      this.selectedObject = null;
      if (this.selectedObjectType === "Application")
        this.objects = this.applicationsList;
      if (this.selectedObjectType === "Composant Technique")
        this.objects = this.composantList;
      if (this.selectedObjectType === "Processus Métier")
        this.objects = this.processusList;
      if (this.selectedObjectType === "Concept Métier")
        this.objects = this.conceptsList;
      if (this.selectedObjectType === "Capacité Métier")
        this.objects = this.capacitesList;
    },

    IMPACTS() {
      this.tableItems = this.IMPACTS.impacts
        .map((impact) => ({
          ...impact,

          object_type: impact.application
            ? "Application"
            : impact.composant
            ? "Composant Technique"
            : impact.processus
            ? "Processus Métier"
            : impact.capaciteMetier
            ? "Capacité Métier"
            : "Concept Métier",
          // OBJECT
          object_label:
            impact.application ||
            impact.composant ||
            impact.processus ||
            impact.concept ||
            impact.capaciteMetier ||
            null,
        }))
        .map((impact) => ({
          ...impact,

          capacite: this.getTheMaximumLevel(impact),
        }));
    },
    view() {
      if (this.view.value === "graphique") {
        this.$store.dispatch(
          "initiative/fetchInitiativeImpacts",
          this.$route.params.id
        );
      }
    },
  },
};
</script>

<style>
.impact_type_select {
  background-color: transparent !important;
}
.vs--searchable .vs__dropdown-toggle {
  background-color: transparent !important;
}
</style>