var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"h4 text-secondary mr-1 my-auto"},[_vm._v("Liste des phases ")]),_c('dropdown-selection',{attrs:{"text":_vm.view.text,"items":_vm.viewOptions},on:{"selected":(item) => (_vm.view = item)}}),(_vm.view.value === 'gantt')?_c('b-dropdown',{class:`custom-drop my-auto ml-1 btn-outline-info ${_vm.selectedView.color} `,attrs:{"id":"dropdown-1","text":_vm.selectedView.text}},_vm._l((_vm.ganttView),function(view,index){return _c('b-dropdown-item',{key:index,staticClass:"col-sm-6 col-md-auto m-0 p-0"},[_c('button',{class:`btn ${view.color} btn-fw col-sm-12`,attrs:{"type":"button"},on:{"click":function($event){return _vm.changeView(index)}}},[_vm._v(" "+_vm._s(view.text)+" ")])])}),1):_vm._e()],1),_vm._m(0),(_vm.view.value === 'tableau')?_c('b-table',{attrs:{"responsive":"","items":_vm.tableItems,"fields":_vm.tableFields,"show-empty":"","bordered":"","striped":"","busy":_vm.loading},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"cell(startedAt)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[_vm._v(_vm._s(data.value || "-"))]):_c('div',{staticClass:"row w-100 m-0"},[_c('date-picker',{attrs:{"format":"DD/MM/YYYY","input-attr":{
            class: {
              'is-invalid':
                _vm.$v.tableItems.$each[data.index].dStartedAt.$error &&
                _vm.$v.tableItems.$each[data.index].dStartedAt.$dirty,
              ' form-control': true,
            },
          }},model:{value:(data.item.dStartedAt),callback:function ($$v) {_vm.$set(data.item, "dStartedAt", $$v)},expression:"data.item.dStartedAt"}}),(_vm.$v.tableItems.$each[data.index].dStartedAt.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.tableItems.$each[data.index].dStartedAt.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1)]}},{key:"cell(endingAt)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[_vm._v(" "+_vm._s(data.value || "-"))]):_c('div',{staticClass:"row w-100 m-0"},[_c('date-picker',{attrs:{"format":"DD/MM/YYYY","input-attr":{
            class: {
              'is-invalid':
                _vm.$v.tableItems.$each[data.index].dEndingAt.$error &&
                _vm.$v.tableItems.$each[data.index].dEndingAt.$dirty,
              ' form-control': true,
            },
          }},model:{value:(data.item.dEndingAt),callback:function ($$v) {_vm.$set(data.item, "dEndingAt", $$v)},expression:"data.item.dEndingAt"}}),(_vm.$v.tableItems.$each[data.index].dEndingAt.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.tableItems.$each[data.index].dEndingAt.required ? "Champ obligatoire" : _vm.$v.tableItems.$each[data.index].dEndingAt.required && _vm.$v.tableItems.$each[data.index].dEndingAt.$error ? "La date de fin ne doit pas être antérieur à la date de début" : "")+" ")]):_vm._e()],1)]}},{key:"cell(status)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[_vm._v(_vm._s(data.value || "-"))]):_c('div',{staticClass:"row col-12 p-0 m-0",attrs:{"id":"add-page"}},[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
            'is-invalid':
              _vm.$v.tableItems.$each[data.index].status.$error &&
              _vm.$v.tableItems.$each[data.index].status.$dirty,
          },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticClass:"bg-transparent",staticStyle:{"min-width":"max-content"},attrs:{"appendToBody":"","options":['Non démarré', 'En cours', 'Terminé']},model:{value:(data.item.status),callback:function ($$v) {_vm.$set(data.item, "status", $$v)},expression:"data.item.status"}})],1),(_vm.$v.tableItems.$each[data.index].status.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.tableItems.$each[data.index].status.required ? "Champ obligatoire" : "")+" ")]):_vm._e()])]}},{key:"cell(avancement)",fn:function(data){return [_c('b-progress',{staticStyle:{"background-color":"#cacccd"},attrs:{"max":"100","show-progress":"","height":"15px"}},[_c('b-progress-bar',{style:(`background-color: ${
            data.value > 20 && data.value <= 70
              ? '#f5f245'
              : data.value > 70
              ? '#23d92c'
              : '#f5ba45'
          }`),attrs:{"value":data.value || 0,"label":`${data.value || 0}%`}})],1)]}},{key:"cell(libelle)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[_vm._v(_vm._s(data.value))]):_c('b-form-input',{attrs:{"type":"text","placeholder":"Libellé","disabled":""},model:{value:(data.value),callback:function ($$v) {_vm.$set(data, "value", $$v)},expression:"data.value"}})]}},{key:"cell(actions)",fn:function(data){return [_c('table-actions',{attrs:{"actions":['edit'],"editMode":data.item.hasOwnProperty('editMode')},on:{"confirmItem":function($event){return _vm.confirmItem(data.item)},"cancelItem":function($event){return _vm.cancelItem(data.item)},"editItem":function($event){return _vm.editItem(data.item['@id'])}}})]}}],null,false,1734823199)}):(_vm.view.value !== 'tableau' && _vm.disabled && !_vm.$route.params.id)?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"100px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_c('div',[_c('div',{staticClass:"d-flex m-0 p-0 table_responsivo shadow-sm border"},[_c('div',{staticClass:"col-sm-2 m-0 p-0",staticStyle:{"max-width":"500px"}},[_c('div',{staticClass:"header_sd_menu d-flex align-items-center justify-content-center"},[_c('p',{staticClass:"fl_2 pl-2 mb-0 text-center"},[_vm._v("Phase")])]),_vm._l((_vm.ganttData),function(item,index){return _c('div',{key:index + '1245',staticClass:"sd_menu_row d-flex align-items-center",style:({
            backgroundColor: index % 2 == 0 ? 'white' : '#f5f5f5',
          })},[_c('p',{staticClass:"fl_2 pl-2"},[_vm._v(" "+_vm._s(item.libelle)+" ")])])})],2),_c('div',{staticClass:"col-sm-10 m-0 p-0"},[_c('frappe-gantt',{staticStyle:{"width":"100%","height":"100%"},attrs:{"view-mode":_vm.mode,"tasks":_vm.ganttData.map((it, i) => ({
              ...it,
              id: `phase-${i}`,
              progress: it.avancement,
              name: `${it.avancement}%`,
              start: _vm.moment(it.startedAt, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              end: _vm.moment(it.endingAt, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            }))},on:{"task-updated":function($event){return _vm.debugEventLog.push($event)},"task-date-updated":_vm.updateInitiativeDate,"task-progress-updated":_vm.updateInitiativeProgress}})],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 p-0"},[_c('hr',{staticClass:"mt-1 mb-3 bg-secondary"})])
}]

export { render, staticRenderFns }