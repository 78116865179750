var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex w-100"},[_c('span',{staticClass:"h4 text-secondary mr-1 my-auto"},[_vm._v("Liste des tâches ")]),_c('div',{staticClass:"d-flex"},[_c('dropdown-selection',{attrs:{"text":_vm.view.text,"items":_vm.viewOptions},on:{"selected":(item) => (_vm.view = item)}}),(_vm.view.value === 'gantt' && !_vm.disabled)?_c('b-dropdown',{class:`custom-drop my-auto ml-1 btn-outline-info ${_vm.selectedView.color} `,attrs:{"id":"dropdown-1","text":_vm.selectedView.text}},_vm._l((_vm.ganttView),function(view,index){return _c('b-dropdown-item',{key:index,staticClass:"col-sm-6 col-md-auto m-0 p-0"},[_c('button',{class:`btn ${view.color} btn-fw col-sm-12`,attrs:{"type":"button"},on:{"click":function($event){return _vm.changeView(index)}}},[_vm._v(" "+_vm._s(view.text)+" ")])])}),1):_vm._e()],1),_c('CustomAddButton',{staticClass:"ml-auto",attrs:{"disabled":_vm.disabled,"text":"Nouvelle tâche"},on:{"click":function($event){return _vm.$router.push({
            name: 'add-task',
            params: {
              fromInitiative: true,
            },
          })}}})],1)]),_vm._m(0),(_vm.view.value === 'tableau')?_c('div',[_c('b-table',{attrs:{"id":"add-page","responsive":"","items":_vm.tableItems,"fields":_vm.tableFields,"show-empty":"","bordered":"","striped":"","busy":_vm.loading,"per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"cell(avancement)",fn:function(data){return [_c('b-progress',{staticStyle:{"color":"#cacaca"},attrs:{"max":"100","show-progress":"","height":"15px"}},[_c('b-progress-bar',{style:(`background-color: ${
              data.value > 20 && data.value <= 70
                ? '#f5f245'
                : data.value > 70
                ? '#23d92c'
                : '#f5ba45'
            }`),attrs:{"value":data.value || 0,"label":`${data.value || 0}%`}})],1)]}},{key:"cell(phase)",fn:function(data){return [(!data.item.hasOwnProperty('editMode') || !data.item.editMode)?_c('span',[_vm._v(_vm._s(data.value.libelle || "-"))]):_c('div',{staticClass:"row col-12 p-0 m-0"},[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
              'is-invalid':
                _vm.$v.selectedPhase.$error && _vm.$v.selectedPhase.$dirty,
            },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticClass:"bg-transparent col-12 p-0",staticStyle:{"min-width":"max-content"},attrs:{"options":_vm.phasesList,"placeholder":"Phase","required":"","label":"text","reduce":(phase) => phase.value,"appendToBody":""},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}],null,true),model:{value:(_vm.selectedPhase),callback:function ($$v) {_vm.selectedPhase=$$v},expression:"selectedPhase"}})],1),(_vm.$v.selectedPhase.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.selectedPhase.required ? "champ obligatoire" : "")+" ")]):_vm._e()])]}},{key:"cell(type)",fn:function(data){return [(!data.item.hasOwnProperty('editMode') || !data.item.editMode)?_c('span',[_vm._v(_vm._s(data.value))]):_c('div',{staticClass:"row col-12 p-0 m-0"},[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
              'is-invalid': _vm.$v.type.$error && _vm.$v.type.$dirty,
            },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticClass:"bg-transparent col-12 p-0",staticStyle:{"min-width":"max-content"},attrs:{"options":_vm.typesTask,"placeholder":"Type de tâche","required":"","appendToBody":""},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}],null,true),model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),(_vm.$v.type.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.type.required ? "champ obligatoire" : "")+" ")]):_vm._e()])]}},{key:"cell(responsable)",fn:function(data){return [(!data.item.hasOwnProperty('editMode') || !data.item.editMode)?_c('TableResponsable',{attrs:{"responsable":data.value}}):_c('div',{staticClass:"row col-12 p-0 m-0"},[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
              'is-invalid':
                _vm.$v.selectedResponsable.$error &&
                _vm.$v.selectedResponsable.$dirty,
            },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticClass:"bg-transparent col-12 p-0",staticStyle:{"min-width":"max-content"},attrs:{"options":_vm.responsables,"placeholder":"Responsable","name":"responsable","label":"text","reduce":(responsable) => responsable.value,"required":"","appendToBody":""},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}],null,true),model:{value:(_vm.selectedResponsable),callback:function ($$v) {_vm.selectedResponsable=$$v},expression:"selectedResponsable"}})],1),(_vm.$v.selectedResponsable.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.selectedResponsable.required ? "champ obligatoire" : "")+" ")]):_vm._e()])]}},{key:"cell(status)",fn:function(data){return [(!data.item.hasOwnProperty('editMode') || !data.item.editMode)?_c('label',[_vm._v(_vm._s(data.value))]):_c('div',{staticClass:"row col-12 p-0 m-0"},[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
              'is-invalid': _vm.$v.status.$error && _vm.$v.status.$dirty,
            },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticClass:"bg-transparent col-12 p-0",staticStyle:{"min-width":"max-content"},attrs:{"appendToBody":"","placeholder":"Statut","options":_vm.statusTasks},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}],null,true),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),(_vm.$v.status.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.status.required ? "champ obligatoire" : "")+" ")]):_vm._e()])]}},{key:"cell(libelle)",fn:function(data){return [(!data.item.hasOwnProperty('editMode') || !data.item.editMode)?_c('b-link',{staticClass:"text-truncate",staticStyle:{"max-width":"100px !important"},attrs:{"to":{
            name: 'show-task',
            params: {
              id: data.item.id,
              slug: data.item.slug,
            },
          }}},[_vm._v(_vm._s(data.value))]):_c('div',[_c('b-input',{class:{
              'is-invalid': _vm.$v.libelle.$error && _vm.$v.libelle.$dirty,
            },attrs:{"placeholder":"Libellé"},model:{value:(_vm.libelle),callback:function ($$v) {_vm.libelle=$$v},expression:"libelle"}}),(_vm.$v.libelle.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.libelle.required ? "champ obligatoire" : !_vm.$v.libelle.minLength ? `Le champ doit contenir au moins ${_vm.$v.libelle.$params.minLength.min} caractères.` : "")+" ")]):_vm._e()],1)]}},{key:"cell(deadline)",fn:function(data){return [(!data.item.hasOwnProperty('editMode') || !data.item.editMode)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
            _vm.echeanceStatus(
              data.item.createdAt,
              data.value,
              data.item.status
            ).toUpperCase()
          ),expression:"\n            echeanceStatus(\n              data.item.createdAt,\n              data.value,\n              data.item.status\n            ).toUpperCase()\n          ",modifiers:{"hover":true}}],staticClass:"text-white text-center px-2 py-1 rounded",style:({
            backgroundColor: _vm.dateBackgroundColor(
              data.item.createdAt,
              data.value,
              data.item.status
            ),
          })},[_vm._v(_vm._s(data.value ? _vm.moment(data.value, "DD/MM/YYYY").format("DD/MM/YYYY") : "-"))]):_c('div',{staticClass:"row col-12 p-0 m-0"},[_c('date-picker',{attrs:{"input-attr":{
              class: {
                'is-invalid': _vm.$v.dDeadline.$error && _vm.$v.dDeadline.$dirty,
                ' form-control': true,
              },
            },"format":"DD/MM/YYYY","placeholder":"Date fin"},model:{value:(_vm.dDeadline),callback:function ($$v) {_vm.dDeadline=$$v},expression:"dDeadline"}}),(_vm.$v.dDeadline.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.dDeadline.required ? "champ obligatoire" : "")+" ")]):_vm._e()],1)]}},{key:"cell(actions)",fn:function(data){return [_c('table-actions',{attrs:{"actions":['edit', 'delete'],"editMode":data.item.hasOwnProperty('editMode')},on:{"confirmItem":function($event){return _vm.confirmItem(data.item)},"cancelItem":function($event){return _vm.cancelItem()},"deleteItem":function($event){return _vm.deleteTask(data.item.id)},"editItem":function($event){return _vm.editItem(data.item)}}})]}}],null,false,1113194453)}),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_c('div',[(_vm.$route.params.id)?_c('div',{staticClass:"d-flex m-0 p-0 table_responsivo shadow-sm border"},[_c('div',{staticClass:"col-3 m-0 p-0"},[_c('div',{staticClass:"table h-100",staticStyle:{"min-height":"400px"}},[_vm._m(1),(!_vm.tableItems.length)?_c('div',{staticClass:"text-center text-gray h-30"},[_vm._v(" Table vide ")]):_vm._e(),_vm._l((_vm.tableItems),function(item,index){return _c('div',{key:index + '1245',staticClass:"sd_menu_row d-flex align-items-center",style:({
              backgroundColor: index % 2 == 0 ? 'white' : '#f5f5f5',
              height: '38px !important',
            })},[_c('p',{staticClass:"fl-2 col-6 mr-auto my-auto text-truncate",staticStyle:{"max-width":"150px !important"}},[_c('router-link',{staticClass:"col-12 p-0",attrs:{"role":"link","to":{
                  name: 'show-task',
                  params: {
                    slug: item.slug || 'abcde',
                    id: item.id,
                  },
                }}},[_vm._v(" "+_vm._s(item.libelle)+" ")])],1),_c('p',{staticClass:"fl-2 text-center col-6 m-auto align-items-center"},[_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
                  item.responsable.firstname + ' ' + item.responsable.lastname
                ),expression:"\n                  item.responsable.firstname + ' ' + item.responsable.lastname\n                ",modifiers:{"hover":true}}],staticClass:"mr-1",staticStyle:{"background-color":"#adb5bd","border-color":"#adb5bd","height":"30px","width":"30px"},attrs:{"src":_vm.IMG_URL + item.responsable.avatarUrl}},[(!item.responsable.avatarUrl)?_c('div',{staticStyle:{"width":"25px","height":"25px","border-radius":"15px","display":"flex","align-items":"center","justify-content":"center"}},[_vm._v(" "+_vm._s(item.responsable.firstname[0].toUpperCase() + item.responsable.lastname[0].toUpperCase())+" ")]):_vm._e()])],1)])})],2),_vm._l((_vm.tableItems),function(item,index){return _c('div',{key:index + '1245',staticClass:"sd_menu_row d-flex align-items-center",style:({
            backgroundColor: index % 2 == 0 ? 'white' : '#f5f5f5',
          })},[_c('p',{staticClass:"fl_2 pl-2"},[_vm._v(" "+_vm._s(item.title)+" ")])])})],2),_c('div',{staticClass:"col-9 m-0 p-0"},[(_vm.tableItems.length)?_c('frappe-gantt',{staticStyle:{"width":"auto","height":"100%"},attrs:{"view-mode":_vm.mode,"tasks":_vm.tableItems.map((item) => ({
              id: `task-${item.id}`,
              progress: item.avancement || 0,
              name: `${item.avancement || 0}%`,
              start: _vm.formatDate(item.startedAt),
              end: _vm.formatDate(item.deadline),
              taskId: item.id,
            }))},on:{"task-date-updated":_vm.updateTasKDate,"task-progress-updated":_vm.updateTasKProgress}}):_vm._e()],1)]):_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"100px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])])]),(_vm.showAddTask)?_c('div',{attrs:{"to":"modals-xyz-548"}},[_c('modal',{on:{"close":function($event){_vm.showAddTask = !_vm.showAddTask}}},[_c('add-task',{attrs:{"initiative":_vm.initiative},on:{"close":function($event){_vm.showAddTask = !_vm.showAddTask}}})],1)],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 p-0"},[_c('hr',{staticClass:"mt-1 mb-3 bg-secondary"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header_sd_menu d-flex align-items-center"},[_c('th',{staticClass:"col-6 m-auto"},[_vm._v("Tâche")]),_c('th',{staticClass:"col-6 m-auto"},[_vm._v("Responsable")])])
}]

export { render, staticRenderFns }