<template>
  <div class="row col-12 p-0 m-0">
    <div class="col-12 p-0 d-flex justify-content-between">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des référents
      </span>

      <CustomAddButton
        text="Ajouter rôle"
        @click="searchRoleModal = true"
        :disabled="disabled"
      />
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>

    <!-- Search role -->
    <div to="modals-xyz-548" v-if="searchRoleModal">
      <SimpleModal @close="searchRoleModal = !searchRoleModal">
        <search-role
          :libelle="libelle"
          @cofirmRole="addRole"
          @close="searchRoleModal = !searchRoleModal"
        />
      </SimpleModal>
    </div>

    <!-- Search guests -->
    <div to="modals-xyz-548" v-if="searchGuestsModal">
      <SimpleModal @close="searchGuestsModal = !searchGuestsModal">
        <SearchGuests
          :libelle="libelle"
          @close="searchGuestsModal = !searchGuestsModal"
          @cofirmUser="confirmUser"
        />
      </SimpleModal>
    </div>

    <div class="p-0 mx-auto row col-12">
      <CustomReferentCarde
        :key="index"
        v-for="(referent, index) in referentsList"
        :role="referent.role"
        :user="referent.user"
        :index="index"
        @setUserRole="setUserRole"
        @deleteUser="deleteUser"
      />
    </div>
  </div>
</template>

<script>
import SimpleModal from "@/components/custom/SimpleModal.vue";
import searchRole from "./searchRole.vue";
import SearchGuests from "./SearchGuests.vue";
import { IMG_URL } from "@/helpers/services/api";
import Swal from "sweetalert2";
import CustomAddButton from "@/components/custom/CustomAddButton";
import CustomReferentCarde from "@/components/custom/CustomReferentCarde.vue";

export default {
  components: {
    searchRole,
    SimpleModal,
    SearchGuests,
    CustomAddButton,
    CustomReferentCarde,
  },
  props: {
    referents: {
      type: Array,
      default: () => [],
    },
    responsables: Array,
    libelle: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      IMG_URL,
      searchRoleModal: false,
      searchGuestsModal: false,
      selectedReferent: null,
      referentsList: this.referents.length
        ? this.referents
        : [
            {
              role: "Sponsor",
              user: null,
            },
          ],
    };
  },
  methods: {
    addRole(role) {
      this.referentsList.push({
        role: role,
        user: null,
      });
    },
    confirmUser(userId) {
      this.referentsList = this.referentsList.map((referent, index) =>
        this.selectedReferent == index
          ? {
              ...referent,
              user: this.responsables.find(
                (responsable) => responsable.id === userId
              ),
            }
          : referent
      );
      this.$store
        .dispatch("initiative/updateInitiative", {
          id: this.$route.params.id,
          referents: this.referentsList
            .filter((ref) => ref.user != null)
            .map((ref) => ({ ...ref, user: `/api/users/${ref.user.id}` })),
        })
        .then(() => {
          Swal.fire({
            title: "Le référent est bien créé !",
            type: "success",
            confirmButtonText: "OK",
          });
        });
    },
    setUserRole(index, selectedRole) {
      this.selectedReferent = index;

      this.role = selectedRole;
      this.searchGuestsModal = !this.searchGuestsModal;
    },
    deleteUser(index) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.referentsList = this.referentsList.filter(
            (referent, i) => i !== index
          );
          this.$store
            .dispatch("initiative/updateInitiative", {
              id: this.$route.params.id,
              referents: this.referentsList
                .filter((ref) => ref.user != null)
                .map((ref) => ({ ...ref, user: `/api/users/${ref.user.id}` })),
            })
            .then(() => {
              Swal.fire("Supprimé!", "", "success");
            });
        }
      });
    },
  },
  watch: {
    referents() {
      this.referentsList = this.referents;
    },
  },
};
</script>
