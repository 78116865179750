<template>
  <div class="col-12 p-0">
    <div class="d-flex flex-column">
      <div
        v-if="!impacts.length"
        class="text-gray d-flex h2 text-center w-100"
        style="height: 166px"
      >
        <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
        <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
      </div>
      <div :key="index" v-for="(capacite, index) in filImpacts">
        <p class="mt-3 mb-2 h5">
          <b-link
            v-if="capacite[0].capacite.id"
            style="text-decoration: none; color: black"
            :to="{
              name: 'show-infosys-capacity',

              params: {
                id: capacite[0].capacite.id,
                slug: capacite[0].capacite.slug || 'abcde',
              },
            }"
          >
            {{ capacite[0].capacite.name }}
          </b-link>
          <span v-else> CAPACITÉ INTROUVABLE </span>
        </p>

        <div class="text-white p-2 rounded my-2 py-3 d-flex" style="background: #f2edf3">
          <div
            class="col-12 col-md-3 mb-2"
            :key="appIndex"
            v-for="(impact, appIndex) in capacite"
          >
            <!-- {{ impact.object_label }} -->
            <b-link
              v-if="impact.object_label"
              class="mb-0 text-white"
              :to="{
                name:
                  impact.object_type === 'Application'
                    ? 'show-application'
                    : impact.object_type === 'Composant Technique'
                    ? 'show-composant'
                    : impact.object_type === 'Processus Métier'
                    ? 'show-job-process'
                    : impact.object_type === 'Capacité Métier'
                    ? 'business-capacity'
                    : 'show-concept',
                params: {
                  id: impact.object_label.id,
                  slug: impact.object_label.slug || 'abcde',
                },
              }"
              role="button"
            >
              <b-badge
                :variant="
                  impact.type === 'Création'
                    ? 'success'
                    : impact.type === 'Evolution'
                    ? 'warning'
                    : 'danger'
                "
              >
                {{ impact.object_label.name }}</b-badge
              >
            </b-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { groupBy } from "lodash";
export default {
  props: {
    applications: {
      type: Array,
      default: () => [],
    },
    impacts: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    groupBy,
  },
  computed: {
    filImpacts() {
      return groupBy(this.impacts, "capacite[@id]");
    },
  },
};
</script>

<style></style>
