<template>
  <div>
    <div class="d-flex">
      <div class="d-flex w-100">
        <span class="h4 text-secondary mr-1 my-auto">Liste des tâches </span>
        <div class="d-flex">
          <dropdown-selection
            :text="view.text"
            :items="viewOptions"
            @selected="(item) => (view = item)"
          />

          <b-dropdown
            id="dropdown-1"
            v-if="view.value === 'gantt' && !disabled"
            :text="selectedView.text"
            :class="`custom-drop my-auto ml-1 btn-outline-info ${selectedView.color} `"
          >
            <b-dropdown-item
              class="col-sm-6 col-md-auto m-0 p-0"
              v-for="(view, index) in ganttView"
              :key="index"
            >
              <button
                @click="changeView(index)"
                type="button"
                :class="`btn ${view.color} btn-fw col-sm-12`"
              >
                {{ view.text }}
              </button>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <CustomAddButton
          :disabled="disabled"
          text="Nouvelle tâche"
          class="ml-auto"
          @click="
            $router.push({
              name: 'add-task',
              params: {
                fromInitiative: true,
              },
            })
          "
        />
      </div>
    </div>

    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <div v-if="view.value === 'tableau'">
      <b-table
        id="add-page"
        responsive
        :items="tableItems"
        :fields="tableFields"
        show-empty
        bordered
        striped
        :busy="loading"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <!-- FOR LOADING ANIMATIONS -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Chargement...</strong>
          </div>
        </template>
        <!-- SHOW IF TABLE IS EMPTY -->
        <template #empty>
          <div class="text-center text-gray">Table vide</div>
        </template>

        <template v-slot:cell(avancement)="data">
          <b-progress
            max="100"
            show-progress
            height="15px"
            style="color: #cacaca"
          >
            <b-progress-bar
              :value="data.value || 0"
              :label="`${data.value || 0}%`"
              :style="`background-color: ${
                data.value > 20 && data.value <= 70
                  ? '#f5f245'
                  : data.value > 70
                  ? '#23d92c'
                  : '#f5ba45'
              }`"
            ></b-progress-bar>
          </b-progress>
        </template>

        <template #cell(phase)="data">
          <span
            v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
            >{{ data.value.libelle || "-" }}</span
          >
          <div class="row col-12 p-0 m-0" v-else>
            <div
              class="font-weight-normal p-0 form-control"
              style="padding: 0 !important; height: fit-content"
              :class="{
                'is-invalid':
                  $v.selectedPhase.$error && $v.selectedPhase.$dirty,
              }"
            >
              <v-select
                :options="phasesList"
                placeholder="Phase"
                required
                label="text"
                :reduce="(phase) => phase.value"
                appendToBody
                style="min-width: max-content"
                class="bg-transparent col-12 p-0"
                v-model="selectedPhase"
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </div>
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.selectedPhase.$dirty"
            >
              {{ !$v.selectedPhase.required ? "champ obligatoire" : "" }}
            </span>
          </div>
        </template>
        <template #cell(type)="data">
          <span
            v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
            >{{ data.value }}</span
          >
          <div class="row col-12 p-0 m-0" v-else>
            <div
              class="font-weight-normal p-0 form-control"
              style="padding: 0 !important; height: fit-content"
              :class="{
                'is-invalid': $v.type.$error && $v.type.$dirty,
              }"
            >
              <v-select
                :options="typesTask"
                placeholder="Type de tâche"
                v-model="type"
                style="min-width: max-content"
                class="bg-transparent col-12 p-0"
                required
                appendToBody
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </div>
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.type.$dirty"
            >
              {{ !$v.type.required ? "champ obligatoire" : "" }}
            </span>
            <!--  -->
          </div>
        </template>
        <template v-slot:cell(responsable)="data">
          <TableResponsable
            v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
            :responsable="data.value"
          />
          <div class="row col-12 p-0 m-0" v-else>
            <div
              class="font-weight-normal p-0 form-control"
              style="padding: 0 !important; height: fit-content"
              :class="{
                'is-invalid':
                  $v.selectedResponsable.$error &&
                  $v.selectedResponsable.$dirty,
              }"
            >
              <v-select
                :options="responsables"
                placeholder="Responsable"
                style="min-width: max-content"
                name="responsable"
                v-model="selectedResponsable"
                class="bg-transparent col-12 p-0"
                label="text"
                :reduce="(responsable) => responsable.value"
                required
                appendToBody
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </div>
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.selectedResponsable.$dirty"
            >
              {{ !$v.selectedResponsable.required ? "champ obligatoire" : "" }}
            </span>
            <!--  -->
          </div>
        </template>
        <template #cell(status)="data">
          <label
            v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
            >{{ data.value }}</label
          >
          <div class="row col-12 p-0 m-0" v-else>
            <div
              class="font-weight-normal p-0 form-control"
              style="padding: 0 !important; height: fit-content"
              :class="{
                'is-invalid': $v.status.$error && $v.status.$dirty,
              }"
            >
              <v-select
                v-model="status"
                appendToBody
                placeholder="Statut"
                style="min-width: max-content"
                class="bg-transparent col-12 p-0"
                :options="statusTasks"
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </div>
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.status.$dirty"
            >
              {{ !$v.status.required ? "champ obligatoire" : "" }}
            </span>
            <!--  -->
          </div>
        </template>

        <template v-slot:cell(libelle)="data">
          <b-link
            :to="{
              name: 'show-task',
              params: {
                id: data.item.id,
                slug: data.item.slug,
              },
            }"
            v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
            style="max-width: 100px !important"
            class="text-truncate"
            >{{ data.value }}</b-link
          >
          <div v-else>
            <b-input
              :class="{
                'is-invalid': $v.libelle.$error && $v.libelle.$dirty,
              }"
              placeholder="Libellé"
              v-model="libelle"
            ></b-input>
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.libelle.$dirty"
            >
              {{
                !$v.libelle.required
                  ? "champ obligatoire"
                  : !$v.libelle.minLength
                  ? `Le champ doit contenir au moins ${$v.libelle.$params.minLength.min} caractères.`
                  : ""
              }}
            </span>
            <!--  -->
          </div>
        </template>

        <template #cell(deadline)="data">
          <span
            class="text-white text-center px-2 py-1 rounded"
            v-b-tooltip.hover="
              echeanceStatus(
                data.item.createdAt,
                data.value,
                data.item.status
              ).toUpperCase()
            "
            :style="{
              backgroundColor: dateBackgroundColor(
                data.item.createdAt,
                data.value,
                data.item.status
              ),
            }"
            v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
            >{{
              data.value
                ? moment(data.value, "DD/MM/YYYY").format("DD/MM/YYYY")
                : "-"
            }}</span
          >
          <div class="row col-12 p-0 m-0" v-else>
            <date-picker
              :input-attr="{
                class: {
                  'is-invalid': $v.dDeadline.$error && $v.dDeadline.$dirty,
                  ' form-control': true,
                },
              }"
              format="DD/MM/YYYY"
              v-model="dDeadline"
              placeholder="Date fin"
            ></date-picker>
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.dDeadline.$dirty"
            >
              {{ !$v.dDeadline.required ? "champ obligatoire" : "" }}
            </span>
            <!--  -->
          </div>
        </template>
        <template #cell(actions)="data">
          <table-actions
            :actions="['edit', 'delete']"
            :editMode="data.item.hasOwnProperty('editMode')"
            @confirmItem="confirmItem(data.item)"
            @cancelItem="cancelItem()"
            @deleteItem="deleteTask(data.item.id)"
            @editItem="editItem(data.item)"
          ></table-actions>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="table-list"
        align="right"
      >
      </b-pagination>
    </div>

    <div v-else>
      <div
        v-if="$route.params.id"
        class="d-flex m-0 p-0 table_responsivo shadow-sm border"
      >
        <!-- <tr> -->
        <div class="col-3 m-0 p-0">
          <div class="table h-100" style="min-height: 400px">
            <div class="header_sd_menu d-flex align-items-center">
              <th class="col-6 m-auto">Tâche</th>
              <th class="col-6 m-auto">Responsable</th>
            </div>

            <div v-if="!tableItems.length" class="text-center text-gray h-30">
              Table vide
            </div>
            <div
              v-for="(item, index) in tableItems"
              :key="index + '1245'"
              class="sd_menu_row d-flex align-items-center"
              :style="{
                backgroundColor: index % 2 == 0 ? 'white' : '#f5f5f5',
                height: '38px !important',
              }"
            >
              <p
                class="fl-2 col-6 mr-auto my-auto text-truncate"
                style="max-width: 150px !important"
              >
                <router-link
                  role="link"
                  :to="{
                    name: 'show-task',
                    params: {
                      slug: item.slug || 'abcde',
                      id: item.id,
                    },
                  }"
                  class="col-12 p-0"
                >
                  {{ item.libelle }}
                </router-link>
              </p>

              <p class="fl-2 text-center col-6 m-auto align-items-center">
                <b-avatar
                  v-b-tooltip.hover="
                    item.responsable.firstname + ' ' + item.responsable.lastname
                  "
                  style="
                    background-color: #adb5bd;
                    border-color: #adb5bd;
                    height: 30px;
                    width: 30px;
                  "
                  class="mr-1"
                  :src="IMG_URL + item.responsable.avatarUrl"
                >
                  <div
                    v-if="!item.responsable.avatarUrl"
                    style="
                      width: 25px;
                      height: 25px;
                      border-radius: 15px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    {{
                      item.responsable.firstname[0].toUpperCase() +
                      item.responsable.lastname[0].toUpperCase()
                    }}
                  </div>
                </b-avatar>
              </p>
            </div>
          </div>
          <div
            v-for="(item, index) in tableItems"
            :key="index + '1245'"
            class="sd_menu_row d-flex align-items-center"
            :style="{
              backgroundColor: index % 2 == 0 ? 'white' : '#f5f5f5',
            }"
          >
            <p class="fl_2 pl-2">
              {{ item.title }}
            </p>
          </div>
        </div>

        <div class="col-9 m-0 p-0">
          <frappe-gantt
            v-if="tableItems.length"
            :view-mode="mode"
            :tasks="
              tableItems.map((item) => ({
                id: `task-${item.id}`,
                progress: item.avancement || 0,
                name: `${item.avancement || 0}%`,
                start: formatDate(item.startedAt),
                end: formatDate(item.deadline),
                taskId: item.id,
              }))
            "
            style="width: auto; height: 100%"
            @task-date-updated="updateTasKDate"
            @task-progress-updated="updateTasKProgress"
          />
        </div>
      </div>
      <div
        v-else
        class="text-gray d-flex h2 text-center w-100"
        style="height: 100px"
      >
        <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
        <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
      </div>
    </div>
    <!-- Add Task MODAL -->
    <div to="modals-xyz-548" v-if="showAddTask">
      <modal @close="showAddTask = !showAddTask">
        <add-task
          @close="showAddTask = !showAddTask"
          :initiative="initiative"
        />
      </modal>
    </div>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import FrappeGantt from "@/components/custom/frappe-gantt.vue";
import AddTask from "./AddTask.vue";
import Modal from "@/components/custom/Modal.vue";
import TableResponsable from "@/components/custom/TableResponsable.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import CustomAddButton from "@/components/custom/CustomAddButton";
import moment from "moment";
import { concat } from "lodash";
import { datePickerFormat } from "@/helpers/DatePickerFormat.js";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import DropdownSelection from "@/components/custom/DropdownSelection.vue";
import { IMG_URL } from "@/helpers/services/api";
import { slice } from "lodash";
export default {
  components: {
    TableActions,
    FrappeGantt,
    // DropDown,
    TableResponsable,
    AddTask,
    Modal,
    // Kanban,
    CustomAddButton,
    DropdownSelection,
  },
  props: {
    initiative: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    tasks: Array,
  },
  data() {
    return {
      IMG_URL,
      sortBy: "",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedResponsable: null,
      selectedPhase: null,
      libelle: "",
      status: "",
      type: "",
      dDeadline: null,
      startedAt: null,
      showAddTask: false,
      tableItems: [],
      loading: true,

      endPrevDate: new Date(),
      mode: "day",

      ganttView: [
        {
          text: "Jour",
          id: 1,
          color: " btn-inverse-primary",
        },
        {
          text: "Semaine",
          id: 2,
          color: "btn-inverse-danger ",
        },
        {
          text: "Mois",
          id: 3,
          color: "btn-inverse-info ",
        },
        // {
        //   text: "Année",
        //   id: 4,
        //   color: "btn-inverse-secondary ",
        // },
      ],
      selectedView: {
        text: "Jour",
        id: 1,
        color: " btn-inverse-primary",
      },

      viewOptions: [
        { value: "tableau", text: "Tableau" },
        { value: "gantt", text: "Gantt" },
      ],
      view: { value: "tableau", text: "Tableau" },
    };
  },
  validations: {
    selectedPhase: {
      required,
    },
    selectedResponsable: {
      required,
    },
    libelle: {
      required,
      minLength: minLength(4),
    },
    type: {
      required,
    },
    status: {
      required,
    },
    dDeadline: {
      required,
      minValue(val) {
        return this.startedAt
          ? moment(val, "DD/MM/YYYY").diff(
              moment(this.startedAt, "DD/MM/YYYY")
            ) +
              1 >
              0
          : true;
      },
    },
  },
  methods: {
    slice,
    formatDate(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },
    dateBackgroundColor: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "#38b000";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "#d20f35";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "#ffd60a";
      else return "#38b000";
      // },
    },

    echeanceStatus: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "Respectée";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "En retard";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "En cours";
      else return "Nouveau";
    },
    onDragged: function (e, f) {
      let index = this.blocks.findIndex((it) => it.id == e);

      this.blocks[index].progressVariant =
        f == "En cours"
          ? "danger"
          : f == "Non démaré"
          ? "secondary"
          : f == "Suspendu"
          ? "warning"
          : "success";
    },
    deleteTask(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("task/deleteTask", id).then(() => {
            this.$store
              .dispatch(
                "initiative/fetchInitiativeTasks",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
    editItem(item) {
      this.$store
        .dispatch("initiative/fetchInitiativeTasks", this.$route.params.id)
        .then(() => {
          this.tableItems = this.tableItems.map((task) =>
            item.id === task.id ? { ...task, editMode: true } : task
          );
          this.selectedResponsable = item.responsable["@id"];
          this.selectedPhase = item.phase["@id"];
          this.libelle = item.libelle;
          this.type = item.type;
          this.status = item.status;
          this.dDeadline = item.dDeadline;
          this.startedAt = item.startedAt;
        });
    },
    confirmItem(item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("task/updateTask", {
            id: item.id,
            responsable: this.selectedResponsable,
            deadline: moment(item.dDeadline).format("DD/MM/YYYY"),
            phase: this.selectedPhase,
            type: this.type,
            status: this.status,
            libelle: this.libelle,
          })
          .then(() => {
            // fetch data again
            this.$store
              .dispatch(
                "initiative/fetchInitiativeTasks",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire("La tâche est mise à jour !", "", "success");
              });
          });
      }
    },
    cancelItem() {
      if (
        this.selectedResponsable === null &&
        this.selectedPhase === null &&
        this.dDeadline === "" &&
        this.libelle === "" &&
        this.type === "" &&
        this.status === ""
      )
        this.$store.dispatch(
          "initiative/fetchInitiativeTasks",
          this.$route.params.id
        );
      else {
        this.selectedResponsable = null;
        this.selectedPhase = null;
        this.dDeadline = "";
        this.libelle = "";
        this.type = "";
        this.status = "";
      }
    },
    changeView: function (val) {
      this.mode =
        val == 0 ? "day" : val == 1 ? "week" : val == 2 ? "month" : "year";
      this.selectedView = this.ganttView[val];
    },
    updateTasKDate: function (task, start, end) {
      if (!this.disabled) {
        this.tableItems = this.tableItems.map((item) => {
          if (item.id == task.taskId) {
            item.deadline = moment(end).format("DD/MM/YYYY");
            item.startedAt = moment(start).format("DD/MM/YYYY");
          }
          return item;
        });
        this.$store.dispatch("task/updateTask", {
          id: task.taskId,
          startedAt: moment(start).format("DD/MM/YYYY"),
          deadline: moment(end).format("DD/MM/YYYY"),
        });
      } else this.updateTasKProgress();
    },

    updateTasKProgress: function (/*task, progress*/) {
      this.$store.dispatch(
        "initiative/fetchInitiativeTasks",
        this.$route.params.id
      );
    },
  },
  created() {
    this.$store.dispatch("phase/fetchAllPhases");
    this.$store.dispatch("type/fetchTaskTypes");
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("status/fetchTaskStatus");
    if (this.$route.params.id)
      this.$store
        .dispatch("initiative/fetchInitiativeTasks", this.$route.params.id)
        .then(() => {
          this.loading = false;
        });
    else this.loading = false;
  },
  computed: {
    ...mapGetters("phase", ["PHASES"]), //get all phases
    ...mapGetters("type", ["TASK_TYPES"]), //get all status
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("status", ["TASK_STATUS"]), //get all status
    ...mapGetters("initiative", ["INITIATIVE", "TASKS"]), //get all status
    typesTask() {
      return this.TASK_TYPES;
    },
    statusTasks() {
      return this.TASK_STATUS;
    },
    responsables() {
      //return array of responsables
      return this.RESPONSABLES.map((responsable) => ({
        id: `/api/users/${responsable.id}`,
        value: `/api/users/${responsable.id}`,
        full_name: responsable.firstname + " " + responsable.lastname,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    phasesList() {
      this.selectedInitiative;
      return this.INITIATIVE.phases.map((phase) => ({
        ...phase,
        value: phase["@id"],
        text: phase.libelle,
      }));
    },
    rows() {
      return this.tableItems.length;
    },
    tableFields() {
      var fields = [
        { key: "phase", label: "Phase", sortable: true },
        { key: "libelle", label: "Tâche", sortable: true },
        { key: "type", label: "Type tâche", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        {
          key: "deadline",
          label: "Date fin prévisionnelle",
          sortable: true,
        },
        { key: "status", label: "Statut", sortable: true },
        { key: "avancement", label: "Avancement", sortable: true },
      ];
      if (!this.disabled) {
        fields.push({
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        });
      }

      return fields;
    },
    ganttData() {
      return slice(
        this.filteredTasks,
        (this.ganttCurrentPage - 1) * this.perPage,
        this.ganttCurrentPage * this.perPage - 1
      );
    },
  },

  watch: {
    TASKS: function () {
      var mytasks = [];
      this.TASKS.phases
        .filter((phase) => phase.tasks.length)
        .map((phase) =>
          phase.tasks.map((task) => {
            mytasks = concat(mytasks, {
              ...task,
              phase: phase,
              dDeadline: datePickerFormat(task.deadline),
            });
          })
        );
      // .map((phase) => phase[0]);
      this.tableItems = mytasks;
    },
  },
};
</script>

<style>
#dropdown-1__BV_toggle_ {
  background: #38b000 !important;
  border-color: #38b000 !important;
  width: 140px !important;
}
.custom-drop .dropdown-item,
.custom-drop .dropdown-menu {
  padding: 0 !important;
}
.header_sd_menu {
  height: 60px;
  background: #37d3bc !important;
  width: 100%;
  color: white;
  border: 0;
}

.sd_menu_row {
  width: 100%;
  height: 38px;
}
</style>
