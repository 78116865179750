<template>
  <div>
    <div class="row" style="margin-top: -56px">
      <div class="col-10">
        <p class="h2 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light"
            >Initiative :
          </span>
          <span style="font-size: 22px">{{ initiativeName }}</span>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 d-flex justify-content-between mb-3">
        <span class="h4 my-auto text-secondary"
          >Recherche cadres d'entreprise</span
        >
        <div>
          <b-button variant="secondary" @click="$emit('close')"
            >Annuler</b-button
          >
          <!-- :disabled="!selectedResults.tableItems.length || submitingForm" -->

          <b-button variant="secondary" @click="addPattern">
            <b-spinner
              small
              v-if="submitingForm"
              class="text-white"
              label="Loading..."
            ></b-spinner
            >Confirmer</b-button
          >
        </div>
      </div>

      <div class="col-5">
        <div class="d-flex">
          <span class="h4 text-secondary my-auto">Résultats</span>
          <b-form-input
            v-model="resultSearch"
            placeholder="Reherche"
            type="text"
            class="ml-auto col-6"
          />
        </div>
        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>
        <b-table
          selectable
          @row-selected="onRowSelectedResults"
          responsive
          :items="resultItems"
          :fields="fields"
          style="max-height: 500px"
          show-empty
          striped
          bordered
          :busy="loading"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <template v-slot:cell(code)="data">CA-{{ data.item.id }} </template>

          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template></b-table
        >
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
      <div
        class="col-2 d-flex flex-column justify-content-stretch"
        style="font-size: 40px; gap: 5px"
      >
        <div class="d-flex">
          <b-button @click="swapAll" class="m-auto" variant="light">
            <i class="mdi mdi-swap-horizontal"></i>
          </b-button>
        </div>
        <div class="d-flex">
          <b-button @click="moveRight" class="m-auto" variant="light">
            <i class="mdi mdi-arrow-right"></i>
          </b-button>
        </div>
        <div class="d-flex">
          <b-button @click="moveLeft" class="m-auto" variant="light">
            <i class="mdi mdi-arrow-left"></i>
          </b-button>
        </div>
      </div>
      <div class="col-5">
        <div class="d-flex">
          <span class="h4 text-secondary my-auto">Eléments séléctionnés</span>
          <b-form-input
            v-model="selectSearch"
            placeholder="Reherche"
            type="text"
            class="ml-auto col-6"
          />
        </div>

        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>
        <b-table
          selectable
          @row-selected="onRowSelected"
          responsive
          :items="selectedItems"
          :fields="fields"
          style="max-height: 500px"
          show-empty
          :busy="loading"
          striped
          bordered
          :per-page="sPerPage"
          :current-page="sCurrentPage"
        >
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(code)="data">CA-{{ data.item.id }} </template>
        </b-table>
        <b-pagination
          v-model="sCurrentPage"
          :total-rows="selectRowsLength"
          :per-page="sPerPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  props: {
    initiativeName: String,
    oldPatterns: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    submitingForm: false,
    loading: true,
    fields: [
      { key: "code", label: "Code cadre", sortable: true },
      { key: "type", label: "Type cadre", sortable: true },
      { key: "name", label: "Libellé cadre", sortable: true },
    ],
    results: {
      tableItems: [],
      selected: [],
    },
    selectedResults: {
      tableItems: [],
      selected: [],
    },
    resultSearch: "",
    selectSearch: "",

    currentPage: 1,
    perPage: 5,
    sCurrentPage: 1,
    sPerPage: 5,
  }),
  methods: {
    onRowSelectedResults(selectedRows) {
      this.results.selected = selectedRows;
    },
    onRowSelected(selectedRows) {
      this.selectedResults.selected = selectedRows;
    },
    moveRight() {
      this.results.tableItems = this.results.tableItems.filter(
        (item) =>
          !this.results.selected.some(
            (selectedItem) => selectedItem.id === item.id
          )
      );
      this.selectedResults.tableItems = [
        ...this.selectedResults.tableItems,
        ...this.results.selected,
      ];
      this.results.selected = [];
    },
    moveLeft() {
      // remove selected items from selected results table
      this.selectedResults.tableItems = this.selectedResults.tableItems.filter(
        (item) =>
          !this.selectedResults.selected.some(
            (selectedItem) => selectedItem.id === item.id
          )
      );

      // add selected items to results table
      this.results.tableItems = [
        ...this.results.tableItems,
        ...this.selectedResults.selected,
      ];

      // Empty the selections
      this.selectedResults.selected = [];
    },
    swapAll() {
      this.moveLeft();

      this.moveRight();
    },
    addPattern() {
      this.$emit("close");
      this.submitingForm = true;
      var deletedPattern = this.oldPatterns.filter(
        (pattern) =>
          !this.selectedResults.tableItems.find(
            (cadre) => pattern.cadre.id === cadre.id
          )
      );
      let createdPatterns = this.selectedResults.tableItems.filter(
        (cadre) =>
          !this.oldPatterns.find((pattern) => pattern.cadre.id === cadre.id)
      );
      deletedPattern.map((item, i) => {
        this.$store.dispatch("pattern/deletePattern", item.id).then(() => {
          if (deletedPattern.length - 1 === i)
            this.$store
              .dispatch("initiative/fetchInitiative", this.$route.params.id)
              .then(() => {
                if (!createdPatterns.length)
                  // Swal.fire({
                  //   title: "Le cadre est bien créé !",
                  //   type: "success",
                  //   confirmButtonText: "OK",
                  // });
                  // else {
                  Swal.fire("Le cadre est mis à jour !", "", "success");
                // }
              });
        });
      });

      createdPatterns.map((item, i) => {
        this.$store
          .dispatch("pattern/createPattern", {
            initiative: `/api/transformation/initiatives/${this.$route.params.id}`,
            cadre: item["@id"],
            name: `CA-INI-${item.name}`,
          })
          .then(() => {
            if (createdPatterns.length - 1 === i)
              this.$store
                .dispatch("initiative/fetchInitiative", this.$route.params.id)
                .then(() => {
                  Swal.fire("Le cadre est mis à jour !", "", "success");
                });
          });
        this.submitingForm = false;
      });

      // });
    },
  },
  created() {
    this.$store.dispatch("cadre/fetchAllCadres").then(() => {
      this.loading = false;
    });
  },
  computed: {
    ...mapGetters("cadre", ["CADRE_ENTREPRISES"]), //get all status
    resultItems() {
      return this.results.tableItems
        ? this.results.tableItems.filter(
            (item) =>
              item.name.includes(this.resultSearch) ||
              ("CA-" + item.id).includes(this.resultSearch)
          )
        : [];
    },
    selectedItems() {
      return this.selectedResults.tableItems.filter(
        (item) =>
          item.name.includes(this.selectSearch) ||
          ("CA-" + item.id).includes(this.selectSearch)
      );
    },
    rows() {
      return this.resultItems.length;
    },
    selectRowsLength() {
      return this.selectedItems.length;
    },
  },

  watch: {
    CADRE_ENTREPRISES: function () {
      this.results.tableItems = this.CADRE_ENTREPRISES.filter(
        (cadre) =>
          !this.oldPatterns.find((pattern) => pattern.cadre.id === cadre.id)
      );
      this.selectedResults.tableItems = this.CADRE_ENTREPRISES.filter((cadre) =>
        this.oldPatterns.find((pattern) => pattern.cadre.id == cadre.id)
      );
    },
    oldPatterns: function () {
      this.selectedResults.tableItems = this.CADRE_ENTREPRISES.filter((cadre) =>
        this.oldPatterns.find((pattern) => pattern.cadre.id == cadre.id)
      );
      this.results.tableItems = this.CADRE_ENTREPRISES.filter(
        (cadre) =>
          !this.oldPatterns.find((pattern) => pattern.cadre.id === cadre.id)
      );
    },
  },
};
</script>

<style>
.sub-modal {
  max-height: 95% !important;
}
</style>
