var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-6 row p-0",attrs:{"id":"add-page"}},[_c('div',{staticClass:"col-12 p-0 mb-auto"},[_vm._m(0),_vm._m(1),_c('b-table',{attrs:{"responsive":"","fields":_vm.tableFields,"items":_vm.tableItems,"show-empty":"","bordered":"","striped":"","busy":_vm.loading,"per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]},proxy:true},{key:"cell(contributions)",fn:function(data){return [(data.value.length)?_c('b-badge',{staticStyle:{"cursor":"pointer","background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"pill":""},on:{"click":function($event){(_vm.selectedContributions = data.value),
              (_vm.showContribution = true),
              (_vm.taskName = data.item.task.libelle)}}},[_vm._v(_vm._s(data.value.length))]):_c('b-badge',{staticStyle:{"cursor":"pointer","background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"pill":""}},[_vm._v(_vm._s(0))])]}},{key:"cell(task)",fn:function(data){return [(!data.item.hasOwnProperty('editMode') || data.item.id)?_c('b-link',{attrs:{"to":{
            name: 'show-task',
            params: {
              id: data.value.id,
              slug: data.value.slug,
            },
          }}},[_vm._v(_vm._s(data.value.libelle))]):(!data.item.id)?_c('div',[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
              'is-invalid': _vm.$v.selectedTask.$error && _vm.$v.selectedTask.$dirty,
            },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticStyle:{"min-width":"max-content","background":"transparent"},attrs:{"label":"text","appendToBody":"","reduce":(task) => task.value,"options":_vm.tasksList},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}],null,true),model:{value:(_vm.selectedTask),callback:function ($$v) {_vm.selectedTask=$$v},expression:"selectedTask"}})],1),(_vm.$v.selectedTask.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.selectedTask.required ? "Champ obligatoire" : "")+" ")]):_vm._e()]):_vm._e()]}},{key:"cell(phase)",fn:function(data){return [(!data.item.hasOwnProperty('editMode') || data.item.id)?_c('span',[_vm._v(_vm._s(data.value))]):(!data.item.id)?_c('div',[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
              'is-invalid':
                _vm.$v.selectedPhase.$error && _vm.$v.selectedPhase.$dirty,
            },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticStyle:{"min-width":"max-content","background":"transparent"},attrs:{"label":"text","appendToBody":"","reduce":(phase) => phase.value,"options":_vm.phasesList},on:{"input":function($event){_vm.selectedTask = null}},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}],null,true),model:{value:(_vm.selectedPhase),callback:function ($$v) {_vm.selectedPhase=$$v},expression:"selectedPhase"}})],1),(_vm.$v.selectedPhase.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.selectedPhase.required ? "Champ obligatoire" : "")+" ")]):_vm._e()]):_vm._e()]}},{key:"cell(chargesEstime)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[_vm._v(_vm._s(data.value))]):_c('div',[_c('b-form-input',{class:{
              'is-invalid':
                _vm.$v.chargesEstime.$error && _vm.$v.chargesEstime.$dirty,
            },attrs:{"type":"number","min":"0"},model:{value:(_vm.chargesEstime),callback:function ($$v) {_vm.chargesEstime=$$v},expression:"chargesEstime"}}),(_vm.$v.chargesEstime.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.chargesEstime.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1)]}},{key:"cell(chargesRestantes)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[_vm._v(_vm._s(data.value))]):_c('div',[_c('b-form-input',{class:{
              'is-invalid':
                _vm.$v.chargesRestantes.$error && _vm.$v.chargesRestantes.$dirty,
            },attrs:{"type":"number","min":"0"},model:{value:(_vm.chargesRestantes),callback:function ($$v) {_vm.chargesRestantes=$$v},expression:"chargesRestantes"}}),(_vm.$v.chargesRestantes.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.chargesRestantes.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1)]}},{key:"cell(actions)",fn:function(data){return [_c('table-actions',{attrs:{"actions":['delete'],"editMode":data.item.hasOwnProperty('editMode')},on:{"confirmItem":function($event){return _vm.confirmItem(data.item)},"cancelItem":function($event){return _vm.cancelItem(data.item)},"editItem":function($event){return _vm.editItem(data.item)},"deleteItem":function($event){return _vm.deleteItem(data.item.id)}}})]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true}])})],1),_c('b-pagination',{staticClass:"mt-auto ml-auto",attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),(_vm.showContribution)?_c('div',{attrs:{"to":"modals-xyz-548"}},[_c('Modal',{on:{"close":function($event){_vm.showContribution = !_vm.showContribution}}},[_c('ContributionList',{attrs:{"initiativeName":_vm.initiativeName,"taskName":_vm.taskName,"contributions":_vm.selectedContributions},on:{"close":function($event){_vm.showContribution = !_vm.showContribution}}})],1)],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 d-flex p-0"},[_c('span',{staticClass:"h4 text-secondary mr-auto my-auto"},[_vm._v("Imputations par type de ressources ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 p-0"},[_c('hr',{staticClass:"mt-1 mb-3 bg-secondary"})])
}]

export { render, staticRenderFns }