<template>
  <div class="col-md-4 col-12 p-0 ml-auto">
    <div class="col-12 p-0">
      <span class="h4 text-secondary mr-auto my-auto"
        >Imputations par Direction
      </span>

      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <div class="h-100 d-flex">
      <PieChart
        v-if="!loading && this.IMPUTATIONS_BY_DIRECTIONS.length"
        style="width: 100%"
        :options="directionPieOptions"
      />
      <div v-else-if="loading" class="text-center text-primary my-auto w-100">
        <b-spinner class="align-middle mr-2"></b-spinner>
        <strong>Chargement...</strong>
      </div>
      <div
        v-else
        class="text-gray d-flex h2 text-center w-100"
        style="height: 166px"
      >
        <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
        <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "@/components/charts/c3-chart/donut-c3.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    PieChart,
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.$store
      .dispatch(
        "initiative/fetchImputationsByDirections",
        this.$route.params.id
      )
      .then(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters("initiative", ["IMPUTATIONS_BY_DIRECTIONS"]),
    directionPieOptions() {
      return {
        data: {
          columns: this.IMPUTATIONS_BY_DIRECTIONS.map((imputation) => [
            imputation.organi,
            imputation.compte,
          ]),
          type: "pie",
        },
        color: {
          pattern: ["#FFC000", "#072648", "#e71d36", "#28affa"],
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 30,
          left: 0,
        },
        bar: {
          width: {
            ratio: 0.7, // this makes bar width 50% of length between ticks
          },
        },
      };
    },
  },
  watch: {
    IMPUTATIONS_BY_DIRECTIONS() {
      window.console.log(this.directionPieOptions, "IMPUTATIONS_BY_DIRECTIONS");
      //   this.directionPieOptions.data.columns =
      //     this.IMPUTATIONS_BY_DIRECTIONS.map((imputation) => [
      //       imputation.organi,
      //       imputation.compte,
      //     ]);
    },
  },
};
</script>