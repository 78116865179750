<template>
  <div style="margin-top: -56px">
    <p class="text-secondary my-auto mr-auto">
      <i :class="$route.meta.iconClass"></i>
      <span style="font-size: 18px" class="font-weight-light">
        Initiative :
      </span>
      <span style="font-size: 22px">{{ libelle }}</span>
    </p>
    <div class="d-flex align-items-center py-4">
      <span class="d-inline-block w-75 h4 mb-0">Recherche invité:</span>
      <v-select
        class="w-100"
        placeholder="Nom"
        :options="responsables"
        v-model="selectedUser"
        :reduce="(responsable) => responsable.value"
        label="text"
      >
        <template #no-options> Liste vide </template>
      </v-select>
      <v-select
        class="w-100"
        placeholder="Organisation"
        :options="organisations"
        :reduce="(organisation) => organisation.value"
        label="text"
      >
        <template #no-options> Liste vide </template>
      </v-select>
      <b-button
        class="ml-1"
        @click="$emit('cofirmUser', selectedGuest) && $emit('close')"
        :disabled="selectedGuest === null"
        type=""
      >
        Confirmer</b-button
      >
    </div>

    <b-table
      :fields="fields"
      :items="filteredUsers"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loading"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #cell(selection)="data">
        <div class="d-flex justify-content-center">
          <b-form-radio
            v-model="selectedGuest"
            :value="data.item.id"
          ></b-form-radio>
        </div>
      </template>
      <template #cell(firstname)="data">
        <span> {{ data.item.firstname + " " + data.item.lastname }}</span>
      </template>
      <template #cell(organisation)="data">
        <span> {{ data.value ? data.value : "-" }}</span>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    libelle: String,
  },
  data() {
    return {
      sortBy: "name",
      perPage: 4,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedGuest: null,
      selectedUser: null,
      loading: true,
      fields: [
        { key: "firstname", label: "Prénom et Nom" },
        { key: "organisation", label: "Organisation" },
        {
          key: "selection",
          label: "Sélection",
          thStyle: { textAlign: "center" },
        },
      ],
    };
  },
  created() {
    this.$store
      .dispatch("users/fetchAllResponsables")
      .then(() => (this.loading = false));
    this.$store.dispatch("status/fetchFdrStatus");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("type/fetchFdrTypes");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
    this.$store.dispatch("entity/fetchAllEntities");
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("status", ["FDR_STATUS"]), //get all status
    ...mapGetters("organisation", ["ORGANISATIONS"]), //get all organisations
    ...mapGetters("type", ["FDR_TYPES"]),
    ...mapGetters("patrimoine", ["PATRIMOINES"]),
    ...mapGetters("entity", ["ENTITIES"]),
    responsables() {
      //return array of responsables

      return this.RESPONSABLES.map((responsable) => ({
        ...responsable,
        value: responsable.id,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    statusFdr() {
      return this.FDR_STATUS;
    },
    organisations() {
      return this.ORGANISATIONS.map((organisation) => ({
        value: `/api/referentiel/organisations/${organisation.id}`,
        text: `${organisation.entite.libelle}/${organisation.libelle}`,
      }));
    },
    fdrTypes() {
      return this.FDR_TYPES;
    },
    patrimoineList() {
      return this.PATRIMOINES.map((patrimoine) => ({
        value: patrimoine.id,
        text: patrimoine.name,
      }));
    },
    filteredUsers() {
      return this.selectedUser
        ? this.RESPONSABLES.filter((user) => user.id === this.selectedUser)
        : this.RESPONSABLES;
    },
    entityList() {
      return this.ENTITIES.map((entite) => ({
        value: `api/referentiel/entites/${entite.id}`,
        text: entite.libelle,
      }));
    },
    rows() {
      return this.filteredUsers.length;
    },
  },
};
</script>

<style></style>
