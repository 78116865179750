<template>
  <div class="w-100 d-flex flex-column border p-2 mt-4 rounded">
    <!-- foreach transformation in transformation table -->
    <div
      v-for="(transformation, index) in transformations"
      :key="index"
      class="w-100 mb-3"
     >
      <p class="text-secondary pb-0">{{ transformation.title }}</p>
      <div class="d-flex">
        <!-- foreach column of transformation columns -->
        <div
          v-for="(column, columnIdx) in transformation.columns"
          :key="columnIdx"
          class="flex-grow-1 mx-1 rounded bg-light shadow-sm border p-2"
          style="width: 33%"
        >
          <p class="text-secondary">{{ column.title }}</p>
          <div>
            <div
              @click="showModal = !showModal"
              v-for="(item, itemIdx) in column.items"
              :key="itemIdx"
              :class="
                'rounded px-3 mr-1 py-2 mb-1 d-inline-block text-white ' +
                setBgColor(item.status)
              "
              style="cursor: pointer"
            >
              {{ item.title }}
            </div>
            <div class="text-gray text-center w-100" v-if="!column.items.length">
              <i class="mdi mdi-information-outline my-auto"></i>
              section vide
            </div>
          </div>
        </div>
      </div>
    </div>
    <div to="modals-xyz-548" v-if="showModal">
			<modal @close="showModal = !showModal">
			<slot></slot>
			</modal>
		</div>
  </div>
</template>
<script>
import Modal from './Modal.vue'
export default {
  components:{Modal},
  data() {
    return {
      transformations: [
        {
          title: "Digital Platform",
          columns: [
            { title: "Customer Relationship", items: [] },
            {
              title: "Portal",
              items: [
                {
                  title: "mesCongés",
                  status: "danger",
                },
              ],
            },
          ],
        },
        {
          title: "Core Banking",
          columns: [
            {
              title: "Assets Management",
              items: [
                {
                  title: "financesAsset",
                  status: "secondary",
                },
                {
                  title: "refAsset",
                  status: "secondary",
                },
              ],
            },
            {
              title: "Leasing",
              items: [
                {
                  title: "myLeaseUp",
                  status: "success",
                },
                {
                  title: "leaseGroup",
                  status: "success",
                },
              ],
            },
            {
              title: "Insurance",
              items: [],
            },
          ],
        },
        {
          title: "Transverse",
          columns: [
            {
              title: "RH",
              items: [
                {
                  title: "RH Access",
                  status: "success",
                },
                {
                  title: "Paie System",
                  status: "danger",
                },
              ],
            },
            {
              title: "Finance",
              items: [
                {
                  title: "oracleFI",
                  status: "secondary",
                },
              ],
            },
            {
              title: "Doc Management",
              items: [
                {
                  title: "GED Ocube",
                  status: "warning",
                },
              ],
            },
          ],
        },
      ],
      showModal:false,
    };
  },
  methods: {
    setBgColor(status) {
      switch (status) {
        case "danger":
          return "bg-danger";
        case "warning":
          return "bg-warning";
        case "secondary":
          return "bg-secondary";
        case "success":
          return "bg-success";
        default:
          return "bg-dark";
      }
    },
  },
};
</script>
