<template>
  <div>
    <div class="d-flex">
      <span class="h4 text-secondary mr-1 my-auto">Liste des phases </span>

      <dropdown-selection
        :text="view.text"
        :items="viewOptions"
        @selected="(item) => (view = item)"
      />

      <b-dropdown
        id="dropdown-1"
        v-if="view.value === 'gantt'"
        :text="selectedView.text"
        :class="`custom-drop my-auto ml-1 btn-outline-info ${selectedView.color} `"
      >
        <b-dropdown-item
          class="col-sm-6 col-md-auto m-0 p-0"
          v-for="(view, index) in ganttView"
          :key="index"
        >
          <button
            @click="changeView(index)"
            type="button"
            :class="`btn ${view.color} btn-fw col-sm-12`"
          >
            {{ view.text }}
          </button>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <b-table
      responsive
      :items="tableItems"
      :fields="tableFields"
      v-if="view.value === 'tableau'"
      show-empty
      bordered
      striped
      :busy="loading"
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>

      <template #cell(startedAt)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value || "-"
        }}</span>
        <div class="row w-100 m-0" v-else>
          <date-picker
            format="DD/MM/YYYY"
            v-model="data.item.dStartedAt"
            :input-attr="{
              class: {
                'is-invalid':
                  $v.tableItems.$each[data.index].dStartedAt.$error &&
                  $v.tableItems.$each[data.index].dStartedAt.$dirty,
                ' form-control': true,
              },
            }"
          ></date-picker>
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.tableItems.$each[data.index].dStartedAt.$dirty"
          >
            {{
              !$v.tableItems.$each[data.index].dStartedAt.required
                ? "Champ obligatoire"
                : ""
            }}
          </span>
        </div>
      </template>
      <template #cell(endingAt)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          <!-- {{ $v.tableItems.$each[data.index] }} -->
          {{ data.value || "-" }}</span
        >
        <div class="row w-100 m-0" v-else>
          <date-picker
            format="DD/MM/YYYY"
            v-model="data.item.dEndingAt"
            :input-attr="{
              class: {
                'is-invalid':
                  $v.tableItems.$each[data.index].dEndingAt.$error &&
                  $v.tableItems.$each[data.index].dEndingAt.$dirty,
                ' form-control': true,
              },
            }"
          ></date-picker>
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.tableItems.$each[data.index].dEndingAt.$dirty"
          >
            {{
              !$v.tableItems.$each[data.index].dEndingAt.required
                ? "Champ obligatoire"
                : $v.tableItems.$each[data.index].dEndingAt.required &&
                  $v.tableItems.$each[data.index].dEndingAt.$error
                ? "La date de fin ne doit pas être antérieur à la date de début"
                : ""
            }}
          </span>
        </div>
      </template>
      <template #cell(status)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value || "-"
        }}</span>
        <div id="add-page" class="row col-12 p-0 m-0" v-else>
          <div
            class="font-weight-normal p-0 form-control"
            style="padding: 0 !important; height: fit-content"
            :class="{
              'is-invalid':
                $v.tableItems.$each[data.index].status.$error &&
                $v.tableItems.$each[data.index].status.$dirty,
            }"
          >
            <v-select
              v-model="data.item.status"
              appendToBody
              style="min-width: max-content"
              class="bg-transparent"
              :options="['Non démarré', 'En cours', 'Terminé']"
            />
          </div>

          <!-- ERROR MESSAGES -->
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.tableItems.$each[data.index].status.$dirty"
          >
            {{
              !$v.tableItems.$each[data.index].status.required
                ? "Champ obligatoire"
                : ""
            }}
          </span>
          <!--  -->
        </div>
      </template>
      <template v-slot:cell(avancement)="data">
        <b-progress
          max="100"
          show-progress
          height="15px"
          style="background-color: #cacccd"
        >
          <b-progress-bar
            :value="data.value || 0"
            :label="`${data.value || 0}%`"
            :style="`background-color: ${
              data.value > 20 && data.value <= 70
                ? '#f5f245'
                : data.value > 70
                ? '#23d92c'
                : '#f5ba45'
            }`"
          ></b-progress-bar>
        </b-progress>
      </template>
      <template #cell(libelle)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value
        }}</span>
        <b-form-input
          v-else
          type="text"
          placeholder="Libellé"
          disabled
          v-model="data.value"
        ></b-form-input>
      </template>

      <template #cell(actions)="data">
        <table-actions
          :actions="['edit']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @confirmItem="confirmItem(data.item)"
          @cancelItem="cancelItem(data.item)"
          @editItem="editItem(data.item['@id'])"
        ></table-actions>
      </template>
    </b-table>
    <div
      v-else-if="view.value !== 'tableau' && disabled && !$route.params.id"
      class="text-gray d-flex h2 text-center w-100"
      style="height: 100px"
    >
      <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
      <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
    </div>
    <div v-else>
      <div class="d-flex m-0 p-0 table_responsivo shadow-sm border">
        <!-- <tr> -->
        <div style="max-width: 500px" class="col-sm-2 m-0 p-0">
          <div
            class="
              header_sd_menu
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <p class="fl_2 pl-2 mb-0 text-center">Phase</p>
          </div>
          <div
            v-for="(item, index) in ganttData"
            :key="index + '1245'"
            class="sd_menu_row d-flex align-items-center"
            :style="{
              backgroundColor: index % 2 == 0 ? 'white' : '#f5f5f5',
            }"
          >
            <p class="fl_2 pl-2">
              {{ item.libelle }}
            </p>
          </div>
        </div>
        <div class="col-sm-10 m-0 p-0">
          <frappe-gantt
            :view-mode="mode"
            :tasks="
              ganttData.map((it, i) => ({
                ...it,
                id: `phase-${i}`,
                progress: it.avancement,
                name: `${it.avancement}%`,
                start: moment(it.startedAt, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                end: moment(it.endingAt, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              }))
            "
            @task-updated="debugEventLog.push($event)"
            @task-date-updated="updateInitiativeDate"
            @task-progress-updated="updateInitiativeProgress"
            style="width: 100%; height: 100%"
          />
        </div>
        <!-- </tr> -->
      </div>
    </div>
  </div>
</template>

<script>
import FrappeGantt from "@/components/custom/frappe-gantt.vue";
import DropdownSelection from "@/components/custom/DropdownSelection.vue";
import TableActions from "@/components/custom/TableActions.vue";
import moment from "moment";
import { datePickerFormat } from "@/helpers/DatePickerFormat.js";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators"; //validation

export default {
  props: {
    newTasks: Array,
    planning: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FrappeGantt,
    TableActions,
    DropdownSelection,
  },
  data: () => ({
    startDate: null,
    endDate: null,
    mode: "day",
    tableItems: [],
    loading: true,
    ganttView: [
      {
        text: "Jour",
        id: 1,
        color: " btn-inverse-primary",
      },
      {
        text: "Semaine",
        id: 2,
        color: "btn-inverse-danger ",
      },
      {
        text: "Mois",
        id: 3,
        color: "btn-inverse-info ",
      },
      {
        text: "Année",
        id: 4,
        color: "btn-inverse-secondary ",
      },
    ],
    selectedView: {
      text: "Jour",
      id: 1,
      color: " btn-inverse-primary",
    },

    viewOptions: [
      { value: "tableau", text: "Tableau" },
      { value: "gantt", text: "Gantt" },
    ],
    view: { value: "tableau", text: "Tableau" },
  }),
  validations: {
    tableItems: {
      $each: {
        status: {
          required,
        },
        dEndingAt: {
          required,
          minValue(val) {
            return this.tableItems.dStartedAt
              ? moment(val, "DD/MM/YYYY").diff(
                  moment(this.tableItems.dStartedAt, "DD/MM/YYYY")
                ) +
                  1 >
                  0
              : true;
          },
        },
        dStartedAt: {
          required,
        },
      },
    },
  },
  methods: {
    updateInitiativeProgress: function () {
      this.$store.dispatch("initiative/fetchInitiative", this.$route.params.id);
    },
    updateInitiativeDate: function (task, start, end) {
      if (!this.disabled)
        this.$store
          .dispatch("phase/updatePhase", {
            id: task["@id"].substring(27),
            startedAt: moment(start).format("DD/MM/YYYY"),
            endingAt: moment(end).format("DD/MM/YYYY"),
          })
          .then(() => {
            this.$store.dispatch(
              "initiative/fetchInitiative",
              this.$route.params.id
            );
          });
      else {
        this.$store.dispatch(
          "initiative/fetchInitiative",
          this.$route.params.id
        );
      }
    },

    editItem(id) {
      this.$store
        .dispatch("initiative/fetchInitiative", this.$route.params.id)
        .then(() => {
          this.tableItems = this.tableItems.map((plan) =>
            plan["@id"] === id ? { ...plan, editMode: true } : plan
          );
        });
    },
    confirmItem: function (item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var id = item.id || item["@id"].substring(27);
        if (item.status !== null) {
          this.$store
            .dispatch("phase/updatePhase", {
              id: id,
              status: item.status,
              startedAt: moment(item.dStartedAt || new Date()).format(
                "DD/MM/YYYY"
              ),
              endingAt: moment(item.dEndingAt || new Date()).format(
                "DD/MM/YYYY"
              ),
            })
            .then(() => {
              // fetch data again
              this.$store
                .dispatch("initiative/fetchInitiative", this.$route.params.id)
                .then(() => {
                  Swal.fire("La phase est mise à jour !", "", "success");
                });
            });
        }
      }
    },
    cancelItem: function (item) {
      if (
        item.status === null &&
        item.dEndingAt === null &&
        item.dStartedAt === null
      ) {
        this.$store.dispatch(
          "initiative/fetchInitiative",
          this.$route.params.id
        );
      } else {
        item.status = item.dEndingAt = item.dStartedAt = null;
      }
    },
    changeView: function (val) {
      this.mode =
        val == 0 ? "day" : val == 1 ? "week" : val == 2 ? "month" : "year";
      this.selectedView = this.ganttView[val];
    },
  },
  mounted() {
    this.tableItems = this.planning;
    this.loading = false;
  },
  computed: {
    ganttData() {
      return this.tableItems;
    },
    tableFields() {
      var fields = [
        { key: "libelle", label: "Phase", sortable: true },
        { key: "startedAt", label: "Date début", sortable: true },
        { key: "endingAt", label: "Date fin", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "avancement", label: "Avancement", sortable: true },
      ];
      if (!this.disabled) {
        fields.push({
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        });
      }

      return fields;
    },
  },
  updated: function () {
    let progresses = document.querySelectorAll(".bar-progress");
    if (progresses.length > 0) {
      progresses.forEach((it) => {
        var avancement = parseInt(
          it.parentElement.lastElementChild.innerHTML.split("%")[0]
        );
        var color =
          avancement > 20 && avancement <= 70
            ? "#f5f245"
            : avancement > 70
            ? "#23d92c"
            : "#f5ba45";

        it.style.fill = color;
      });
    }
  },
  watch: {
    planning() {
      this.tableItems = this.planning.map((phase) => ({
        ...phase,
        dStartedAt: datePickerFormat(phase.startedAt),
        dEndingAt: datePickerFormat(phase.endingAt),
      }));
    },
  },
};
</script>
<style>
#dropdown-1__BV_toggle_ {
  background: #38b000 !important;
  border-color: #38b000 !important;
  width: 140px !important;
}
.custom-drop .dropdown-item,
.custom-drop .dropdown-menu {
  padding: 0 !important;
}
.header_sd_menu {
  height: 60px;
  background: #37d3bc !important;
  width: 100%;
  color: white;
  border: 0;
}
.gantt .grid-header {
  fill: #37d3bc !important;
  stroke: #e0e0e0;
  stroke-width: 1.4;
}
#gantt-table thead {
  background: #37d3bc !important;
}
.gantt .lower-text {
  fill: white;
}
.gantt .upper-text {
  fill: white;
}
.gantt .grid-row {
  fill: #ffffff;
}
</style>
