<template>
  <section class="tables">
    <!-- {{ FINANCE_INITIATIVE.ajustements }} -->
    <div class="row">
      <div class="col-lg-12 stretch-card">
        <div class="card">
          <div class="card-body p-0">
            <b-table
              :items="tableItems"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="loading"
              show-empty
              bordered
              striped
            >
              <!-- FOR LOADING ANIMATIONS -->
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Chargement...</strong>
                </div>
              </template>
              <!-- SHOW IF TABLE IS EMPTY -->
              <template #empty>
                <div class="text-center text-gray">Table vide</div>
              </template>

              <template v-slot:cell(montant)="data">
                <span class="mb-0 text-center d-block">{{
                  new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  }).format(data.value)
                }}</span>
              </template>

              <template v-slot:cell(budget)="data">
                <span class="mb-0 text-center d-block">{{
                  new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  }).format(data.value)
                }}</span>
              </template>

              <template v-slot:cell(rest)="data">
                <span class="mb-0 text-center d-block">{{
                  new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  }).format(data.value)
                }}</span>
              </template>

              <template v-slot:cell(createdAt)="data">
                <span class="mb-0 text-center d-block">{{
                  data.value ? moment(data.value).format("DD/MM/YYYY") : "-"
                }}</span>
              </template>

              <template v-slot:cell(tendance)="data">
                <div class="p-0 m-0 row">
                  <i
                    v-if="data.value < 0"
                    style="font-size: 20px; color: red"
                    class="mdi mdi-menu-down my-auto"
                  ></i>
                  <i
                    v-else-if="data.value > 0"
                    style="font-size: 20px; color: green"
                    class="mdi mdi-menu-up my-auto"
                  ></i>
                  <i
                    v-else
                    style="font-size: 20px; color: gray"
                    class="mdi mdi-menu-right my-auto"
                  ></i>
                  <p class="my-auto">{{ data.value }} %</p>
                </div>
              </template>
              <template v-slot:cell(responsable)="data">
                <span v-if="!data.item.hasOwnProperty('editMode') && data.value"
                  ><TableResponsable
                    v-if="!$route.path.includes('print')"
                    :responsable="data.value"
                  />
                  <span v-else>
                    {{ data.value.firstname + " " + data.value.lastname }}
                  </span>
                </span>
              </template>
            </b-table>

            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableResponsable from "@/components/custom/TableResponsable.vue";
import { mapGetters } from "vuex";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableResponsable,
  },

  data: function () {
    return {
      tableItems: [],
      sortBy: "name",
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        {
          key: "createdAt",
          label: "Date révision",
          sortable: true,
        },
        { key: "responsable", label: "Resp Fin", sortable: true },
        { key: "commentaire", label: "Commentaire", sortable: true },
        { key: "budget", label: "Budget initial", sortable: true },
        { key: "montant", label: "Montant ajusté", sortable: true },
        {
          key: "rest",
          label: "Budget initiative révisé",
          sortable: true,
        },
        { key: "tendance", label: "Tendance", sortable: true },
      ],
      items: [],
    };
  },
  computed: {
    ...mapGetters("financeInitiative", ["FINANCE_INITIATIVE"]),

    rows() {
      return this.tableItems.length;
    },
  },
  methods: {
    currentBudget(i) {
      if (i == 0) {
        return parseFloat(this.finance.ajustements[0].montant);
      } else if (i >= 1) {
        return (
          this.currentBudget(i - 1) +
          parseFloat(this.finance.ajustements[i - 1].montant)
        );
      } else return parseFloat(this.budget);
    },
    budgetInitiative(index) {
      return index === 0 ? 0 : this.budgetRevise(index - 1);
    },
    budgetRevise(index) {
      let budget = parseFloat(
        this.FINANCE_INITIATIVE.ajustements[index].montant
      );
      budget += index === 0 ? 0 : parseFloat(this.budgetInitiative(index));
      return budget;
    },
  },
  mounted() {},

  props: {
    loading: Boolean,
    finance: Object,
    budget: String,
  },
  watch: {
    FINANCE_INITIATIVE() {
      this.tableItems = this.FINANCE_INITIATIVE
        ? this.FINANCE_INITIATIVE.ajustements.map((ajustement) => ({
            ...ajustement,
            responsable: this.finance.responsable,
            deadline: "-",
          }))
        : [];

      this.tableItems = this.tableItems.map((ajustement, index) => ({
        ...ajustement,
        budget: this.budgetInitiative(index),
        rest: this.budgetRevise(index),
      }));

      this.tableItems = this.tableItems.map((ajustement, index) => ({
        ...ajustement,
        tendance:
          index === 0
            ? 0
            : Math.round(
                ajustement.budget
                  ? (parseFloat(ajustement.montant) /
                      parseFloat(ajustement.budget)) *
                      100
                  : 100
              ),
      }));
    },
  },
};
</script>
