<template>
  <div class="container-fluid rounded bg-white py-4">
    <div class="sub-modal">
      <div class="d-flex">
        <p class="my-auto mr-auto">
          <i :class="$route.meta.iconClass"></i>
          <span
            style="font-size: 22px; color: #7f7f7f"
            class="font-weight-light"
          >
            Fiche initiative :
          </span>

          <span class="font-weight-bold text-black" style="font-size: 25px"
            >{{ INITIATIVE.libelle }}
          </span>
        </p>

        <div class="row mb-3">
          <div class="col-12">
            <modal-actions
              @close="
                $router.go(
                  $store.getters['routerHistory/fromCreation'] ? -2 : -1
                )
              "
            />
          </div>
        </div>
      </div>
      <div class="row p-0 m-0">
        <div class="col-12 col-md-4 p-1">
          <b-form @submit.prevent="updateItem" class="col-12 shdow h-100">
            <div class="col-12 p-0 d-flex">
              <p class="h4 text-secondary my-auto mr-auto">
                Informations générales
              </p>

              <button
                class="p-0 border-0 btn bg-white"
                v-if="
                  (isAdmin ||
                    isSuper ||
                    (isPo &&
                      INITIATIVE.responsable &&
                      AuthenticatedUser.id === INITIATIVE.responsable.id)) &&
                  (editing !== 'info' || !editMode)
                "
                @click="(editing = 'info'), (editMode = !editMode)"
                :disabled="
                  INITIATIVE.status === 'Suspendu' ||
                  INITIATIVE.status === 'Terminé'
                "
              >
                <i
                  class="mdi mdi-file-document-edit-outline"
                  style="font-size: 35px"
                  :style="{
                    color:
                      (editMode && editing !== 'info') ||
                      INITIATIVE.status === 'Suspendu' ||
                      INITIATIVE.status === 'Terminé'
                        ? 'gray'
                        : '#007bff',
                  }"
                ></i>
              </button>
              <div
                v-else-if="
                  isAdmin ||
                  isSuper ||
                  (isPo &&
                    INITIATIVE.responsable &&
                    AuthenticatedUser.id === INITIATIVE.responsable.id)
                "
                class="my-auto mx-0 d-flex p-0"
              >
                <b-button
                  type="submit"
                  style="width: 90px"
                  class="btn btn-secondary rounded-pill"
                  variant="secondary"
                  size="sm"
                  pill
                  >Confirmer</b-button
                >
                <b-button
                  @click="(editMode = !editMode), cancelEdit()"
                  style="background-color: #adb5bd; border-color: #adb5bd"
                  pill
                  size="sm"
                  >Annuler</b-button
                >
              </div>
            </div>
            <div class="col-12 p-0">
              <hr class="bg-secondary mb-3 mt-1" />
            </div>
            <div
              class="row col-12 p-0 my-1 p-0 mx-0"
              v-show="!editMode || editing !== 'info'"
            >
              <div class="col-12 p-0" style="flex: 1; margin-right: 20px">
                <div>
                  <p>
                    <span>Libellé : </span>
                    <span class="font-weight-bold">
                      {{ INITIATIVE.libelle }}
                    </span>
                  </p>
                  <p>
                    <span>Date de création : </span>
                    <span class="font-weight-bold">
                      {{
                        moment(INITIATIVE.createdAt, "DD/MM/YYYY").format(
                          "DD/MM/YYYY"
                        )
                      }}</span
                    >
                  </p>
                  <p class="">
                    <span>Responsable : </span>
                    <!-- <TableResponsable class="ml-1" :responsable="INITIATIVE.responsable" /> -->
                    <span class="font-weight-bold">
                      {{
                        INITIATIVE.responsable
                          ? INITIATIVE.responsable.firstname +
                            " " +
                            INITIATIVE.responsable.lastname
                          : "-"
                      }}
                    </span>
                  </p>
                  <p>
                    <span>Statut : </span>
                    <span class="font-weight-bold">
                      {{ INITIATIVE.status }}
                    </span>
                  </p>
                  <p>
                    <span>FDR : </span>
                    <span class="font-weight-bold">
                      {{
                        INITIATIVE.feuilleRoute
                          ? INITIATIVE.feuilleRoute.name
                          : "-"
                      }}
                    </span>
                  </p>

                  <p>
                    <span>Type : </span>
                    <span class="font-weight-bold"> {{ INITIATIVE.type }}</span>
                  </p>
                  <p>
                    <span>Patrimoine : </span>
                    <span class="font-weight-bold">
                      {{
                        INITIATIVE.patrimoine ? INITIATIVE.patrimoine.name : "-"
                      }}</span
                    >
                  </p>

                  <p>
                    <span>Budget : </span>
                    <span class="font-weight-bold">
                      {{
                        new Intl.NumberFormat("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        }).format(INITIATIVE.budget)
                      }}
                    </span>
                  </p>
                </div>
                <div>
                  <p>
                    <span>Description : </span>
                    <span class="font-weight-bold">
                      {{ INITIATIVE.description }}</span
                    >
                  </p>
                </div>
              </div>
            </div>
            <div
              id="add-page"
              class="p-0 m-0"
              v-show="editMode && editing === 'info'"
            >
              <div class="col-12 p-0" style="flex: 1; margin-right: 20px">
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Libellé:</label>
                  </div>
                  <div class="col-md-8">
                    <b-form-input
                      placeholder="Libellé"
                      :class="{
                        'is-invalid':
                          $v.newInitiative.libelle.$error &&
                          $v.newInitiative.libelle.$dirty,
                      }"
                      v-model="newInitiative.libelle"
                    ></b-form-input>
                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newInitiative.libelle.$dirty"
                    >
                      {{
                        !$v.newInitiative.libelle.required
                          ? "champ obligatoire"
                          : !$v.newInitiative.libelle.minLength
                          ? `Le champ doit contenir au moins ${$v.newInitiative.libelle.$params.minLength.min} caractères.`
                          : ""
                      }}
                    </span>
                    <!--  -->
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Date de création:</label>
                  </div>
                  <div class="col-md-8">
                    <date-picker
                      :value="newInitiative.createdAt"
                      class="col-12 p-0 font-weight-normal"
                      format="DD/MM/YYYY"
                      disabled
                    />
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Responsable:</label>
                  </div>
                  <div class="col-md-8">
                    <div
                      class="font-weight-normal p-0 form-control"
                      style="padding: 0 !important; height: fit-content"
                      :class="{
                        'is-invalid':
                          $v.newInitiative.responsable.$error &&
                          $v.newInitiative.responsable.$dirty,
                      }"
                    >
                      <v-select
                        style="background: transparent !important"
                        placeholder="Responsable"
                        required
                        label="text"
                        :options="responsables"
                        v-model="newInitiative.responsable"
                        :reduce="(responsable) => responsable.value"
                        :style="{
                          borderColor: '#dc3545',
                        }"
                      >
                        <template #no-options> Liste vide </template>
                      </v-select>
                    </div>
                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newInitiative.responsable.$dirty"
                    >
                      {{
                        !$v.newInitiative.responsable.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                    <!--  -->
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Statut:</label>
                  </div>
                  <div class="col-md-8">
                    <div
                      class="font-weight-normal p-0 form-control"
                      style="padding: 0 !important; height: fit-content"
                      :class="{
                        'is-invalid':
                          $v.newInitiative.responsable.$error &&
                          $v.newInitiative.responsable.$dirty,
                      }"
                    >
                      <v-select
                        class="bg-transparent"
                        v-model="newInitiative.status"
                        :options="statusInitiative"
                        placeholder="Statut"
                        :class="{
                          'is-invalid':
                            $v.newInitiative.status.$error &&
                            $v.newInitiative.status.$dirty,
                        }"
                      >
                        <template #no-options> Liste vide </template>
                      </v-select>
                    </div>
                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newInitiative.status.$dirty"
                    >
                      {{
                        !$v.newInitiative.status.required
                          ? "champ obligatoire"
                          : ""
                      }}
                    </span>
                    <!--  -->
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">FdR:</label>
                  </div>
                  <div class="col-md-8">
                    <div
                      class="font-weight-normal p-0 form-control"
                      style="padding: 0 !important; height: fit-content"
                      :class="{
                        'is-invalid':
                          $v.newInitiative.feuilleRoute.$error &&
                          $v.newInitiative.feuilleRoute.$dirty,
                      }"
                    >
                      <v-select
                        class="bg-transparent"
                        :options="fdrList"
                        label="text"
                        :reduce="(fdr) => fdr.value"
                        placeholder="FDR"
                        v-model="newInitiative.feuilleRoute"
                        :class="{
                          'is-invalid':
                            $v.newInitiative.feuilleRoute.$error &&
                            $v.newInitiative.feuilleRoute.$dirty,
                        }"
                        @input="getFdrCouvertures"
                      >
                        <template #no-options> Liste vide </template>
                      </v-select>
                    </div>
                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newInitiative.feuilleRoute.$dirty"
                    >
                      {{
                        !$v.newInitiative.feuilleRoute.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                    <!--  -->
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Patrimoine:</label>
                  </div>
                  <div class="col-md-8">
                    <div
                      class="font-weight-normal p-0 form-control"
                      style="padding: 0 !important; height: fit-content"
                    >
                      <!-- :class="{
                        'is-invalid':
                          $v.newInitiative.patrimoine.$error &&
                          $v.newInitiative.patrimoine.$dirty,
                      }" -->
                      <v-select
                        class="bg-transparent"
                        :options="patrimoineList"
                        label="text"
                        disabled
                        :reduce="(patrimoine) => patrimoine.value"
                        placeholder="Patrimoine"
                        v-model="newInitiative.patrimoine"
                      >
                        <!-- :class="{
                          'is-invalid':
                            $v.newInitiative.patrimoine.$error &&
                            $v.newInitiative.patrimoine.$dirty,
                        }" -->
                        <template #no-options> Liste vide </template>
                      </v-select>
                    </div>
                    <!-- ERROR MESSAGES -->
                    <!-- <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newInitiative.patrimoine.$dirty"
                    >
                      {{
                        !$v.newInitiative.patrimoine.required
                          ? "champ obligatoire"
                          : ""
                      }}
                    </span> -->
                    <!--  -->
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Type:</label>
                  </div>
                  <div class="col-md-8">
                    <div
                      class="font-weight-normal p-0 form-control"
                      style="padding: 0 !important; height: fit-content"
                      :class="{
                        'is-invalid':
                          $v.newInitiative.type.$error &&
                          $v.newInitiative.type.$dirty,
                      }"
                    >
                      <v-select
                        class="bg-transparent"
                        :options="initiativetypes"
                        placeholder="Type"
                        v-model="newInitiative.type"
                        :class="{
                          'is-invalid':
                            $v.newInitiative.type.$error &&
                            $v.newInitiative.type.$dirty,
                        }"
                      >
                        <template #no-options> Liste vide </template>
                      </v-select>
                    </div>
                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newInitiative.type.$dirty"
                    >
                      {{
                        !$v.newInitiative.type.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                    <!--  -->
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Budget:</label>
                  </div>
                  <div class="col-md-8">
                    <CurrencyInput
                      placeholder="Budget"
                      v-model="newInitiative.budget"
                      :value="newInitiative.budget"
                      class="font-weight-normal"
                      disabled
                      :class="{
                        'is-invalid':
                          $v.newInitiative.budget.$error &&
                          $v.newInitiative.budget.$dirty,
                      }"
                    />

                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newInitiative.budget.$dirty"
                    >
                      {{
                        !$v.newInitiative.budget.required
                          ? "Champ obligatoire"
                          : !$v.newInitiative.budget.minValue
                          ? `Le budget doit étre positif.`
                          : ""
                      }}
                    </span>
                    <!--  -->
                  </div>
                </div>
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Description:</label>
                  </div>
                  <div class="col-md-8">
                    <b-form-textarea
                      placeholder="Description"
                      v-model="newInitiative.description"
                    ></b-form-textarea>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>

        <div class="col-12 col-md-4 p-1">
          <b-form @submit.prevent="updateItem" class="col-12 shdow h-100">
            <div id="add-page" class="col-12 p-0 d-flex">
              <p class="h4 text-secondary my-auto mr-auto">Avancement</p>
              <button
                class="p-0 btn bg-white border-0"
                v-if="
                  (!editMode || editing !== 'avancement') &&
                  (isAdmin ||
                    isSuper ||
                    (isPo &&
                      INITIATIVE.responsable &&
                      AuthenticatedUser.id === INITIATIVE.responsable.id))
                "
                @click="(editing = 'avancement'), (editMode = !editMode)"
                :disabled="
                  INITIATIVE.status === 'Suspendu' ||
                  INITIATIVE.status === 'Terminé'
                "
              >
                <i
                  class="mdi mdi-file-document-edit-outline"
                  style="font-size: 35px"
                  :style="{
                    color:
                      (editMode && editing !== 'avancement') ||
                      INITIATIVE.status === 'Suspendu' ||
                      INITIATIVE.status === 'Terminé'
                        ? 'gray'
                        : '#007bff',
                  }"
                ></i>
              </button>
              <div
                v-else-if="
                  isAdmin ||
                  isSuper ||
                  (isPo &&
                    INITIATIVE.responsable &&
                    AuthenticatedUser.id === INITIATIVE.responsable.id)
                "
                class="m-0 d-flex p-0"
              >
                <b-button
                  type="submit"
                  style="width: 90px"
                  class="btn btn-secondary rounded-pill"
                  variant="secondary"
                  size="sm"
                  pill
                  >Confirmer</b-button
                >
                <b-button
                  @click="(editMode = !editMode), cancelEdit()"
                  style="background-color: #adb5bd; border-color: #adb5bd"
                  pill
                  size="sm"
                  >Annuler</b-button
                >
              </div>
            </div>
            <div class="col-12 p-0">
              <hr class="bg-secondary mb-3 mt-1" />
            </div>
            <div
              class="row col-12 p-0 my-1 p-0 mx-0"
              v-show="!editMode || editing !== 'avancement'"
            >
              <div class="col-12 p-0" style="flex: 1; margin-right: 20px">
                <div>
                  <p>
                    <span>Date de début : </span>
                    <span class="font-weight-bold">
                      {{ INITIATIVE.startedAt }}</span
                    >
                  </p>
                </div>
                <div>
                  <p>
                    <span>Date de fin : </span>
                    <span class="font-weight-bold">
                      {{ INITIATIVE.endingAt }}</span
                    >
                  </p>
                  <p>
                    <span>Phase : </span>
                    <span v-if="INITIATIVE.phases" class="font-weight-bold">
                      {{
                        INITIATIVE.phases.find((phase) => phase.isCurrent)
                          ? INITIATIVE.phases.find((phase) => phase.isCurrent)
                              .libelle
                          : "-"
                      }}</span
                    >
                  </p>

                  <p>
                    <span class="d-flex"
                      >Avancement :

                      <b-progress
                        show-progress
                        class="col-7 p-0 ml-1 my-auto"
                        height="15px"
                        :max="100"
                      >
                        <b-progress-bar
                          :value="myInitiative.avancement"
                          :style="`background-color: ${
                            myInitiative.avancement > 20 &&
                            myInitiative.avancement <= 70
                              ? '#f5f245'
                              : myInitiative.avancement > 70
                              ? '#23d92c'
                              : '#f5ba45'
                          }`"
                          :label="`${myInitiative.avancement}%`"
                        ></b-progress-bar>
                      </b-progress>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="p-0 m-0" v-show="editMode && editing === 'avancement'">
              <div class="col-12 p-0" style="flex: 1; margin-right: 20px">
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Date de début:</label>
                  </div>
                  <div class="col-md-8">
                    <date-picker
                      :value="new Date()"
                      class="col-12 p-0 font-weight-normal"
                      format="DD/MM/YYYY"
                      :input-attr="{
                        class: {
                          'is-invalid':
                            $v.newInitiative.startedAt.$error &&
                            $v.newInitiative.startedAt.$dirty,
                          ' form-control': true,
                        },
                      }"
                      v-model="newInitiative.startedAt"
                    />
                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newInitiative.startedAt.$dirty"
                    >
                      {{
                        !$v.newInitiative.startedAt.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                    <!--  -->
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Date de fin:</label>
                  </div>
                  <div class="col-md-8">
                    <date-picker
                      :value="new Date()"
                      class="col-12 p-0 font-weight-normal"
                      format="DD/MM/YYYY"
                      v-model="newInitiative.endingAt"
                      :input-attr="{
                        class: {
                          'is-invalid':
                            $v.newInitiative.endingAt.$error &&
                            $v.newInitiative.endingAt.$dirty,
                          ' form-control': true,
                        },
                      }"
                    />
                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newInitiative.endingAt.$dirty"
                    >
                      {{
                        !$v.newInitiative.endingAt.required
                          ? "Champ obligatoire"
                          : $v.newInitiative.endingAt.required &&
                            $v.newInitiative.endingAt.$error
                          ? "La date de fin ne doit pas être antérieur à la date de début"
                          : ""
                      }}
                    </span>
                    <!--  -->
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Phase:</label>
                  </div>
                  <div class="col-md-8">
                    <div
                      class="font-weight-normal p-0 form-control"
                      style="padding: 0 !important; height: fit-content"
                      :class="{
                        'is-invalid':
                          $v.newInitiative.phase.$error &&
                          $v.newInitiative.phase.$dirty,
                      }"
                    >
                      <v-select
                        class="bg-transparent"
                        :options="phasesList"
                        label="text"
                        :reduce="(phase) => phase.value"
                        placeholder="Phase Initiative"
                        v-model="newInitiative.phase"
                        :class="{
                          'is-invalid':
                            $v.newInitiative.phase.$error &&
                            $v.newInitiative.phase.$dirty,
                        }"
                        :disabled="
                          INITIATIVE.status === 'Proposé' ||
                          INITIATIVE.status === 'Validé' ||
                          INITIATIVE.status === 'Non validé'
                        "
                      >
                        <template #no-options> Liste vide </template>
                      </v-select>
                    </div>
                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newInitiative.phase.$dirty"
                    >
                      {{
                        !$v.newInitiative.phase.required
                          ? "champ obligatoire"
                          : ""
                      }}
                    </span>
                    <!--  -->
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="font-weight-bold mb-0">Avancement:</label>
                  </div>
                  <div class="col-md-8">
                    <b-progress variant="success" height="10px" :max="100">
                      <b-progress-bar
                        :value="avancementInitiative"
                        style="font-size: 8px"
                        :label="`${avancementInitiative}%`"
                      ></b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>

        <div class="col-12 col-md-4 p-1">
          <b-form @submit.prevent="updateItem" class="col-12 shdow h-100">
            <div class="col-12 p-0 d-flex">
              <p class="h4 text-secondary my-auto mr-auto">Couvertures</p>
              <!-- <div class=""> -->
              <button
                v-if="
                  (!editMode || editing !== 'couverture') &&
                  (isAdmin ||
                    isSuper ||
                    (isPo &&
                      INITIATIVE.responsable &&
                      AuthenticatedUser.id === INITIATIVE.responsable.id))
                "
                class="btn btn-sm p-0"
                :disabled="
                  (editMode && editing !== 'couverture') ||
                  INITIATIVE.status === 'Suspendu' ||
                  INITIATIVE.status === 'Terminé'
                "
                @click="(editing = 'couverture'), (editMode = !editMode)"
                type="button"
              >
                <i
                  class="mdi mdi-file-document-edit-outline"
                  style="font-size: 35px"
                  :style="{
                    color:
                      (editMode && editing !== 'couverture') ||
                      INITIATIVE.status === 'Suspendu' ||
                      INITIATIVE.status === 'Terminé'
                        ? 'gray'
                        : '#007bff',
                  }"
                ></i>
              </button>
              <!-- </div> -->
              <div
                v-else-if="
                  isAdmin ||
                  isSuper ||
                  (isPo &&
                    INITIATIVE.responsable &&
                    AuthenticatedUser.id === INITIATIVE.responsable.id)
                "
                class="m-0 d-flex p-0"
              >
                <b-button
                  type="submit"
                  style="width: 90px"
                  class="btn btn-secondary rounded-pill"
                  variant="secondary"
                  size="sm"
                  pill
                  >Confirmer</b-button
                >
                <b-button
                  @click="(editMode = !editMode), cancelEdit()"
                  style="background-color: #adb5bd; border-color: #adb5bd"
                  pill
                  size="sm"
                  >Annuler</b-button
                >
              </div>
            </div>
            <div class="col-12 p-0">
              <hr class="bg-secondary mb-3 mt-1" />
            </div>
            <div
              class="row col-12 p-0 my-1 p-0 mx-0"
              v-show="!editMode || editing !== 'couverture'"
            >
              <div class="row m-0 p-0">
                <div
                  style="height: 50px; position: relative; overflow: visible"
                  class="shdow ml-1"
                  v-for="(covert, index) in couvertures"
                  :key="index"
                >
                  <p>
                    <country-flag
                      :country="covert.countryCode"
                      size="small"
                    />{{ covert.country || "" }}
                  </p>
                </div>
              </div>
              <div
                class="text-center text-gray col-12 p-1"
                v-if="!couvertures.length"
              >
                Aucune couverture
              </div>
            </div>
            <div class="p-0 m-0" v-show="editMode && editing === 'couverture'">
              <!-- <div class="row p-0 m-0"> -->
              <div id="couverture" class="col-12 p-0 mb-2">
                <v-select
                  multiple
                  label="country"
                  v-model="couvertures"
                  :options="fdrCouvertures"
                  placeholder="Couvertures"
                  class="col-12 p-0 font-weight-normal"
                >
                  <template #option="{ country, countryCode }">
                    <span>
                      <country-flag
                        :country="countryCode"
                        size="small"
                        v-show="countryCode"
                      />
                      {{ country }}
                    </span>
                  </template>
                  <template #selected-option="{ country, countryCode }">
                    <div
                      class="d-flex"
                      style="display: flex; align-items: baseline"
                    >
                      <span>
                        <country-flag
                          :country="countryCode"
                          size="small"
                          v-show="countryCode"
                        />
                        {{ country }}
                      </span>
                    </div>
                  </template>
                  <template #no-options> Liste vide </template>
                </v-select>
                <!-- </div> -->
              </div>
              <!-- </div> -->
              <div class="row m-0 p-0">
                <div
                  style="height: 50px; position: relative; overflow: visible"
                  class="shdow"
                  v-for="(covert, index) in couvertures"
                  :key="index"
                >
                  <p>
                    <country-flag
                      :country="covert.countryCode"
                      size="small"
                    />{{ covert.country || "" }}
                  </p>

                  <div style="position: absolute; top: -5px; right: -5px">
                    <span style="cursor: pointer"
                      ><i
                        @click="deleteCouverture(covert.id, index)"
                        class="mdi mdi-close-circle text-danger"
                      ></i
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
      <hr />
      <b-tabs
        v-model="$store.state.tabs.initiativeTab"
        @activate-tab="
          (newTabIndex) =>
            $store.dispatch('tabs/changeInitiativeTab', newTabIndex)
        "
        class="tab-solid mt-3 tab-solid-primary"
      >
        <b-tab
          title="Finance"
          v-if="
            isAdmin ||
            isSuper ||
            (isPo &&
              INITIATIVE.responsable &&
              AuthenticatedUser.id === INITIATIVE.responsable.id)
          "
          lazy
        >
          <div class="row justify-content-between p-0 m-0 mb-2 flex-wrap">
            <div class="col-12 p-1">
              <div class="m-0 p-0 shdow h-100">
                <div class="hdr py-2 text-white rounded-top mb-3">
                  Budget au {{ moment().format("DD/MM/YYYY") }}
                </div>
                <div class="p-4">
                  <div class="d-flex col-12 p-0">
                    <div class="col-md-6 col-12 pl-0">
                      <p>
                        <span> Budget initiative : </span>
                        <span class="font-weight-bold">
                          {{
                            new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(INITIATIVE.budget)
                          }}
                        </span>
                      </p>
                      <p>
                        <span> Budget FdR : </span>
                        <span
                          v-if="INITIATIVE.feuilleRoute"
                          class="font-weight-bold"
                        >
                          {{
                            new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(INITIATIVE.feuilleRoute.budget || 0)
                          }}
                        </span>
                      </p>
                      <p>
                        <span>Dernière révision : </span
                        ><span class="font-weight-bold"
                          >{{
                            INITIATIVE.finance &&
                            INITIATIVE.finance.ajustements &&
                            INITIATIVE.finance.ajustements.length
                              ? moment(
                                  INITIATIVE.finance.ajustements[
                                    INITIATIVE.finance.ajustements.length - 1
                                  ].createdAt
                                ).format("DD/MM/YYYY")
                              : "-"
                          }}
                          <!-- par Steeve EWORE -->
                        </span>
                      </p>
                    </div>
                    <div class="col-md-6 col-12 pr-0">
                      <!-- ajustement -->
                      <form @submit.prevent="addAjustement">
                        <div class="row m-0 p-0 align-items-center">
                          <span class="mr-2 col-3 p-0">Ajustement</span>

                          <CurrencyInput
                            v-model="ajustement.montant"
                            :value="ajustement.montant"
                            size="sm"
                            placeholder="Ajuster"
                            class="col-5 mr-2"
                            :class="{
                              'is-invalid':
                                $v.ajustement.montant.$error &&
                                $v.ajustement.montant.$dirty,
                            }"
                          />
                        </div>
                        <div>
                          <!-- ERROR MESSAGES -->
                          <span
                            style="font-size: x-small"
                            class="text-danger font-weight-normal"
                            v-if="$v.ajustement.montant.$dirty"
                          >
                            {{
                              !$v.ajustement.montant.required
                                ? "Champ obligatoire"
                                : !$v.ajustement.montant.minValue
                                ? `L'ajustement ne doit pas dépasser le budget de initiative .`
                                : ""
                            }}
                          </span>
                          <!--  -->
                        </div>
                        <div class="row m-0 mt-2 p-0 align-items-center">
                          <span class="mr-2 col-3 p-0">Commentaire</span>
                          <b-form-input
                            placeholder="Commentaire"
                            class="col-5 mr-2"
                            size="sm"
                            v-model="ajustement.commentaire"
                            required
                          ></b-form-input>
                        </div>
                        <div class="row m-0 mt-2 p-0 align-items-center">
                          <div class="d-flex p-0 col-8">
                            <b-button
                              size="sm"
                              type="submit"
                              class="bg-blue ml-auto"
                              :disabled="
                                !(
                                  (isPo &&
                                    INITIATIVE.responsable &&
                                    INITIATIVE.responsable.id ===
                                      AuthenticatedUser.id) ||
                                  isAdmin ||
                                  isSuper
                                ) ||
                                ajustement.montant === 0 ||
                                ajustement.montant === ''
                              "
                              >Réviser</b-button
                            >
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div>
                    <p class="d-flex">
                      <span class="mr-2 my-auto">
                        Fichiers :
                        <span
                          v-if="
                            !INITIATIVE.finance ||
                            !INITIATIVE.finance.fileFinances ||
                            !INITIATIVE.finance.fileFinances.length
                          "
                        >
                          -
                        </span>
                      </span>
                      <button
                        v-if="
                          ((isPo &&
                            INITIATIVE.responsable.id ===
                              AuthenticatedUser.id) ||
                            isAdmin ||
                            isSuper) &&
                          !editFinanceFile
                        "
                        @click="editFinanceFile = true"
                        class="btn btn-sm btn-info"
                        :disabled="
                          INITIATIVE.status === 'Suspendu' ||
                          INITIATIVE.status === 'Terminé'
                        "
                      >
                        Modifier
                      </button>
                      <span
                        v-else-if="
                          (isPo &&
                            INITIATIVE.responsable.id ===
                              AuthenticatedUser.id) ||
                          isAdmin ||
                          isSuper
                        "
                        class="d-flex"
                      >
                        <button
                          @click="confirmFinanceFile"
                          variant="success"
                          class="btn btn-sm btn-success"
                        >
                          Confirmer
                        </button>
                        <button
                          @click="editFinanceFile = false"
                          class="btn btn-sm btn-danger"
                        >
                          Annuler
                        </button>
                      </span>
                    </p>

                    <FilePicker
                      v-if="editFinanceFile"
                      @click.prevent=""
                      class="font-weight-normal"
                      :isfinance="true"
                      @change="filePickerChanger"
                    />
                    <span style="color: black" class="font-weight-bold">
                      <b-list-group class="mt-3" v-if="INITIATIVE.finance">
                        <b-list-group-item
                          v-for="(livrable, index) in INITIATIVE.finance
                            .fileFinances"
                          :key="index"
                          class="d-flex"
                          ><i
                            class="fa my-auto pr-1 fa-paperclip"
                            aria-hidden="true"
                          ></i>
                          <a target="_blank" :href="IMG_URL + livrable.fileUrl">
                            {{ livrable.filename }}</a
                          >
                          <i
                            v-if="
                              !editFinanceFile &&
                              INITIATIVE.status !== 'Suspendu' &&
                              INITIATIVE.status !== 'Terminé'
                            "
                            @click="deleteLivrable(livrable)"
                            class="fa my-auto fa-times text-danger ml-auto"
                            aria-hidden="true"
                            style="cursor: pointer"
                          ></i>
                        </b-list-group-item>
                      </b-list-group>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div v-show="false" class="col-md-6 col-12 p-1">
              <div class="m-0 p-0 h-100 shdow">
                <div class="hdr py-2 text-white rounded mb-3">
                  Suivi conso initiative
                </div>
                <div class="p-2 d-flex">
                  <div
                    class="row col-md-6 p-0 m-0 align-items-center py-2 px-3"
                  >
                    <PieChart style="width: 100%" :options="pieOptions" />
                  </div>
                  <div
                    class="row col-md-6 p-0 m-0 align-items-center py-2 px-3"
                  >
                    <PieChart style="width: 100%" :options="pieConsoOptions" />
                  </div>
                </div>
                <div class="p-2">
                  <FollowConsomation
                    :disabled="
                      INITIATIVE.status === 'Suspendu' ||
                      INITIATIVE.status === 'Terminé'
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="hdr py-2 text-white rounded-top mb-3">
            Historique des révisions
          </div>
          <RevisionHistorique
            :budget="INITIATIVE.budget"
            :finance="initiativeFinance"
          />
        </b-tab>
        <b-tab
          v-if="
            isAdmin ||
            isSuper ||
            (isPo &&
              INITIATIVE.responsable &&
              AuthenticatedUser.id === INITIATIVE.responsable.id)
          "
          title="Référents"
        >
          <Referent
            :referents="referentsList"
            :libelle="INITIATIVE.libelle"
            :responsables="RESPONSABLES"
            :disabled="
              INITIATIVE.status === 'Suspendu' ||
              INITIATIVE.status === 'Terminé'
            "
          />
        </b-tab>
        <b-tab
          v-if="
            isAdmin ||
            isSuper ||
            (isPo &&
              INITIATIVE.responsable &&
              AuthenticatedUser.id === INITIATIVE.responsable.id)
          "
          title="Les enjeux stratégiques"
        >
          <StrategicChallenge
            :fdrId="feuilleId"
            :strategicCdhallenge="strategicChallenge"
            :disabled="
              INITIATIVE.status === 'Suspendu' ||
              INITIATIVE.status === 'Terminé'
            "
          />
        </b-tab>
        <b-tab
          v-if="
            isAdmin ||
            isSuper ||
            (isPo &&
              INITIATIVE.responsable &&
              AuthenticatedUser.id === INITIATIVE.responsable.id)
          "
          title="Gains"
        >
          <Gains
            :gains="gainsList"
            :disabled="
              INITIATIVE.status === 'Suspendu' ||
              INITIATIVE.status === 'Terminé'
            "
          />
        </b-tab>
        <b-tab
          v-if="
            isAdmin ||
            isSuper ||
            (isPo &&
              INITIATIVE.responsable &&
              AuthenticatedUser.id === INITIATIVE.responsable.id)
          "
          title="Impacts"
        >
          <Impacts
            :disabled="
              INITIATIVE.status === 'Suspendu' ||
              INITIATIVE.status === 'Terminé'
            "
            v-if="showImpact"
            :impacts="INITIATIVE.impacts"
          />
          <TransformationPlan v-else />
        </b-tab>
        <b-tab
          v-if="
            isAdmin ||
            isSuper ||
            (isPo &&
              INITIATIVE.responsable &&
              AuthenticatedUser.id === INITIATIVE.responsable.id)
          "
          title="Cadres d'entreprise"
        >
          <div>
            <CompanyFrame
              :disabled="
                INITIATIVE.status === 'Suspendu' ||
                INITIATIVE.status === 'Terminé'
              "
              :patterns="patternsList"
              :initiativeName="INITIATIVE.libelle"
            />
          </div>
        </b-tab>
        <b-tab
          v-if="
            isAdmin ||
            isSuper ||
            (isPo &&
              INITIATIVE.responsable &&
              AuthenticatedUser.id === INITIATIVE.responsable.id)
          "
          title="Planning"
        >
          <Planning
            :planning="INITIATIVE.phases"
            @addFrame="addFrameModal = true"
            :newTasks="initiative.newTasks"
            :disabled="
              INITIATIVE.status === 'Suspendu' ||
              INITIATIVE.status === 'Terminé'
            "
          />
        </b-tab>
        <b-tab
          v-if="
            isAdmin ||
            isSuper ||
            isManager ||
            (isPo &&
              INITIATIVE.responsable &&
              AuthenticatedUser.id === INITIATIVE.responsable.id)
          "
          title="Tâches et livrables"
        >
          <task-deliverable
            @showDeliverables="showDeliverablesModal = !showDeliverablesModal"
            :tasks="INITIATIVE.tasks"
            :initiative="INITIATIVE"
            :disabled="
              INITIATIVE.status === 'Suspendu' ||
              INITIATIVE.status === 'Terminé'
            "
          ></task-deliverable>
        </b-tab>
        <b-tab
          v-if="
            isAdmin ||
            isSuper ||
            (isPo &&
              INITIATIVE.responsable &&
              AuthenticatedUser.id === INITIATIVE.responsable.id)
          "
          title="Comités et décisions"
        >
          <div>
            <CommitteeDecision
              :tableItems="initiative.comiteDecision.tableItems"
              @searchGuests="searchGuestsModal = !searchGuestsModal"
              :disabled="
                INITIATIVE.status === 'Suspendu' ||
                INITIATIVE.status === 'Terminé'
              "
            />
          </div>
        </b-tab>
        <b-tab
          v-if="
            isAdmin ||
            isSuper ||
            (isPo &&
              INITIATIVE.responsable &&
              AuthenticatedUser.id === INITIATIVE.responsable.id)
          "
          title="Imputations"
          lazy
        >
          <div class="p-0 row col-12">
            <Imputation
              :initiativeName="INITIATIVE.libelle"
              @showRessourses="showRessourses = true"
              :disabled="
                INITIATIVE.status === 'Suspendu' ||
                INITIATIVE.status === 'Terminé'
              "
              class="p-md-1"
            />
            <ImputationByDirection />

            <ImputationByRessources
              v-if="false"
              :initiativeName="INITIATIVE.libelle"
              @showRessourses="showRessourses = true"
              :disabled="
                INITIATIVE.status === 'Suspendu' ||
                INITIATIVE.status === 'Terminé'
              "
              class="p-md-1"
            />
          </div>

          <div class="d-flex justify-content-between">
            <!-- <div class="col-md-5 p-0">
              <div class="col-12 p-0">
                <span class="h4 text-secondary mr-auto my-auto"
                  >Budget consommé
                </span>

                <hr class="mt-1 mb-3 bg-secondary" />
              </div>
              <PieChart style="width: 100%" :options="pieOptions" />
            </div> -->
            <!-- <ImputationByDirection /> -->
            <!-- <div class="col-md-5 p-0">
              <div class="col-12 p-0">
                <span class="h4 text-secondary mr-auto my-auto"
                  >Imputations par Direction
                </span>

                <hr class="mt-1 mb-3 bg-secondary" />
              </div>
              <PieChart style="width: 100%" :options="directionPieOptions" />
            </div> -->
          </div>
          <!-- {{ imputationsByDirections }} -->
        </b-tab>
      </b-tabs>
    </div>
    <!-- <Modal /> -->

    <!-- Show deliverables modal -->
    <div v-if="showDeliverablesModal" to="modals-xyz-548">
      <Modal @close="showDeliverablesModal = !showDeliverablesModal">
        <Deliverables />
      </Modal>
    </div>

    <!-- Search guests -->
    <div to="modals-xyz-548" v-if="searchGuestsModal">
      <Modal @close="searchGuestsModal = !searchGuestsModal">
        <SearchGuests @close="searchGuestsModal = !searchGuestsModal" />
      </Modal>
    </div>

    <!-- Add Frame -->

    <!-- Search role -->
    <div to="modals-xyz-548" v-if="searchRoleModal">
      <sub-modal @close="searchRoleModal = !searchRoleModal">
        <search-role @close="searchRoleModal = !searchRoleModal" />
      </sub-modal>
    </div>

    <!-- Ressources Modal -->
    <div to="modals-xyz-548" v-if="showRessourses">
      <Modal @close="showRessourses = !showRessourses">
        <RessourcesList @close="showRessourses = !showRessourses" />
      </Modal>
    </div>
  </div>
</template>

<script>
import TaskDeliverable from "./components/TaskDeliverable.vue";
import Deliverables from "./components/Deliverables.vue";
import SubModal from "@/components/custom/Modal.vue";
import PieChart from "@/components/charts/c3-chart/donut-c3.vue";
import searchRole from "./components/searchRole.vue";
import Referent from "./components/Referent.vue";
import StrategicChallenge from "./components/StrategicChallenge.vue";
import Gains from "./components/Gains.vue";
import Impacts from "./components/Impacts.vue";
import CompanyFrame from "./components/CompanyFrame.vue";
import Planning from "./components/Planning.vue";
import CommitteeDecision from "./components/CommitteeDecision.vue";
import SearchGuests from "./components/SearchGuests.vue";
import ModalActions from "@/components/custom/ModalActions.vue";
import TransformationPlan from "@/components/custom/TransformationPlan.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";
import RevisionHistorique from "./components/RevisionHistorique.vue";
import { datePickerFormat } from "@/helpers/DatePickerFormat.js";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import RessourcesList from "./components/RessourcesList";
import FollowConsomation from "./components/FollowConsomation";
import { IMG_URL } from "@/helpers/services/api";
import FilePicker from "@/components/custom/FilePicker.vue";
import CountryFlag from "vue-country-flag";
import Imputation from "./components/Imputation";
import ImputationByRessources from "./components/ImputationByRessources";
import ImputationByDirection from "./components/ImputationByDirection";
import CurrencyInput from "@/components/custom/CurrencyInput";

export default {
  name: "ShowInitiative",
  components: {
    CountryFlag,
    RessourcesList,
    PieChart,
    SubModal,
    TaskDeliverable,
    Deliverables,
    StrategicChallenge,
    Gains,
    Impacts,
    CompanyFrame,
    Planning,
    CommitteeDecision,
    SearchGuests,
    ModalActions,
    TransformationPlan,
    searchRole,
    RevisionHistorique,
    Referent,
    FollowConsomation,
    FilePicker,
    Imputation,
    ImputationByRessources,
    ImputationByDirection,
    CurrencyInput,
  },
  data() {
    return {
      editFinanceFile: false,
      editing: "",
      IMG_URL,
      livrables: [],
      showRessourses: false,
      searchGuestsModal: false,
      addFrameModal: false,
      searchRoleModal: false,
      editMode: false,
      showImpact: true,
      deletedCouverture: [],
      activeTab: this.$store.state.tabs.activeTab,
      myInitiative: {
        libelle: "",
        responsable: "",
        status: "",
        fdr: "",
        patrimoine: "",
        type: "",
        domaine: "",
        phase: "",
        description: "",
        avancement: 0,
        endingAt: "",
        startedAt: "",
        gains: [],
        tasks: [],
        impacts: [],
        finance: {},
      },
      newInitiative: {
        libelle: "",
        responsable: "",
        status: "",
        fdr: "",
        patrimoine: "",
        type: "",
        domaine: "",
        phase: "",
        description: "",
        avancement: 0,
        endingAt: "",
        startedAt: "",
        budget: 0,
      },
      ajustement: {
        montant: 0,
        commentaire: "",
      },
      impacts: {
        tableItems: [{ editMode: true, actions: ["edit", "delete"] }],
      },
      gains: {
        tableItems: [
          { editMode: true, actions: ["validate", "cancel", "edit"] },
        ],
      },
      strategicChallenge: {
        tableItems: [{ editMode: true }],
      },
      companyFrame: {
        tableItems: [],
      },
      initiative: {
        selectedImpact: 0,
        newTasks: [
          {
            title: "Phase 1",
            type: "Plan actions",
            name: "Phase one two three four five six seven",
            responsable: "S. E",
            id: "1",
            start: "2021-01-01",
            end: "2021-01-10",
            progress: 10,
            style: "text-truncate",
          },
          {
            title: "Phase 2",
            type: "Plan actions",
            name: "Phase one two three four five six seven",
            responsable: "S. E",
            id: "2",
            start: "2021-01-10",
            end: "2021-01-20",
            progress: 20,
            style: "text-truncate",
          },
          {
            title: "Phase 3",
            type: "Plan actions",
            name: "Phase one two three four five six seven",
            responsable: "S. E",
            id: "3",
            start: "2021-01-20",
            end: "2021-01-30",
            progress: 30,
            style: "text-truncate",
          },
          {
            title: "Phase 4",
            type: "Plan actions",
            name: "Phase one two three four five six seven",
            responsable: "S. E",
            id: "4",
            start: "2021-03-01",
            end: "2021-05-05",
            progress: 60,
            style: "text-truncate",
          },
          {
            title: "Phase 4",
            type: "Plan actions",
            name: "Phase one two three four five six seven",
            responsable: "S. E",
            id: "5",
            start: "2021-03-01",
            end: "2021-05-05",
            progress: 90,
            style: "text-truncate",
          },
        ],
        planning: {
          viewOptions: [
            { value: "tableau", text: "Tableau" },
            { value: "gantt", text: "Gantt" },
            { value: "kanban", text: "Kanban" },
          ],
          selectedView: { value: "tableau", text: "Tableau" },

          tableItems: [
            {
              phase: "Etude",
              start_date: "01/02/2021",
              end_date: "10/05/2021",
              status: "Non demarré",
              actions: ["edit"],
            },
          ],
          tableFields: [
            { key: "phase", label: "Phase", sortable: true },
            { key: "start_date", label: "Date début", sortable: true },
            { key: "end_date", label: "Date fin", sortable: true },
            { key: "status", label: "Statut", sortable: true },
            { key: "actions", label: "" },
          ],
        },

        comiteDecision: {
          viewOptions: [
            { value: "tableau", text: "Tableau" },
            { value: "gantt", text: "Gantt" },
          ],
          selectedView: { value: "tableau", text: "Tableau" },

          tableItems: [
            {
              committee: "",
              committee_date: "",
              object_path: "",
              presentator: "",
              inscription_date: "",
              inscription_status: "",
              guests: "",
              delivery: "Dossier Architecture",
              decision: "Atourné",
              decision_comment: "",
              actions: ["edit", "delete"],
            },
            {
              guests: "S. Ewore, J. page",
              editMode: true,
            },
          ],
          tableFields: [
            "Comité",
            "Date comité",
            "Object passage",
            "Presentateur",
            "Date inscription",
            "Invités",
            "Livrable",
            "Décision",
            "Commentaire décision",
            { key: "actions", label: "" },
          ],
        },
        searchInvitedModal: false,
      },

      imputationPhase: {
        fields: [
          { key: "phase", label: "Phase" },
          { key: "task", label: "Tâche" },
          { key: "nb_ressource", label: "Nb ressources" },
          { key: "charge_estime", label: "Charges estimée" },
          { key: "moncharge_imput", label: "Charges imputées" },
        ],
        items: [
          {
            phase: "Etude",
            task: "Notre cadrage",
            nb_ressource: "3",
            charge_estime: "15J/H",
            moncharge_imput: "5J/H",
          },
          {
            phase: "Etude",
            task: "Notre cadrage",
            nb_ressource: "0",
            charge_estime: "15J/H",
            moncharge_imput: "1J/H",
          },
          {
            phase: "Etude",
            task: "etude architecture",
            nb_ressource: "0",
            charge_estime: "15J/H",
            moncharge_imput: "3J/H",
          },
        ],
      },
      imputationNonValide: {
        fields: [
          { key: "collaborator", label: "collaborateur" },
          { key: "semaine", label: "Semaine" },
          { key: "status_semaine", label: "Statut semaine" },
          { key: "approbateur", label: "Approbateur" },
        ],
        items: [
          {
            collaborator: {
              firstname: "Steeve",
              lastname: "Ewore",
            },
            semaine: "S01 01/01/2021 - 07/01/2021",
            status_semaine: "A saisir",
            approbateur: {
              firstname: "Echalh",
              lastname: "Adil",
            },
          },
          {
            collaborator: {
              firstname: "Steeve",
              lastname: "Ewore",
            },
            semaine: "S01 01/01/2021 - 07/01/2021",
            status_semaine: "A saisir",
            approbateur: {
              firstname: "Echalh",
              lastname: "Adil",
            },
          },
          {
            collaborator: {
              firstname: "Steeve",
              lastname: "Ewore",
            },
            semaine: "S01 01/01/2021 - 07/01/2021",
            status_semaine: "A saisir",
            approbateur: {
              firstname: "Echalh",
              lastname: "Adil",
            },
          },
        ],
      },
      showDeliverablesModal: false,
      pieOptions: {
        data: {
          columns: [
            ["Consommé", 6000],
            ["Reste", 4000],
          ],
          type: "donut",
        },
        color: {
          pattern: ["#FFC000", "#072648"],
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 30,
          left: 0,
        },
        bar: {
          width: {
            ratio: 0.7, // this makes bar width 50% of length between ticks
          },
        },
      },
      pieConsoOptions: {
        data: {
          columns: [
            ["Ressources Internes", 5500],
            ["Externes", 2500],
            ["Achat matériel", 2000],
          ],
          type: "donut",
        },
        color: {
          pattern: ["#FFC000", "#072648", "#82D4BB"],
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 30,
          left: 0,
        },
        bar: {
          width: {
            ratio: 0.7, // this makes bar width 50% of length between ticks
          },
        },
      },
      directionPieOptions: {
        data: {
          columns: [
            ["Printemps", 6000],
            ["Eté", 4000],
            ["Automne", 2456],
            ["Hiver", 3221],
          ],
          type: "pie",
        },
        color: {
          pattern: ["#FFC000", "#072648", "#e71d36", "#28affa"],
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 30,
          left: 0,
        },
        bar: {
          width: {
            ratio: 0.7, // this makes bar width 50% of length between ticks
          },
        },
      },
      couvertures: [],
    };
  },
  validations: {
    newInitiative: {
      libelle: {
        required,
        minLength: minLength(4),
      },
      responsable: {
        required,
      },
      status: {
        required,
      },
      feuilleRoute: {
        required,
      },
      // patrimoine: {
      //   required,
      // },
      budget: {
        minValue(val) {
          return parseFloat(val) > 0;
        },
        required,
      },
      type: {
        required,
      },
      phase: {
        required,
      },
      avancement: 0,
      endingAt: {
        required,
        minValue(val) {
          return this.newInitiative.startedAt
            ? moment(val, "DD/MM/YYYY").diff(
                moment(this.newInitiative.startedAt, "DD/MM/YYYY")
              ) +
                1 >
                0
            : true;
        },
      },
      startedAt: {
        required,
      },
    },
    ajustement: {
      montant: {
        required,
        minValue(val) {
          if (this.INITIATIVE.budget >= 0)
            return val >= -this.INITIATIVE.budget;
          else true;
        },
      },
    },
  },

  methods: {
    sendingFunction: function (location) {
      window.console.table(location, "Click mouse location");
      this.couvertures.push(location);
      //  window.console.table(this.vision.couvertures, "this.vision.couvertures");
      //
    },
    deleteCouverture(id, index) {
      this.couvertures.splice(index, 1);
      id && this.deletedCouverture.push(id);
    },
    getFdrCouvertures() {
      if (this.newInitiative.feuilleRoute) {
        this.$store.dispatch(
          "fdr/fetchFdrCouvertures",
          this.newInitiative.feuilleRoute.substring(35)
        );
        this.couvertures = [];

        this.newInitiative.patrimoine = this.PATRIMOINES.find((patrimoine) =>
          patrimoine.feuilles.find(
            (feuille) => feuille["@id"] === this.newInitiative.feuilleRoute
          )
        )["@id"];
      } else {
        this.newInitiative.patrimoine = "";
      }
    },
    filePickerChanger(files) {
      this.livrables = files;
    },
    confirmFinanceFile() {
      this.editFinanceFile = false;
      if (this.INITIATIVE.finance) {
        this.livrables.map((file) => {
          file.append("finance", this.INITIATIVE.finance["@id"]);
          this.$store
            .dispatch("financeFile/createFinanceFile", file)
            .then(() => {
              this.$store
                .dispatch("initiative/fetchInitiative", this.$route.params.id)
                .then(() => {
                  Swal.fire(
                    "Le fichier a été téléversé avec succès !",
                    "",
                    "success"
                  );
                });
            });
          this.$store.dispatch(
            "initiative/fetchInitiative",
            this.$route.params.id
          );
        });
      } else {
        this.$store
          .dispatch("initiative/updateInitiative", {
            id: this.$route.params.id,
            finance: {
              name: `FIN-INI-${this.$route.params.id}`,
              responsable: this.INITIATIVE.responsable["@id"],
            },
          })
          .then(() => {
            this.$store
              .dispatch("initiative/fetchInitiative", this.$route.params.id)
              .then(() => {
                this.livrables.map((file) => {
                  file.append("finance", this.INITIATIVE.finance["@id"]);
                  this.$store
                    .dispatch("financeFile/createFinanceFile", file)
                    .then(() => {
                      this.$store
                        .dispatch("initiative/updateInitiative", {
                          id: this.$route.params.id,
                        })
                        .then(() => {
                          this.$store
                            .dispatch(
                              "initiative/fetchInitiative",
                              this.$route.params.id
                            )
                            .then(() => {
                              Swal.fire(
                                "Le fichier a été téléversé avec succès !",
                                "",
                                "success"
                              );
                            });
                        });
                    });
                });
              });
          });
      }
      this.livrables = [];
    },
    deleteLivrable(livrable) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("financeFile/deleteFinanceFile", livrable.id)
            .then(() => {
              this.$store
                .dispatch("initiative/fetchInitiative", this.$route.params.id)
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
              // });
            });
        }
      });
    },

    updateItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.newInitiative.phases.map((phase) => {
          this.$store.dispatch("phase/updatePhase", {
            id: phase.id,
            isCurrent: phase["@id"] === this.newInitiative.phase,
          });
        });
        window.console.table(this.newInitiative);
        if (
          this.INITIATIVE.feuilleRoute["@id"] !==
          this.newInitiative.feuilleRoute
        )
          this.INITIATIVE.couvertures.map((couverture) => {
            this.$store
              .dispatch("couverture/deleteCouverture", couverture.id)
              .then(() => {
                this.$store.dispatch(
                  "initiative/fetchInitiative",
                  this.$route.params.id
                );
              });
          });

        this.couvertures.map((couverture) => {
          if (!couverture.old)
            this.$store
              .dispatch("couverture/createCouverture", {
                initiative: this.INITIATIVE["@id"],
                adresse: couverture.adresse,
                country: couverture.country,
                countryCode: couverture.countryCode,
                lat: couverture.lat,
                lng: couverture.lng,
              })
              .then(() => {
                this.$store.dispatch(
                  "initiative/fetchInitiative",
                  this.$route.params.id
                );
              });
        });
        this.deletedCouverture.map((id) => {
          this.$store.dispatch("couverture/deleteCouverture", id).then(() => {
            this.$store.dispatch(
              "initiative/fetchInitiative",
              this.$route.params.id
            );
          });
        });
        this.deleteCouverture = [];

        this.$store
          .dispatch("initiative/updateInitiative", {
            budget: this.newInitiative.budget.toString(), ///////////////////
            description: this.newInitiative.description,
            domaine: this.newInitiative.domaine,
            feuilleRoute: this.newInitiative.feuilleRoute,
            id: this.$route.params.id,
            libelle: this.newInitiative.libelle,
            patrimoine: this.newInitiative.patrimoine,
            // phase: this.newInitiative.phase,
            responsable: this.newInitiative.responsable,
            sponsor: "/api/users/3",
            status: this.newInitiative.status,
            type: this.newInitiative.type,
            startedAt: moment(
              this.newInitiative.startedAt,
              "DD/MM/YYYY"
            ).format("DD/MM/YYYY"),
            endingAt: moment(this.newInitiative.endingAt, "DD/MM/YYYY").format(
              "DD/MM/YYYY"
            ),
            // couvertures: this.couvertures.map((couverture) => couverture["@id"]),
          })
          .then(() => {
            this.$store
              .dispatch("initiative/fetchInitiative", this.$route.params.id)
              .then(() => {
                Swal.fire("L'initiative est mise à jour !", "", "success");
                this.editMode = false;
              });
          });
      }
    },
    addAjustement() {
      if (
        (this.isPo &&
          this.INITIATIVE.responsable.id === this.AuthenticatedUser.id) ||
        this.isAdmin ||
        this.isSuper
      ) {
        this.$v.$touch();
        if (!this.$v.ajustement.$invalid) {
          if (this.myInitiative.finance) {
            this.myInitiative.finance.ajustements.push({
              ...this.ajustement,
              createdAt: new Date(),
            });
            // creating new ajustement
            this.$store
              .dispatch("ajustement/createAjustement", {
                ...this.ajustement,
                finance: this.myInitiative.finance["@id"],
              })
              .then(() => {
                this.$store
                  .dispatch("initiative/fetchInitiative", this.$route.params.id)
                  .then(() => {
                    Swal.fire({
                      title: "L'ajustement a été bien effectué !",
                      type: "success",
                      confirmButtonText: "OK",
                    });
                    this.ajustement = {
                      montant: 0,
                      commentaire: "",
                    };
                  });
              });
          }
          if (!this.myInitiative.finance)
            this.$store
              .dispatch("initiative/updateInitiative", {
                id: this.$route.params.id,
                finance: {
                  user: this.myInitiative.responsable["@id"],
                  name: `finance de initiative ${this.myInitiative.name}`,
                },
              })
              .then(() => {
                this.$store
                  .dispatch("initiative/fetchInitiative", this.$route.params.id)
                  .then(() => {
                    // creating new ajustement
                    this.$store
                      .dispatch("ajustement/createAjustement", {
                        ...this.ajustement,
                        finance: this.myInitiative.finance["@id"],
                      })
                      .then(() => {
                        this.$store
                          .dispatch(
                            "initiative/fetchInitiative",
                            this.$route.params.id
                          )
                          .then(() => {
                            this.ajustement = {
                              montant: 0,
                              commentaire: "",
                            };
                          });
                      });
                  });
              });
        }
      }
    },
    cancelEdit() {
      this.$store.dispatch("initiative/fetchInitiative", this.$route.params.id);
      this.deletedCouverture = [];
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("status/fetchInitiativeStatus");
    this.$store.dispatch("fdr/fetchAllFdrs");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
    this.$store.dispatch("cType/fetchInitiativeTypes");
    this.$store
      .dispatch("initiative/fetchInitiative", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("loader/toggleLoading", false);
      });
    this.$store.dispatch("phase/fetchAllPhases");
    this.$store.dispatch(
      "initiative/fetchImputationsByDirections",
      this.$route.params.id
    );
  },
  computed: {
    ...mapGetters("status", ["INITIATIVE_STATUS"]), //get all status
    ...mapGetters("organisation", ["ORGANISATIONS"]), //get all organisations
    ...mapGetters("fdr", ["FDRS", "COUVERTURES"]), //get all FDRs
    ...mapGetters("patrimoine", ["PATRIMOINES"]),
    ...mapGetters("cType", ["INITIATIVE_TYPES"]),

    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("initiative", ["INITIATIVE", "IMPUTATIONS_BY_DIRECTIONS"]),
    ...mapGetters("phase", ["PHASES"]), //get all phases
    ...mapGetters([
      "AuthenticatedUser",
      "isPo",
      "isManager",
      "isAdmin",
      "isSuper",
    ]),

    feuilleId() {
      return this.INITIATIVE.feuilleRoute ? this.INITIATIVE.feuilleRoute.id : 0;
    },
    responsables() {
      //return array of responsables
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/users/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    phasesList() {
      return this.INITIATIVE.phases
        ? this.INITIATIVE.phases.map((phase) => ({
            ...phase,
            value: phase["@id"],
            text: phase.libelle,
          }))
        : [];
    },
    statusInitiative() {
      return this.INITIATIVE_STATUS;
    },
    fdrList() {
      return this.FDRS.map((feuille) => ({
        value: feuille["@id"],
        text: feuille.name,
      }));
    },
    organisations() {
      return this.ORGANISATIONS.map((organisation) => ({
        value: organisation["@id"],
        text: organisation.libelle,
      }));
    },
    patrimoineList() {
      return this.PATRIMOINES.map((patrimoine) => ({
        value: patrimoine["@id"],
        text: patrimoine.name,
      }));
    },
    initiativetypes() {
      return this.INITIATIVE_TYPES;
    },
    avancementInitiative() {
      var avancement = 0;
      this.myInitiative.tasks
        ? this.myInitiative.tasks.map((task) => {
            avancement += task.avancement;
          })
        : "";
      return this.myInitiative.tasks
        ? this.myInitiative.tasks.length
          ? parseInt(avancement / this.myInitiative.tasks.length)
          : 0
        : 0;
    },
    gainsList() {
      return this.myInitiative.gains;
    },
    impactsList() {
      return this.myInitiative.impacts;
    },
    tasksList() {
      return this.myInitiative.tasks.map((task) => ({
        ...task,
        actions: ["edit", "delete"],
      }));
    },
    patternsList() {
      return this.INITIATIVE.patterns;
    },
    referentsList() {
      return this.myInitiative.referents;
    },
    initiativeFinance() {
      return this.myInitiative.finance;
    },
    fdrCouvertures() {
      if (this.newInitiative.feuilleRoute) return this.COUVERTURES.couvertures;
      return [];
    },
    imputationsByDirections() {
      this.directionPieOptions;
      return this.IMPUTATIONS_BY_DIRECTIONS;
    },
  },
  watch: {
    INITIATIVE: function () {
      this.myInitiative = this.INITIATIVE;
      if (this.INITIATIVE)
        this.newInitiative = {
          ...this.INITIATIVE,
          feuilleRoute: this.INITIATIVE.feuilleRoute
            ? this.INITIATIVE.feuilleRoute["@id"]
            : "",
          patrimoine: this.INITIATIVE.patrimoine
            ? this.INITIATIVE.patrimoine["@id"]
            : "",
          responsable: this.INITIATIVE.responsable["@id"],
          endingAt: datePickerFormat(this.INITIATIVE.endingAt),
          startedAt: datePickerFormat(this.INITIATIVE.startedAt),
          createdAt: datePickerFormat(this.INITIATIVE.createdAt),
          phase:
            this.INITIATIVE.phases && this.INITIATIVE.phases.length
              ? this.INITIATIVE.phases.find((phase) => phase.isCurrent)
                ? this.INITIATIVE.phases.find((phase) => phase.isCurrent)["@id"]
                : this.INITIATIVE.phases[0]["@id"]
              : null,
          budget: parseFloat(this.INITIATIVE.budget),
          // budget: this.INITIATIVE.budget,
        };
      // if (this.INITIATIVE)
      this.$store
        .dispatch(
          "fdr/fetchFdrCouvertures",
          this.newInitiative.feuilleRoute.substring(35)
        )
        .then(() => {
          this.couvertures = this.INITIATIVE.couvertures.map((couverture) => ({
            ...couverture,
            old: true,
          }));
        });
    },
    IMPUTATIONS_BY_DIRECTIONS() {
      window.console.log(this.directionPieOptions, "IMPUTATIONS_BY_DIRECTIONS");
      this.directionPieOptions;
    },
  },
};
</script>
<style>
#couverture .vs__selected-options {
  display: none;
}

#couverture .vs__actions {
  margin-left: auto;
}
</style>

<style scoped>
.top_gantt_controls {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.header_sd_menu {
  height: 60px;
  background-color: white;
  width: 100%;
  border: 2px #f2edf3 solid;
}

.sd_menu_row {
  width: 100%;
  height: 38px;
}

.fl_1 {
  flex: 1;
}

.fl_2 {
  flex: 2;
}

.fl_3 {
  flex: 3;
}

.txt_desc {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.table_responsivo {
  overflow: scroll;
}

.popUpFather {
  position: relative;
}

@media (min-width: 991px) {
  .modal-xyz-548 {
    position: absolute;
    width: calc(100% - 331px) !important;
    height: 72% !important;
    top: 165px !important;
    right: 35px !important;
    z-index: 3000;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-flex-pack: center;
  }
}

@media (max-width: 991px) {
  .modal-xyz-548 {
    width: 100%;
  }
}

.sub-modal {
  background-color: white;
  padding: 20px 30px;
  width: 100%;
  height: 100%;
  position: relative;
}

.sb_mdl_ctrls {
  /* position: absolute; */
  /* top: -45px; */
  /* background-color: rebeccapurple; */
  height: 40px;
  /* right: 0px; */
  /* width: 100px; */
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
}

.mdl_btn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
}

.tr_clckble {
  cursor: pointer;
}

.tr_clckble:hover {
  background-color: rgba(139, 44, 235, 0.342);
}

.hdr {
  background-color: #072648;
  text-align: center;
}

.hdr > p {
  color: white;
}

.spn {
  color: #797979;
}
</style>
<style>
.vs__search {
  color: #cfcfcf;
}

.mx-datepicker {
  width: 100% !important;
}
</style>
