<template>
  <div>
    <div class="d-flex">
      <span class="h4 text-secondary mr-auto my-auto">Liste des gains </span>
      <div class="w-50 d-flex align-items-center justify-content-end">
        <CustomAddButton
          :disabled="disabled"
          text="Nouveau gain"
          @click="addItem"
        />
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>

    <b-table
      responsive
      :fields="tableFields"
      :items="tableItems"
      show-empty
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      striped
      bordered
      :sort-desc.sync="sortDesc"
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(type)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value
        }}</span>
        <v-select
          style="width: 100%"
          v-else
          v-model="data.item.type"
          :options="gainTypes"
          placeholder="Type"
          appendToBody
        />
      </template>
      <template v-slot:cell(libelle)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value
        }}</span>
        <input
          v-else
          type="text"
          v-model="data.item.libelle"
          class="form-control"
          placeholder="Libellé"
        />
      </template>
      <template v-slot:cell(val)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value
        }}</span>
        <input
          type="number"
          v-else
          v-model="data.item.val"
          class="form-control"
          placeholder="Val"
        />
      </template>
      <template v-slot:cell(typeVal)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value
        }}</span>
        <input
          v-else
          type="number"
          v-model="data.item.typeVal"
          class="form-control"
          placeholder="Type Val"
        />
      </template>
      <template v-slot:cell(description)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value
        }}</span>
        <input
          v-else
          type="text"
          v-model="data.item.description"
          class="form-control"
          placeholder="Description"
        />
      </template>

      <template #cell(actions)="data">
        <table-actions
          :actions="['edit', 'delete']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @confirmItem="confirmItem(data.item)"
          @cancelItem="cancelItem(data.item)"
          @editItem="editItem(data.item.id)"
          @deleteItem="deleteItem(data.item.id)"
        ></table-actions>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { mapGetters } from "vuex";

export default {
  props: {
    gains: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { TableActions, CustomAddButton },
  data: () => ({
    sortBy: "",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,

    tableItems: [],
  }),
  methods: {
    addItem: function () {
      var hasEdit = false;
      this.tableItems.map(
        (item) => (hasEdit = item.editMode ? item.editMode : false)
      );
      if (!hasEdit) {
        this.tableItems.unshift({
          type: "",
          description: "",
          val: "",
          typeVal: "",
          editMode: true,
        });
        this.currentPage = 1;
      }
    },
    confirmItem: function (item) {
      if (item.libelle !== "" && item.type !== "" && item.description !== "") {
        if (!item.id)
          this.$store
            .dispatch("gain/createGain", {
              libelle: item.libelle,
              type: item.type,
              description: item.description,
              val: parseInt(item.val || 0),
              typeVal: parseInt(item.typeVal || 0),
              initiative: `/api/transformation/initiatives/${this.$route.params.id}`,
            })
            .then(() => {
              // fetch data again
              this.$store
                .dispatch("initiative/fetchInitiative", this.$route.params.id)
                .then(() => {
                  Swal.fire({
                    title: "Le gain est bien créé !",
                    type: "success",
                    confirmButtonText: "OK",
                  });
                });
            });
        else
          this.$store
            .dispatch("gain/updateGain", {
              id: item.id,
              libelle: item.libelle,
              type: item.type,
              description: item.description,
              val: parseInt(item.val || 0),
              typeVal: parseInt(item.typeVal || 0),
              initiative: `/api/transformation/initiatives/${this.$route.params.id}`,
            })
            .then(() => {
              // fetch data again
              this.$store
                .dispatch("initiative/fetchInitiative", this.$route.params.id)
                .then(() => {
                  Swal.fire({
                    title: "Le gain est mis à jour !",
                    type: "success",
                    confirmButtonText: "ok",
                  });
                });
            });
      }
    },
    editItem: function (id) {
      this.$store
        .dispatch("initiative/fetchInitiative", this.$route.params.id)
        .then(() => {
          this.tableItems = this.tableItems.map((item) =>
            item.id === id
              ? {
                  ...item,
                  editMode: true,
                }
              : item
          );
        });
    },
    cancelItem: function (item) {
      if (item.libelle === "" && item.description === "" && item.type === "") {
        this.$store.dispatch(
          "initiative/fetchInitiative",
          this.$route.params.id
        );
      } else {
        item.libelle = "";
        item.description = "";
        item.type = "";
      }
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("gain/deleteGain", id).then(() => {
            this.$store
              .dispatch("initiative/fetchInitiative", this.$route.params.id)
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
  },
  mounted() {
    this.tableItems = this.gains.map((gain) => ({
      ...gain,
      actions: ["edit", "delete"],
      old: true,
    }));
  },
  created() {
    this.$store.dispatch("cType/fetchGainTypes");
  },
  computed: {
    ...mapGetters("cType", ["GAIN_TYPES"]),
    tableFields() {
      var fields = [
        { key: "type", label: "Type de gain", sortable: true },
        { key: "libelle", label: "Lib. gain", sortable: true },
        { key: "description", label: "Description", sortable: true },
      ];
      if (!this.disabled) {
        fields.push({
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        });
      }

      return fields;
    },
    rows() {
      return this.tableItems.length;
    },
    gainTypes() {
      return this.GAIN_TYPES.map((type) => type.name);
    },
  },
  watch: {
    gains() {
      this.tableItems = this.gains.map((gain) => ({
        ...gain,
        actions: ["edit", "delete"],
        old: true,
      }));
    },
  },
};
</script>

<style></style>
